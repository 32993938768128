import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

export const resourceKey = 'resource_category'
export const collectionKey = 'resource_categories'

const RESOURCE_LOCATION = `${BASE_URL}/api/soil_management/soil_resources_category/`

export const fields = [{ name: 'Nombre', field: 'name' }]

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const byId = (farms, startDate, endDate, managementCategories) => axios.get(`${BASE_URL}/analytics/resourcecategories`,{ 
  params: {
      farms,
      startDate,
      endDate,
      managementCategories,
  }}).then(dataGetter)

export const create = (soil_resource_category) =>
  axios.post(RESOURCE_LOCATION, { soil_resource_category }).then(dataGetter)

export const remove = (soil_resource_category_id) =>
  axios
    .delete(`${RESOURCE_LOCATION}${soil_resource_category_id}`)
    .then(dataGetter)

export const edit = (soil_resource_category_id, soil_resource_category) =>
  axios
    .put(`${RESOURCE_LOCATION}${soil_resource_category_id}`, {
      soil_resource_category,
    })
    .then(dataGetter)
