import React, { useState, useEffect } from 'react'
import { Grid, Paper, Box } from '@material-ui/core'

import { FarmOwner } from 'utils/api/models'
import ResourcesAsTable from './ResourcesAsTable'

const FarmOwners = () => {
  const [owners, setOwners] = useState()

  async function getOwnersData() {
    const _providers = await FarmOwner.all()
    setOwners(_providers)
  }

  useEffect(() => getOwnersData(), [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
          >
            <h2> Administrar Cuentas</h2>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          position='relative'
          height='80vh'
        >
          {owners ? (
            <ResourcesAsTable
              resources={owners}
              model={FarmOwner}
              title='Cuentas'
              refreshData={async () => await getOwnersData()}
              setSelected={(id) => console.log(id)}
              parentSelections={[]}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}
export default FarmOwners
