import AppBreadcrumbs from 'components/AppBreadcrumbs'
import React from 'react'
import {
  Toolbar,
  AppBar,
  Fab,
  Menu,
  MenuItem,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'

import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { useHistory } from 'react-router-dom'

import Logo from 'images/logo-verde.svg'
//import Isotipo from 'images/Isotipo.svg'
import { useSelector, useDispatch } from 'react-redux'

import * as actions from 'actions'
import Snackbar from 'utils/Snackbar'
import useParams from 'hooks/useParams'

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  isotipo: {
    height: '35px',
    paddingRight: '10px',
    cursor: 'pointer',
  },
  logo: {
    height: '30px',
    width: '80px',

    cursor: 'pointer',
  },
  appBar: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
  },
  selectFarm: {
    fontWeight: 'bold',
  },
  userContainer: {
    /* REVISAR  POSICIONAMIENTO */
    right: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  userButton: {
    alignSelf: 'center',
    paddingRight: '12px',
    paddingLeft: '12px',
  },
}))

const TopBar = () => {
  const user = useSelector((state) => state.user)
  const classes = useStyles()
  let history = useHistory()
  const dispatch = useDispatch()

  const routeParams = useParams()

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    history.push('/iniciar-sesion')
    dispatch(actions.unsetUser())
    Snackbar.toast('Se cerro la sesión', 'success')
  }

  const navigateTo = (path) => (e) => {
    history.push(path)
    handleClose(e)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar color='secondary' className={classes.appBar}>
        <Box display='flex'>
          <Toolbar>
          {/*<img
              src={Isotipo}
              className={classes.isotipo}
              onClick={() => history.push('/')}
              alt='isotipo'
          />*/}
            <img
              src={Logo}
              className={classes.logo}
              onClick={() => history.push('/')}
              alt='logotipo'
          />
          </Toolbar>
          <AppBreadcrumbs />
        </Box>

        <Box p={1} className={classes.userContainer}>
          <i>{user.username}</i>

          <Box px={2}>
            <Fab
              className={classes.userButton}
              size='small'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleToggle}
              color='primary'
              ref={anchorRef}
            >
              <PersonIcon />
            </Fab>
          </Box>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement='bottom-end'
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'right top',
                  anchorOrigin: 'right top',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id='menu-list-grow'>
                      {user.is_super_admin && [
                        <MenuItem
                          onClick={navigateTo('/administracion-cuentas')}
                        >
                          Administrar Cuentas
                        </MenuItem>,
                      ]}
                      {(user.is_admin || user.is_super_admin) && [
                        <MenuItem
                          onClick={navigateTo(
                            `/${routeParams.farmOwnerId}/administracion-usuarios`,
                          )}
                        >
                          Administrar Usuarios
                        </MenuItem>,
                        <MenuItem
                          onClick={navigateTo(
                            `/${routeParams.farmOwnerId}/administracion-campos`,
                          )}
                        >
                          Administrar Campos
                        </MenuItem>,
                        <MenuItem
                          onClick={navigateTo(
                            `/${routeParams.farmOwnerId}/administracion-empresas`,
                          )}
                        >
                          Administrar Empresas
                        </MenuItem>,
                        <MenuItem
                          onClick={navigateTo(
                            `/${routeParams.farmOwnerId}/unidades-productivas`,
                          )}
                        >
                          Administrar Unidades Productivas
                        </MenuItem>,
                        <MenuItem
                          onClick={navigateTo(
                            `/${routeParams.farmOwnerId}/administracion-proveedores`,
                          )}
                        >
                          Administrar Proveedores
                        </MenuItem>,
                      ]}
                      <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </AppBar>
    </React.Fragment>
  )
}

export default TopBar
