import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/daily_kpi/`

export const DailyKpi = [
    { name: 'Leche total', field: 'total_milk', only: 'create', value: '' },
    { name: 'Vacas en ordeña', field: 'total_dairy_cows', only: 'create', value: '' },
    { name: 'Producción por vaca', field: 'production_per_cow', only: 'create', value: '' },
    { name: 'Medición de estanque', field: 'pond_measurement', only: 'create', value: '' },
    { name: 'Medición de camion', field: 'truck_measurement', only: 'create', value: '' },
    { name: 'Diferencia estanque camión', field: 'delta_measurement', only: 'create', value: '' },
    { name: 'Potreros pastoreo AM', field: 'grazing_paddock_am', only: 'create', value: '' },
    { name: 'Potreros pastoreo PM', field: 'grazing_paddock_pm', only: 'create', value: '' },


]

export const dateFields = [{ name: 'Fecha', field: 'date', type: 'date' }]

export const fields = [
    ...DailyKpi,
]

export const all = (productiveUnitId) => axios.get(`${RESOURCE_LOCATION}?productiveUnitId=${productiveUnitId}`)
    .then(response => {
        const data = response.data

        const formattedData = data.data.map(entry => ({
            'Leche total (L)': entry.total_milk,
            'Producción por vaca (L)': entry.production_per_cow.toFixed(2),
            'Vacas en leche (Nº)': entry.total_dairy_cows,
            'Potrero pastoreo AM': entry.grazing_paddock_am,
            'Potrero pastoreo PM': entry.grazing_paddock_pm,
            'Medicion a camión (cm)': entry.truck_measurement,
            'Medicion a estanque (cm)': entry.pond_measurement,
            'Diferencia de medida estanque-camión (cm)': entry.delta_measurement,
            'productive_unit': entry.productive_unit_id,
            'Fecha': entry.date,
        }))
        return formattedData
    })
    .catch(error => {
        console.error('Error fetching data from API:', error)
        throw error
    })
