import React from 'react'
import { useLocation } from 'react-router-dom'

export default function UnderConstruccion() {
  let location = useLocation()

  return (
    <div>
      <h3>
        El sitio <code>{location.pathname}</code> todavía está en construcción
      </h3>
    </div>
  )
}
