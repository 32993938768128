import * as types from 'actions/types'

const farms = (state = [], action) => {
  switch (action.type) {
    case types.SET_FARMS: {
      return action.farms
    }
    default:
      return state
  }
}

export default farms
