import React, { useState, useEffect } from 'react'
import { Grid, Paper, Box } from '@material-ui/core'

import { User } from 'utils/api/models'
import ResourcesAsTable from './ResourcesAsTable'
import * as actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'

const UsersAdmin = () => {
  const [usersData, setUsersData] = useState()
  const dispatch = useDispatch()

  async function getUserData() {
    const users = await User.all()
    setUsersData(users)
    dispatch(actions.breadcrumbResourcesUpdated())
  }
  const routeParams = useSelector((state) => state.routeParams)

  useEffect(() => getUserData(), [routeParams.farmOwnerId])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
          >
            <h2> Administrar usuarios </h2>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          position='relative'
          height='80vh'
        >
          {usersData ? (
            <ResourcesAsTable
              resources={usersData}
              model={User}
              title='Usuario'
              refreshData={async () => await getUserData()}
              setSelected={(id) => console.log(id)}
              parentSelections={[]}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}
export default UsersAdmin
