import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
import { MachineryCategory, Machinery } from 'utils/api/models'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/meadows/:templateMeadowId/soil_management/managements`

export const collectionKey = 'tasks'
export const parentKey = 'task_group_id'

export const customName = (r) =>
  `${r.machinery.name} (Días relativos: ${r.relative_days})`

export const fields = [
  { name: 'Máquina', field: 'machinery.name', only: 'list' },
  {
    name: 'Categoría de máquina',
    field: 'machinery_category_id',
    only: 'create',
    model: MachineryCategory,
  },
  {
    name: 'Máquina',
    field: 'machinery_id',
    depends: 'machinery_category_id',
    only: 'create',
    editValue: 'machinery',
    model: Machinery,
  },
  { name: 'Fecha relativa', field: 'relative_days', type: 'number' },
  { name: 'Frecuencia', field: 'machinery_frequency', type: 'number' },
  {
    name: 'Costo/u',
    field: 'machinery_price',
    type: 'currency',
    suggestedValue: ({ machinery }) => (machinery ? machinery.unit_price : 0),
  },
  {
    name: 'Costo/Ha',
    value: (row) => row.machinery_frequency * row.machinery_price,
    suggestedValue: (state, form_values) =>
      form_values.machinery_frequency &&
      form_values.machinery_price &&
      form_values.machinery_price * form_values.machinery_frequency,
    type: 'currency',
  },
  { name: 'Observaciones', field: 'observation' },
]

export const create = (
  _management_category_id,
  management_id,
  task_group_id,
  task,
  state,
) => {
  task.name = state.task_groups[task_group_id].name

  /* TODO MODIFICAR ESTO */
  task.suggested_date = '2020-08-01T08:00:00Z'
  task.task_total = 1000

  return axios
    .post(
      `${RESOURCE_LOCATION}/${management_id}/task_groups/${task_group_id}/tasks/`,
      {
        task,
      },
    )
    .then(dataGetter)
}

export const remove = (
  _soil_management_category_id,
  management_id,
  task_group_id,
  task_id,
) =>
  axios
    .delete(
      `${RESOURCE_LOCATION}/${management_id}/task_groups/${task_group_id}/tasks/${task_id}`,
    )
    .then(dataGetter)

export const edit = (
  task_id,
  task,
  _soil_management_category_id,
  management_id,
  task_group_id,
) =>
  axios
    .put(
      `${RESOURCE_LOCATION}/${management_id}/task_groups/${task_group_id}/tasks/${task_id}`,
      {
        task,
      },
    )
    .then(dataGetter)
