import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/cattle_summary_analysis`

export default [
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },

  {
    title: 'Produccion por vaca',
    subtitle: 'Indicador productivo',
    keydata: '31,19 L',
    delta: '+ +1,2 L ',
    period: 'respecto al periodo anterior',
  },

  {
    title: 'Gastos totales',
    subtitle: 'Indicador económico',
    keydata: '$ 536.768',
    delta: '$ +698.753',
    period: 'respecto al periodo anterior',
  },

  {
    title: 'Solidos por hectarea',
    subtitle: 'Indicador económico',
    keydata: '87L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },

  {
    title: 'L/VM',
    subtitle: 'Indicador económico',
    keydata: '235',
    delta: ' +8,23',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'L/VM',
    subtitle: 'Indicador económico',
    keydata: '235',
    delta: ' +8,23',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'L/VM',
    subtitle: 'Indicador económico',
    keydata: '235',
    delta: ' +8,23',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Gastos totales',
    subtitle: 'Indicador económico',
    keydata: '$ 536.768',
    delta: '$ +698.753',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },
  {
    title: 'Leche total',
    subtitle: 'Indicador productivo',
    keydata: '32874 L',
    delta: '+ 10,51 L',
    period: 'respecto al periodo anterior',
  },
]

export const all = () => axios.get(RESOURCE_LOCATION)

  .then(response => {
    const data = response.data.data

    const formattedData = data.map(entry => ({
      'Avance Pastoreo': typeof entry.advance === 'number' ? entry.advance.toFixed(2) : entry.advance,
      'Concentrado/Vaca/Dia': typeof entry.concentrate_per_day === 'number' ? entry.concentrate_per_day.toFixed(2) : entry.concentrate_per_day,
      'Fecha': entry.date,
      'Muertes': entry.deaths,
      'Mastitis': entry.mastitis,
      'Metritis': entry.metritis,
      'Grasa láctea': typeof entry.milk_fat === 'number' ? entry.milk_fat.toFixed(2) : entry.milk_fat,
      'Proteína láctea': typeof entry.milk_protein === 'number' ? entry.milk_protein.toFixed(2) : entry.milk_protein,
      'productive_unit_id': entry.productive_unit_id,
      'Rotación (días)': typeof entry.rotation === 'number' ? entry.rotation.toFixed(2) : entry.rotation,
      'Superficie pastoreo (Has)': typeof entry.surface === 'number' ? entry.surface.toFixed(2) : entry.surface,
      'Vacas totales': typeof entry.total_cows === 'number' ? entry.total_cows.toFixed(2) : entry.total_cows,
      'Leche total (L)': typeof entry.total_milk === 'number' ? entry.total_milk.toFixed(2) : entry.total_milk,
      'farm_id': entry.farm_id,
    }))

    return formattedData
  })
  .catch(error => {
    console.error('Error fetching data from API:', error)
    throw error
  })