import React from 'react'
import { Grid, makeStyles, Paper } from '@material-ui/core'
import withProps from 'hoc/withProps'

import {
  Machinery,
  MachineryCategory,
  Resource,
  ResourceCategory,
} from 'utils/api/models'

import NestedResourcesLayout from 'components/NestedResourcesLayout'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}))

const Machineries = withProps({
  layout: [
    {
      id: 1,
      title: 'Categorías de maquinarias',
      model: MachineryCategory,
      size: 4,
      listMode: 'list',
    },
    {
      id: 2,
      title: 'Maquinarias',
      model: Machinery,
      size: 8,
      parent_id: 1,
      listMode: 'table',
    },
  ],
})(NestedResourcesLayout)

const Resources = withProps({
  layout: [
    {
      id: 1,
      title: 'Categorías de insumos',
      model: ResourceCategory,
      size: 4,
      listMode: 'list',
    },
    {
      id: 2,
      title: 'Insumos',
      model: Resource,
      size: 8,
      parent_id: 1,
      listMode: 'table',
    },
  ],
})(NestedResourcesLayout)

console.log('Insumo, base de datos: ', Resource)

const MachineriesResources = (props) => {
  const classes = useStyles()

  return (
    <Paper className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Machineries />
        </Grid>
        <Grid item xs={6}>
          <Resources />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MachineriesResources
