import React, { useState, useRef } from 'react'
import {
    Grid,
    Paper,
    Button,
    Box,
    Typography,
    Divider,
    RadioGroup,
    Radio,
    FormControlLabel,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
} from '@material-ui/core'
import CalendarNavigator from '../CalendarNavigator'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import _ from 'lodash'
import MonthsSelector from './MothsSelector'

const useStyles = makeStyles((theme) => ({
    papper: {
        padding: theme.spacing(2, 4, 3),
    },
    Textfield: {
        paddingBottom: '10px',
    },
    buttongroup: {},
    modal: {
        color: 'black',
        alignContent: 'center',
        width: 'auto',
        backgroundColor: '#eeeeee',
        padding: theme.spacing(2, 4, 3),
    },
    selectorlabel: {
        fontWeight: 'bold',
    },
    light: {
        fontWeight: '300',
        color: '#305600',
    },
    root: {
        padding: '20px',
    },
    calendarYear: {
        margin: '10px',
        minWidth: 180,
        maxWidth: 180,
    },
}))



export default function CattleSummariesSelector({
    summary = [],
    summaryMonthly = [],
    date,
    setDate,
    CattleSummariesType,
    setCattleSummariesType,
    setDisabledData,
}) {

    const [refreshCalendar, setRefreshCalendar] = useState(false);

    const [selectedyear, setSelectedYear] = useState(new Date().getFullYear())
    const label1 = useRef(null)

    const classes = useStyles()

    const years = Array.from({ length: 10 }, (_, i) => date.getFullYear() - 5 + i);

    const CattleSummariesType_handleChange = event => {
        setCattleSummariesType(event.target.value);
    };

    summary = _.map(summary, function name(n) { return [new Date(n.date), 1] })

    return (
        <section className={classes.root}>

            <Box display='flex' justifyContent='space-between' >
                <div>
                    <Typography variant='h6' className={classes.light}>Tipo de ingreso:</Typography>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup
                            aria-label="Tipo de Ingreso"
                            name="gender1"
                            //className={classes.group}
                            value={CattleSummariesType}
                            onChange={(e) => CattleSummariesType_handleChange(e)}
                        >
                            <FormControlLabel
                                value="mensual"
                                className={classes.selectorlabel}
                                control={<Radio color="primary" />}
                                label={
                                    <Typography variant="h3" color={CattleSummariesType === 'mensual' ? 'primary' : 'inhert'}>
                                        Mensuales
                                    </Typography>
                                } />
                            <FormControlLabel
                                value="diario"
                                control={<Radio color="primary" />}
                                label={
                                    <Typography variant="h3" color={CattleSummariesType === 'diario' ? 'primary' : 'inhert'}>
                                        Diarios
                                    </Typography>
                                } />
                        </RadioGroup>
                    </FormControl>
                </div>
                <Box display='flex' alignItems='flex-end'>
                    <FormControl className={classes.calendarYear} variant="outlined" >
                        <InputLabel ref={label1} >Año Calendario</InputLabel>
                        <Select
                            value={date.getFullYear()}
                            onChange={(e) => setDate(new Date(date.setYear(e.target.value)))}
                            name='year'
                            input={
                                <OutlinedInput
                                    labelWidth={label1.current?.clientWidth}
                                    name="Año Calendario"
                                />} >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                    {CattleSummariesType === 'diario' ?

                        <CalendarNavigator
                            date={date}
                            setDate={setDate}
                            visit={true}
                            taskFrequencyByDay={summary}
                            refreshCalendar={refreshCalendar}
                        />
                        :
                        <MonthsSelector
                            date={date}
                            setDate={setDate}
                            summary={summaryMonthly}
                        />
                    }
                </Box>
                <Box display='flex' flexDirection='column' justifyContent='flex-end'>
                    <Typography variant='caption' align='right' className={classes.light}>{CattleSummariesType === 'diario' ? 'Fecha Actual:' : 'Mes Actual:'}</Typography>
                    <Typography variant='body1'>{`${format(new Date(), CattleSummariesType === 'diario' ? 'dd MMMM yyyy' : 'MMMM yyyy', { locale: es })}`}</Typography>
                </Box>
            </Box>
            <Divider />
            <Box display='flex' justifyContent='space-between' mt='15px'>
                <Button variant='contained' color='primary' onClick={() => setDisabledData(false)}>Editar Datos</Button>
                <Box display='flex' flexDirection='column' justifyContent='flex-end'>
                    <Typography variant='caption' align='center' className={classes.light}><b>{CattleSummariesType === 'diario' ? 'Fecha Selecionada:' : 'Mes Seleccionado:'}</b></Typography>
                    <Typography variant='body1' align='center' ><b>{`${format(date, CattleSummariesType === 'diario' ? 'dd MMMM yyyy' : 'MMMM yyyy', { locale: es })}`}</b></Typography>
                </Box>
                <div />
            </Box>
        </section>
    )
}