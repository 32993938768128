import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/`

export const all = (business_id, params, to_month) => {
  //console.log(params)

  return axios
    .post(`${RESOURCE_LOCATION}${business_id}/income_statement/`, 
        { ...params, to_month},
        
    )
    .then(({ data }) => data) 
  }