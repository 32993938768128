export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_OWNER = 'SET_OWNER'
export const SET_USERS = 'SET_USERS'
export const SET_USER = 'SET_USER'
export const UNSET_USER = 'UNSET_USER'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const SET_FARM_OWNERS = 'SET_FARM_OWNERS'
export const SET_ROUTE_PARAMS = 'SET_ROUTE_PARAMS'
export const SET_BUSINESSES = 'SET_BUSINESSES'
export const SET_FARMS = 'SET_FARMS'
export const SET_TOKEN = 'SET_TOKEN'
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const BREADCRUMB_RESOURCES_UPDATED = 'BREADCRUMB_RESOURCES_UPDATED'
