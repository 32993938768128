import React from 'react'
import withOwnerFarmParam from 'hoc/withOwnerFarmParam'
import { Meadow } from 'utils/api/models'
import { useSelector } from 'react-redux'
import ResourcesAsPolygons from 'components/ResourcesAsPolygons'
import MeadowPolygon from 'components/MeadowPolygon'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useStyles } from 'components/ResourcesModalForm/ResourcesModalForm'
import { useSnackbar } from 'notistack'

function ImportKmz({ refreshData }) {
  const [open, setOpen] = React.useState(false)
  const toggleModal = () => setOpen((o) => !o)
  const classes = useStyles()
  const [file, setFile] = React.useState({})
  const { enqueueSnackbar } = useSnackbar()
  const isLoading = useSelector((s) => s.network.isLoading)

  const postForm = () => {
    async function postData() {
      let formData = new FormData()
      formData.append('kmz', file)
      //console.log(file)

      try {
        const meadows = await Meadow.importKmz(formData)
        await refreshData()

        enqueueSnackbar(`Se han importado ${meadows.length} polígonos`, {
          variant: 'success',
        })
        setOpen(false)
        setFile({})
      } catch (e) {
        enqueueSnackbar(
          'No se pudieron importar los polígonos, verifique el archivo.',
          {
            variant: 'error',
          },
        )
      }
    }
    postData()
  }

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      flexDirection='row'
      p={1}
    >
      <Typography variant='h6'>Potreros</Typography>
      <Button
        color='primary'
        size='small'
        endIcon={<AddIcon />}
        onClick={toggleModal}
      >
        Importar KMZ
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className={classes.paper}>
          <Typography variant='h2'>Importar polígonos</Typography>
          <Box p={2} display='flex' flexDirection='column' width='100%'>
            <input
              type='file'
              onChange={(e) => setFile(e.target.files[0])}
              accept='.kmz'
              style={{ display: 'none' }}
              id='kmz'
            />
            <Typography>Archivo KMZ</Typography>

            <label htmlFor='kmz'>
              <Button variant='contained' color='secondary' component='span'>
                Subir KMZ
              </Button>
            </label>

            {file.name && <Typography>{file.name}</Typography>}
          </Box>
          <Box p={3}>
            <Button
              color='secondary'
              variant='contained'
              onClick={postForm}
              disabled={!file.name || isLoading}
            >
              Importar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
function MeadowsManager(props) {
  const [meadows, setMeadows] = React.useState([])
  const routeParams = useSelector((state) => state.routeParams)

  const getMeadows = () => {
    async function getData() {
      const _meadows = await Meadow.all()

      setMeadows(_meadows)
    }
    getData()
  }
  React.useEffect(getMeadows, [routeParams])

  return (
    <ResourcesAsPolygons
      resources={meadows}
      Resource={MeadowPolygon}
      refreshData={getMeadows}
      model={Meadow}
      HeaderComponent={ImportKmz}
      {...props}
    />
  )
}

export default MeadowsManager
