import React from 'react'

import Tab from '@material-ui/core/Tab'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'

import { makeStyles } from '@material-ui/core/styles'

import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: '0px',
    '& span': {
      '&:hover': {
        color: '#343434',
      },
    },
  },
  menuPaper: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      '& li': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
      },
    },
  },
}))

export default function TabMenuOption({ label, route, submenus }) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  let history = useHistory()

  const classes = useStyles()

  const handleToggle = () => {
    setOpen((o) => !o)
  }

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleClose = (route) => {
    handleToggle()
    if (route) {
      history.push(route)
    }
  }

  return (
    <React.Fragment>
      {submenus ? (
        <React.Fragment>
          <Tab
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
            label={label}
            className={classes.tab}
          />
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <MenuList autoFocusItem={open} id='menu-list-grow'>
                      {submenus.map(({ label, route }, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleClose(route)}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </React.Fragment>
      ) : (
        <Tab
          className={classes.tab}
          label={label}
          onClick={() => history.push(route)}
        />
      )}
    </React.Fragment>
  )
}
