import React from 'react'
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core'
import ResourcesAsTable from 'components/ResourcesAsTable'
import ResourcesAsDataTable from 'components/ResourcesAsDataTable'
import ResourcesAsList from 'components/ResourcesAsList'
import ResourcesAsTransferList from 'components/ResourcesAsTransferList'

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  listContainer: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    position: 'relative',
  },
  container: {
    padding: theme.spacing(1),
  },
  coloredTitle: {
    color: theme.palette.primary.main,
  },
}))

const LayoutElement = ({
  layout: { size, horizontal, listMode, _get, Component, ...l },
  setSelected,
  selected,
  ...props
}) => {
  const classes = useStyles()
  const [resources, setResources] = React.useState([])

  const fetchData = (force = false) => {
    async function dataGetter() {
      if (_get) {
        if (force) {
          await props.refreshDataTree()
        }
        return _get(props.externalState, ...props.parentSelections)
      } else {
        return l.model.all(...props.parentSelections)
      }
    }

    async function getData() {
      const _resources = await dataGetter(props, l)

      setResources(_resources)
    }
    if (force) {
      return getData
    } else {
      if (props.enoughSelections) {
        getData()
      }
    }
  }

  React.useEffect(fetchData, [
    ...props.parentSelections,
    props.enoughSelections,
    props.externalState,
  ])

  const asList = listMode === 'list'

  if (Component) {
    return (
      <Component active={props.enoughSelections}>
        {asList ? (
          <>
            {/* Lista renderizada en modal */}
            <ResourcesAsList
              resources={resources}
              selected={selected}
              setSelected={setSelected}
              model={l.model}
              title={l.title}
              listOnly
              refreshData={async () => {
                await fetchData(true)()
              }}
              {...props}
            />
          </>
        ) : (
          <React.Fragment>
            <ResourcesAsTransferList
              resources={resources}
              selected={selected}
              setSelected={setSelected}
              model={l.model}
              title={l.title}
              elementHeight={l.height}
              listOnly
              checkbox
              allowCreation={false}
              refreshData={async () => await fetchData(true)()}
              resourcesFiltered={props.resourcesFiltered}
              setResourcesFiltered={props.setResourcesFiltered}
              {...props}
            />
          </React.Fragment>
        )}
      </Component>
    )
  }

  return (
    <Grid item xs={size || 12} className={classes.column}>
      <Typography variant='h6' align='center'>
        {/*  Lista de */}
        <span className={classes.coloredTitle}>{l.title}</span>
      </Typography>
      <Paper variant='outlined' className={classes.listContainer}>
        <Grid
          container
          spacing={1}
          justify='center'
          className={classes.container}
        >
          {asList ? (
            <ResourcesAsList
              resources={resources}
              selected={selected}
              setSelected={setSelected}
              allowCreation
              model={l.model}
              title={l.title}
              refreshData={async () => {
                await fetchData(true)()
              }}
              {...props}
            />
          ) : (
            <React.Fragment>
              <ResourcesAsTable
                resources={resources}
                selected={selected}
                setSelected={setSelected}
                model={l.model}
                title={l.title}
                elementHeight={l.height}
                allowCreation
                refreshData={async () => await fetchData(true)()}
                {...props}
              />

              {/* TODO preguntar si tiene props.checkbox     */}
            </React.Fragment>
          )}
        </Grid>
      </Paper>
    </Grid>
  )
}

export default LayoutElement
