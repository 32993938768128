import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
import _ from 'lodash'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/meadows/`

export function getShortName(meadow) {
  return _(meadow.name)
    .words()
    .map((w) => (_.isInteger(Number(w)) ? w : w[0]))
    .map(_.capitalize)
    .value()
}

export const label = 'Potrero'

export const fields = [
  {
    name: 'Nombre',
    field: 'name',
    suggestedValue: (_selections, form, meadows) =>
      form.name || `Potrero ${meadows?.length + 1}`,
  },
  {
    name: 'Área (ha)',
    field: 'area_in_ha',
    suggestedValue: (_, values) => values.area,
  },
  { name: 'Riego', field: 'riego', type: 'boolean' },
]

export const all = (start_date, end_date) =>
  axios
    .get(RESOURCE_LOCATION, { params: { start_date, end_date } })
    .then(dataGetter)

export const create = (meadow) =>
  axios.post(RESOURCE_LOCATION, { meadow }).then(dataGetter)

export const remove = (meadow_id) =>
  axios.delete(`${RESOURCE_LOCATION}/${meadow_id}`).then(dataGetter)

export const edit = (meadow_id, meadow) =>
  axios
    .put(`${RESOURCE_LOCATION}/${meadow_id}`, {
      meadow,
    })
    .then(dataGetter)

export const importKmz = (kmz) =>
  axios.post(`${RESOURCE_LOCATION}kmz`, kmz).then(dataGetter)
