import React from 'react'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import Button from '@material-ui/core/Button'

const ExcelExport = ({ data, headers, fileName }) => {
  //console.log('xlsx: ', XLSX)

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data, {
      header: headers.includes('fecha') ? ['fecha'] : [],
    })
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(blob, `${fileName}.xlsx`)
  }

  return (
    <Button color='primary' variant='contained' onClick={exportToExcel}>
      Excel
    </Button>
  )
}

export default ExcelExport
