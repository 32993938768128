import * as types from 'actions/types'

const user = (state = {}, action) => {
  switch (action.type) {
    case types.SET_USER: {
      return action.user
    }
    case types.UNSET_USER: {
      return {}
    }
    default:
      return state
  }
}

export default user
