import * as types from 'actions/types'
import _ from 'lodash'

const users = (state = [], action) => {
  switch (action.type) {
    case types.SET_USERS: {
      return _(action.users)
        .filter(({ username }) => username !== '__admin__')
        .value()
    }
    default:
      return state
  }
}

export default users
