import Table from '../../AnalyticsMilkByDay/Table'
import es from 'date-fns/locale/es'
import { format } from 'date-fns'
import { elementTypeAcceptingRef } from '@mui/utils'

export default function PreTable({ data }) {
  //console.log('pre_table_Days: ', data)

  let finalarray = {
    data: data.data,
    headers: data.keys,
  }

  const headers = [{ label: 'name', key: 'name' }]

  const columns = [
    {
      name: 'Unidades productivas',
      selector: (row) => row.name,
    },
  ]

  finalarray.headers.forEach((element) => {
    let head = {
      name: element,
      selector: (row) => row[element],
    }
    let csvHead = { label: element, key: element }
    columns.push(head)
    headers.push(csvHead)
  })

  //console.log('columns: ', columns, 'final array: ', finalarray.data)

  return (
    <>
      <Table data={finalarray} columns={columns} headers={headers} />
    </>
  )
}
