import React from 'react'
import { useSelector } from 'react-redux'
import { Fade, LinearProgress, withStyles } from '@material-ui/core'

const AbsoluteLinearProgress = withStyles({
  root: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1500,
  },
})(LinearProgress)

function LoadingIndicator() {
  const isLoading = useSelector((state) => state.network.isLoading)
  return (
    <Fade
      in={isLoading}
      style={{
        transitionDelay: isLoading ? '400ms' : '0ms',
      }}
      unmountOnExit
    >
      <AbsoluteLinearProgress />
    </Fade>
  )
}

export default LoadingIndicator
