import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { Business, Farm, FarmOwner, User } from 'utils/api/models'
import * as types from 'actions/types'
import { push } from 'connected-react-router'
import { getParamFromUrl, getRedirectPath } from 'utils/common'
import Snackbar from 'utils/Snackbar'
import axios from 'axios'

import * as api from 'utils/api'
import * as actions from 'actions'

export function* main() {
  yield fork(checkToken)
  yield takeEvery(types.SET_TOKEN, handleToken)

  while (true) {
    const user = yield call(ensureUserLoaded)
    yield fork(getDefaultEntities, user)
    yield take(types.UNSET_USER)
  }
}

function* handleToken({ token }) {
  localStorage.setItem('token', token)

  yield call(checkToken)

  Snackbar.toast('Bienvenido', 'success')

  yield put(push('/'))
}

function* checkToken() {
  const token = localStorage.getItem('token')

  if (token) {
    axios.defaults.headers.common = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    try {
      const user = yield call(User.current)
      yield put({ type: types.SET_USER, user })
    } catch (e) {
      const status = e.response?.status
      if (status === 401) {
        Snackbar.toast('Se requiere iniciar sesión nuevamente', 'warning')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
      }

      yield put({ type: types.LOGIN_FAILED })
    }
  } else {
    yield put({ type: types.LOGIN_FAILED })
  }
}

function* getDefaultEntities(user) {
  try {
    if (user.is_super_admin) {
      const { path, url } = yield select((state) => state)
      const currentFarmOwnerId = yield call(
        getParamFromUrl,
        path,
        url,
        ':farmOwnerId',
      )
      const setFarmOwnerParam =
        Number(currentFarmOwnerId) === 0 || currentFarmOwnerId === undefined
      // const currentFarmOwnerId = yield call(
      //   getParamFromUrl,
      //   path,
      //   url,
      //   ':farmOwnerId',
      // )
      // const setFarmOwnerParam =
      //   Number(currentFarmOwnerId) === 0 || currentFarmOwnerId === undefined

      let params = {}

      // if (setFarmOwnerParam) {
      //   params.farmOwnerId = user.id
      // }

      // if (setFarmOwnerParam) {
      //   const [farmOwner] = yield call(FarmOwner.all, user.id)
      //   params.farmOwnerId = farmOwner.id
      // }
      // if (setFarmOwnerParam || setFarmOwnerParam) {
      //   yield put({
      //     type: types.SET_ROUTE_PARAMS,
      //     params,
      //   })
      // }
    } else {
      // const [farmOwner] = yield call(FarmOwner.all, user.id)
      yield put({
        type: types.SET_ROUTE_PARAMS,
        params: {
          farmOwnerId: user.farm_owner_id,
        },
      })
    }
    yield fork(getCategories)
  } catch (e) {
    return
  }

  yield fork(getDefaultBusiness)
  yield fork(getDefaultFarm)
}

function* getDefaultBusiness() {
  try {
    const [business] = yield call(Business.all)
    if (business) {
      yield put({
        type: types.SET_ROUTE_PARAMS,
        params: {
          businessId: business.id,
        },
      })
      const pathDescription = yield select((state) => state.path)
      const location = yield select((state) => state.router.location.pathname)
      const redirectPath = getRedirectPath(
        pathDescription,
        location,
        ':businessId',
        business.id,
      )
      if (location !== redirectPath) {
        yield put(push(redirectPath))
      }
    }
  } catch (e) {
    return
  }
}

function* getDefaultFarm() {
  try {
    const { path, url } = yield select((state) => state)
    const currentFarmId = yield call(getParamFromUrl, path, url, ':farmId')
    const setFarmParam =
      Number(currentFarmId) === 0 || currentFarmId === undefined
    if (setFarmParam) {
      const [farm] = yield call(Farm.all)
      if (farm) {
        yield put({
          type: types.SET_ROUTE_PARAMS,
          params: {
            farmId: farm.id,
            templateMeadowId: farm.template_meadow_id,
          },
        })
        const pathDescription = yield select((state) => state.path)
        const location = yield select((state) => state.router.location.pathname)
        const redirectPath = getRedirectPath(
          pathDescription,
          location,
          ':farmId',
          farm.id,
        )
        if (location !== redirectPath) {
          yield put(push(redirectPath))
        }
      }
    }
  } catch (e) {
    return
  }
}

function* getCategories() {
  try {
    const { data: categories } = yield call(api.getCategories)
    yield put(actions.setCategories(categories))
  } catch (_e) {
    return
  }
}

export function* ensureUserLoaded() {
  let user = yield select((state) => state.user)
  if (!user.id) {
    const action = yield take(types.SET_USER)
    user = action.user
  }

  return user
}
