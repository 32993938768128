import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/daily_dairy_report/`

export const DailyMilk = [
  { name: 'Entrega a planta', field: 'truck_milk', only: ['create', 'edit'], value: '' },
  { name: 'Leche descarte', field: 'discard_milk', only: ['create', 'edit'], value: '' },
  { name: 'Leche ternero', field: 'calf_milk', only: ['create', 'edit'], value: '' },
  { name: 'Leche personal', field: 'staff_milk', only: ['create', 'edit'], value: '' },
]



export const DailyCattle = [
  { name: 'Vacas en ordeña', field: 'milking_cows', only: ['create', 'edit'], value: '' },
  { name: 'Ingreso vacas nuevas', field: 'cow_entry', only: ['create', 'edit'], value: '' },
  { name: 'Salidas a secado', field: 'drying_cows', only: ['create', 'edit'], value: '' },
  { name: 'Potrero pastoreo am', field: 'grazing_paddock_am', only: ['create', 'edit'], value: '' },
  { name: 'Potrero pastoreo pm', field: 'grazing_paddock_pm', only: ['create', 'edit'], value: '' },
  { name: 'Vacas lote 1', field: 'cows_per_herd', only: ['create', 'edit'], value: '' },
  { name: 'Vacas lote 2', field: 'cows_per_herd_2', only: ['create', 'edit'], value: '' },
]



export const DailyMeasurements = [
  { name: 'Medición de estanque', field: 'pond_measurement', only: ['create', 'edit'], value: '' },
  { name: 'Medición de camión', field: 'truck_measurement', only: ['create', 'edit'], value: '' },
]


export const dateFields = [{ name: 'Fecha', field: 'date', type: 'date' }]

export const fields = [
  ...dateFields,
  ...DailyMilk,
  ...DailyCattle,
  ...DailyMeasurements,
]

export const all = (productive_unit_id) => axios.get(`${RESOURCE_LOCATION}?productive_unit_id=${productive_unit_id}`)
  .then(response => {
    return response.data
  })
  .catch(error => {
    console.error('Error fetching data from API:', error)
    throw error
  })

export const create = async (cattle_summary) => {
  const formattedDate = new Date(cattle_summary.date).toISOString()

  const dataToSend = {
    ...cattle_summary,
    date: formattedDate,
  }
  console.log('vacas2', dataToSend)

  return axios
    .post(RESOURCE_LOCATION, {
      cattle_summary: dataToSend,
    })
    .then(dataGetter)
    .catch((e) => {
      throw e.response
    })
}



export const edit = async (cattleSummaryId, cattle_summary) => {
  const formattedDate = new Date(cattle_summary.date).toISOString()

  const dataToSend = {
    ...cattle_summary,
    date: formattedDate,
  }

  return axios
    .put(`${RESOURCE_LOCATION}${cattleSummaryId}/`, {
      daily_dairy_report: dataToSend,
    })
    .then(dataGetter)
    .catch((e) => {
      throw e.response
    })
}





export const remove = (farmOwnerId, farmId, cattleSummaryId) =>
  axios.delete(`${RESOURCE_LOCATION}/${cattleSummaryId}`).then(dataGetter)
