import React, { useState, useEffect } from 'react'
import { Grid, Paper, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { Business } from 'utils/api/models'
import ResourcesAsTable from './ResourcesAsTable'
import withOwnerParam from 'hoc/withOwnerParam'
import * as actions from 'actions'

const Businesses = () => {
  const [businessesData, setBusinessesData] = useState()
  const routeParams = useSelector((state) => state.routeParams)
  const dispatch = useDispatch()

  async function getBusinessData() {
    const businesses = await Business.all()
    setBusinessesData(businesses)
    dispatch(actions.breadcrumbResourcesUpdated({ farmOwnerId: true }))
  }

  useEffect(() => getBusinessData(), [
    routeParams.userId,
    routeParams.farmOwnerId,
  ])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
          >
            <h2> Administrar empresas </h2>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          position='relative'
          height='80vh'
        >
          {businessesData ? (
            <ResourcesAsTable
              resources={businessesData}
              model={Business}
              title='Empresa'
              refreshData={async () => await getBusinessData()}
              setSelected={(id) => console.log(id)}
              parentSelections={[]}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}
export default Businesses
