import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as types from 'actions/types'
import _ from 'lodash'

function useParams() {
  const farmOwners = useSelector(({ farmOwners }) => farmOwners)
  const farms = useSelector(({ farms }) => farms)
  const routeParams = useSelector(({ routeParams }) => routeParams)
  const year = Number.isInteger(routeParams.year)
    ? routeParams.year
    : new Date().getFullYear()
  const farmId = Number.isInteger(routeParams.farmId)
    ? routeParams.farmId
    : farms[0]?.id
  const farmOwnerId = Number.isInteger(routeParams.farmOwnerId)
    ? routeParams.farmOwnerId
    : farmOwners[0]?.id

  const templateMeadowId = _.find(farms, { id: farmId })?.template_meadow_id

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch({
      type: types.SET_ROUTE_PARAMS,
      params: {
        templateMeadowId,
      },
    })
  }, [farmId, templateMeadowId])
  return { year, farmId, farmOwnerId }
}

export default useParams
