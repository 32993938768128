import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

//const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/meadows/:templateMeadowId/soil_management/managements`
const RESOURCE_LOCATION_OWNER = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/meadows/:templateMeadowId/soil_management/managements_owner`


export const fields = [{ name: 'Nombre', field: 'name' }]

export const all = () => axios.get(RESOURCE_LOCATION_OWNER).then(dataGetter)

