import * as types from 'actions/types'

const businesses = (state = [], action) => {
  switch (action.type) {
    case types.SET_BUSINESSES: {
      return action.businesses
    }
    default:
      return state
  }
}

export default businesses
