import Paper from '@material-ui/core/Paper'
import { makeStyles, Typography, Box } from '@material-ui/core/'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import {
  FarmOwner,
  Farm,
  ManagementCategory,
  ResourceCategory,
} from 'utils/api/models'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import { format, endOfDay, startOfDay } from 'date-fns'
import es from 'date-fns/locale/es'
import ManagementCategorySelector from 'components/ManagementCategorySelector'

/*function callData() {
const names = all()
console.log(names)
}*/

/*callData()*/

const names = []

//console.log(names)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    border: '1px solid rgba(180, 180, 180, 0.2)',
  },
  heading: {
    //marginLeft: '25px',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  formControl: {
    //margin: theme.spacing.unit,
    marginTop: '20px',
    minWidth: 400,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  filterContainer: {
    display: 'flex',
    gap: '60px',
    alignItems: 'flex-end',
    //border: '1px solid red',
  },
  filterContainerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    //height: 'max-content',
    gap: '30px',
    //border: '1px solid red',
  },
  managementSelector: {
    margin: '0px',
  },
}))

function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  }
}

export default function GlobalFilters({
  selectedFarms,
  setSelectedFarms,
  getData,
  state,
  setState,
  selectedManagementCategories,
  setSelectedManagementCategories,
  selectedResourceCategories,
  setSelectedResourceCategories,
}) {
  const [owners, setOwners] = useState()
  const [farmsData, setFarmsData] = useState()
  const [selectedFarmOwners, setSelectedFarmOwners] = useState([])

  const states = useSelector((state) => state)
  //console.log('analytics state : ', states.farmOwners)

  function getOwnersData() {
    const _providers = states.farmOwners
    setOwners(_providers)
  }

  async function getFarmData() {
    let callFarms = []
    for (var i = 0; i < selectedFarmOwners.length; i++) {
      const f = await Farm.byId(selectedFarmOwners[i])
      callFarms.push(f)
    }
    let flattenFarms = _.flatten(callFarms)
    //console.log('callFarms: ', flattenFarms)
    setFarmsData(flattenFarms)
  }

  useEffect(() => getOwnersData(), [])

  useEffect(() => {
    getFarmData()
  }, [selectedFarmOwners])

  const [snackOwners, setSnackOwners] = useState(false)
  const [snackFarms, setSnackFarms] = useState(false)

  const handleChangeselectedFarmOwners = (value) => {
    if (value.length <= 5) {
      setSelectedFarmOwners(value)
    } else {
      setSnackOwners(true)
    }
  }

  const handleChangeselectedFarms = (value) => {
    if (value.length <= 5) {
      setSelectedFarms(value)
    } else {
      setSnackFarms(true)
    }
  }

  useEffect(() => {
    //por arreglar
    setSelectedFarms([])
    /*let newArray = selectedFarms
        
        var newSelectedFarms = _.remove(selectedFarms, function(n) {
            console.log('consulta: ', farmsData)
            return !_.includes(farmsData, n)
          }); 
        console.log(selectedFarms)
        setSelectedFarms(selectedFarms)

       /* for (var i = 0; i < selectedFarms.length ; i++ ){
            if (_.includes(farmsData, selectedFarms[i])){
                null
            } else {
                _.remove
            }
        }*/
  }, [selectedFarmOwners])

  const [isLoading, setIsLoading] = useState(false)
  const [managementCategories, setManagementCategories] = useState([])

  async function getManagementCategories(farms, startDate, endDate) {
    const data = await ManagementCategory.byId(farms, startDate, endDate)
    setManagementCategories(data)
  }

  useLayoutEffect(() => {
    setSelectedManagementCategories([])
    if (selectedFarms.length >= 1) {
      getManagementCategories(
        selectedFarms,
        format(startOfDay(state[0].startDate), 'yyyy-MM-dd HH:mm:ss'),
        format(endOfDay(state[0].endDate), 'yyyy-MM-dd HH:mm:ss'),
      )
    }
  }, [selectedFarms, state])

  const handleChangeselectedManagementCategories = (value, ref) => {
    if (isLoading === false) {
      if (value.length <= 5) {
        setSelectedManagementCategories(value)
      } else {
        setSnackFarms(true)
      }
    }
  }

  const [resourceCategories, setResourceCategories] = useState([])

  async function getResourceCategories(
    farms,
    startDate,
    endDate,
    selectedManagementCategories,
  ) {
    setIsLoading(true)
    const data = await ResourceCategory.byId(
      farms,
      startDate,
      endDate,
      selectedManagementCategories,
    )
    if (selectedManagementCategories.length >= 1) {
      setResourceCategories(data)
      setSelectedResourceCategories(data.map((v) => v.id))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setSelectedResourceCategories([])
    if (selectedManagementCategories.length >= 1) {
      getResourceCategories(
        selectedFarms,
        format(startOfDay(state[0].startDate), 'yyyy-MM-dd HH:mm:ss'),
        format(endOfDay(state[0].endDate), 'yyyy-MM-dd HH:mm:ss'),
        selectedManagementCategories,
      )
    } else {
      setSelectedResourceCategories([])
    }
  }, [selectedFarms, state, selectedManagementCategories])

  const handleChangeselectedResourceCategories = (value) => {
    setSelectedResourceCategories(value)
  }

  //console.log('owners: ', selectedFarmOwners, 'Farms :', selectedFarms)

  //const state = useSelector((state) => state)

  //console.log('global filter state: ',state)
  //console.log('state', state.startDate)
  //console.log('fns', format(state.startDate, 'yyyy-MM-dd'))

  const stateHandleChange = (value) => {
    setState([value.selection])
    setSelectedManagementCategories([])
    setSelectedResourceCategories([])
  }

  const classes = useStyles()
  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <Typography
            variant='h1'
            className={classes.heading}
            //color='primary'
          >
            Filtros globales
          </Typography>
        </div>
        <div className={classes.filterContainer}>
          <DateRangePicker
            onChange={(item) => stateHandleChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            rangeColors={['#5FAA00']}
            fixedHeight={true}
            direction='horizontal'
            locale={es}
            //renderStaticRangeLabel={renderStaticRangeLabel}
            inputRanges={[]}
            staticRanges={createStaticRanges([
              {
                label: '1° semestre Pasado',
                range: () => ({
                  startDate: new Date(
                    `${new Date().getFullYear() - 1}, 01, 01`,
                  ),
                  endDate: new Date(`${new Date().getFullYear() - 1}, 06, 30`),
                }),
              },
              {
                label: '1° semestre Actual',
                range: () => ({
                  startDate: new Date(`${new Date().getFullYear()}, 01, 01`),
                  endDate: new Date(`${new Date().getFullYear()}, 06, 30`),
                }),
              },
              {
                label: 'Próximo 1° semestre',
                range: () => ({
                  startDate: new Date(
                    `${new Date().getFullYear() + 1}, 01, 01`,
                  ),
                  endDate: new Date(`${new Date().getFullYear() + 1}, 06, 30`),
                }),
              },
              {
                label: '2° semestre Pasado',
                range: () => ({
                  startDate: new Date(
                    `${new Date().getFullYear() - 1}, 07, 01`,
                  ),
                  endDate: new Date(`${new Date().getFullYear() - 1}, 12, 31`),
                }),
              },
              {
                label: '2° semestre Actual',
                range: () => ({
                  startDate: new Date(`${new Date().getFullYear()}, 07, 01`),
                  endDate: new Date(`${new Date().getFullYear()}, 12, 31`),
                }),
              },
              {
                label: 'Próximo 2° semestre',
                range: () => ({
                  startDate: new Date(
                    `${new Date().getFullYear() + 1}, 07, 01`,
                  ),
                  endDate: new Date(`${new Date().getFullYear() + 1}, 12, 31`),
                }),
              },
              {
                label: 'Año Pasado',
                range: () => ({
                  startDate: new Date(
                    `${new Date().getFullYear() - 1}, 01, 01`,
                  ),
                  endDate: new Date(`${new Date().getFullYear() - 1}, 12, 31`),
                }),
              },
              {
                label: 'Año Actual',
                range: () => ({
                  startDate: new Date(`${new Date().getFullYear()}, 01, 01`),
                  endDate: new Date(`${new Date().getFullYear()}, 12, 31`),
                }),
              },
              {
                label: 'Próximo Año',
                range: () => ({
                  startDate: new Date(
                    `${new Date().getFullYear() + 1}, 01, 01`,
                  ),
                  endDate: new Date(`${new Date().getFullYear() + 1}, 12, 31`),
                }),
              },
            ])}
          />
          <div className={classes.filterContainerColumn}>
            <MultipleSelector
              value={selectedFarmOwners}
              handleChange={handleChangeselectedFarmOwners}
              snackOpen={snackOwners}
              setSnackOpen={setSnackOwners}
              menuValue={owners}
              snakMessage='Se deben seleccionar como máximo 5 Propietarios'
              label='Propietarios'
            />
            <MultipleSelector
              value={selectedFarms}
              handleChange={handleChangeselectedFarms}
              snackOpen={snackFarms}
              setSnackOpen={setSnackFarms}
              menuValue={farmsData}
              disabled={selectedFarmOwners.length === 0 && true}
              snakMessage='Se deben seleccionar como máximo 5 Campos '
              label='Campos'
            />
            <MultipleSelector
              value={selectedManagementCategories}
              handleChange={handleChangeselectedManagementCategories}
              snackOpen={snackFarms}
              setSnackOpen={setSnackFarms}
              menuValue={managementCategories}
              disabled={selectedFarms.length === 0 && true}
              snakMessage='Se deben seleccionar como máximo 5 Cultivos '
              label='Cultivos'
            />
            <MultipleSelector
              value={selectedResourceCategories}
              handleChange={handleChangeselectedResourceCategories}
              snackOpen={snackFarms}
              setSnackOpen={setSnackFarms}
              menuValue={resourceCategories}
              disabled={selectedManagementCategories.length === 0 && true}
              snakMessage='Se deben seleccionar como máximo 5 Tipos de Insumo '
              label='Tipo de Insumo'
              close={!isLoading ? null : false}
            />
            <Typography variant='caption'>
              Tipo de insumo sólo aplica a insumos
            </Typography>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              disabled={selectedFarms.length === 0}
              onClick={() => getData(state)}
            >
              {selectedFarms.length === 0
                ? 'Seleccionar Campos'
                : 'Aplicar Filtro'}
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  )
}

function MultipleSelector({
  value,
  handleChange,
  snackOpen,
  setSnackOpen,
  menuValue,
  snakMessage,
  label,
  disabled = false,
}) {
  const [close, setClose] = useState(false)

  const classes = useStyles()
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor='select-multiple-Farms-Owners'>{label}</InputLabel>
        <Select
          disabled={disabled}
          multiple
          //onClick={()=> setClose(!close)}
          value={value}
          //open={close}
          onChange={(e) => handleChange(e.target.value)}
          input={<Input id='select-multiple-chip' />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={_.find(menuValue, { id: value })?.name}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {menuValue?.map((value) => (
            <MenuItem key={value.name} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={(e, r) => r === 'timeout' && setSnackOpen(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id='message-id'>{snakMessage}</span>}
      />
    </div>
  )
}
