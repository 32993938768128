import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
import { Resource, ResourceCategory } from '.'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/meadows`

export const fields = [
  { name: 'Nombre', field: 'soil_resource.name', only: 'list' },
  {
    name: 'Categoria Insumo',
    field: 'soil_resource_category_id',
    model: ResourceCategory,
    only: 'create',
  },
  {
    name: 'Insumo',
    field: 'soil_resource_id',
    depends: 'soil_resource_category_id',
    model: Resource,
    only: 'create',
    editValue: 'soil_resource',
  },
  { name: 'Dosis', field: 'dose', type: 'number' },
  {
    name: 'Costo/u',
    field: 'unit_price',
    type: 'currency',
    suggestedValue: ({ resource }) => resource && resource.unit_price,
  },
  {
    name: 'Costo/Ha',
    field: 'task_resource_total',
    type: 'currency',
    suggestedValue: (selectionData, form_values) =>
      form_values.unit_price &&
      form_values.dose &&
      form_values.unit_price * form_values.dose,
  },
  { name: 'Observaciones', field: 'observation' },
]

export const all = (meadow_id, management_id, task_group_id, task_id) =>
  axios
    .get(
      `${RESOURCE_LOCATION}/${meadow_id}/soil_management/managements/${management_id}/task_groups/${task_group_id}/tasks/${task_id}/task_soil_resources`,
    )
    .then(dataGetter)

export const remove = (
  meadow_id,
  management_id,
  task_group_id,
  task_id,
  task_soil_resource_id,
) =>
  axios
    .delete(
      `${RESOURCE_LOCATION}/${meadow_id}/soil_management/managements/${management_id}/task_groups/${task_group_id}/tasks/${task_id}/task_soil_resources/${task_soil_resource_id}`,
    )
    .then(dataGetter)

export const edit = (
  task_soil_resource_id,
  task_soil_resource,
  meadow_id,
  management_id,
  task_group_id,
  task_id,
) =>
  axios
    .put(
      `${RESOURCE_LOCATION}/${meadow_id}/soil_management/managements/${management_id}/task_groups/${task_group_id}/tasks/${task_id}/task_soil_resources/${task_soil_resource_id}`,
      {
        task_soil_resource,
      },
    )
    .then(dataGetter)
