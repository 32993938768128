import useParams from 'hooks/useParams'
import React from 'react'
import { Redirect } from 'react-router'

function Home() {
  const { farmOwnerId, farmId, year } = useParams()
  return <Redirect to={`/${farmOwnerId}/${farmId}/dashboard/${year}`} />
}

export default Home

