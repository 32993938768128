// import * as types from 'actions/types'
import _ from 'lodash'
import * as types from 'actions/types'

const routeParams = (state = {}, action) => {
  switch (action.type) {
    case '@@router/ROUTE_CHANGED':
    case types.SET_ROUTE_PARAMS:
      return {
        ...state,
        ..._(action.params)
          .omit(0)
          .mapValues((p) => Number(p))
          .value(),
      }

    default:
      return state
  }
}

export default routeParams
