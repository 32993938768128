import * as types from 'actions/types'
import _ from 'lodash'

const INITIAL_STATE = {
  all: [],
  spends: [],
  incomes: [],
}
const categories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CATEGORIES: {
      const spends = _(action.categories)
        .filter((c) => c.document_type === 'spend')
        .value()
      const incomes = _(action.categories)
        .filter((c) => c.document_type === 'income')
        .value()
      return { all: action.categories, spends, incomes }
    }
    default:
      return state
  }
}

export default categories
