import { ResponsiveLine } from '@nivo/line'
import es from 'date-fns/locale/es'
import { format } from 'date-fns'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const CustomTooltip = ({ data, month, units }) => {
  //console.log('toltip: ', data)
  return (
    <div
      style={{
        padding: '20px',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    >
      Unidad productiva: <strong>{data.point.serieId}</strong>
      <br />
      Fecha:{' '}
      <b>
        {format(data.point.data.x, month ? 'MMMM-yyyy' : 'dd-MMMM-yyyy', {
          locale: es,
        })}
      </b>
      <br />
      Valor:{' '}
      <b>
        {Number(data.point.data.yFormatted).toFixed(0)}{' '}
        {units == 'porcentaje' ? '%' : ''}
      </b>
    </div>
  )
}

export const MyResponsiveLine = ({ data, month, units }) => (
  <ResponsiveLine
    style={{ height: 500 }}
    data={data}
    curve='monotoneX'
    tooltip={(data) => (
      <CustomTooltip data={data} month={month} units={units} />
    )}
    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    xScale={{ format: '%Y-%m-%dT%H:%M:%S.%L', type: 'time' }}
    xFormat='time:%Y-%m-%dT%H:%M:%S.%L'
    colors={['#5FAA00', '#A03CB5', '#148AF5', '#FF7A00']}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: false,
    }}
    yFormat=' >-.2f'
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: month ? '%m-%Y' : '%d-%m-%Y',
      legend: 'transportation',
      legendOffset: 36,
      legendPosition: 'middle',
      truncateTickAt: 0,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: (value) => (units == 'porcentaje' ? `${value}%` : value),
      legend:
        units == 'int'
          ? 'Unidades'
          : units == 'porcentaje'
          ? 'Porcentaje'
          : 'count',
      legendOffset: -40,
      legendPosition: 'middle',
      truncateTickAt: 0,
    }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabel='data.yFormatted'
    pointLabelYOffset={-12}
    enableTouchCrosshair={true}
    useMesh={true}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
)
