import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  withStyles,
  IconButton,
  Box,
  Toolbar,
  Typography,
  Tooltip,
  Modal,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import {
  eachDayOfInterval,
  endOfWeek,
  startOfWeek,
  sub,
  add,
  format,
  addDays,
  subDays,
  isPast,
} from 'date-fns'
import _ from 'lodash'

import CardHeaderDay from 'components/CardHeaderDay'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import clsx from 'clsx'
import AddIcon from '@material-ui/icons/Add';

import { useSnackbar } from 'notistack'
import ResourcesAsTable from './ResourcesAsTable'
import {
  TaskSoilResource,
  Management,
  Task as TaskModel,
} from 'utils/api/models'
import { denormalizeManagement } from './ManagementTemplates'
import ManagementTemplateSelector from 'components/ManagementTemplateSelector'
import MachineryEntry from './MachineryEntry'
import UndoIcon from '@material-ui/icons/Undo'
import DeleteIcon from '@material-ui/icons/Delete'
import AssignTasks from './AssignTasks'

const CardContentDay = withStyles({
  root: {
    height: '20vh',
    overflow: 'auto',
    padding: '4px',
  },
})(CardContent)

const useStyles = makeStyles((theme) => ({
  containerCalendar: {
    padding: theme.spacing(1),
    backgroundColor: 'white',
    textAlign: 'center',
  },
  modal: {
    overflow: 'auto',
    position: 'absolute',
    maxWidth: 800,
    maxHeight: '90%',
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    padding: '10px',
  },
}))

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    flex: '1 1 100%',
  },
}))

const EnhancedToolbar = ({
  setOpenNewManagement,
  setOpenAssignTasks,
  setOpenDelete,
  ...props
}) => {
  const classes = useToolbarStyles()
  const numMeadowsSelected = _(props.active_meadows).keys().value().length
  const numTasksSelected = _(props.selected_tasks).keys().value().length

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numTasksSelected > 0,
      })}
    >
      {numTasksSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numTasksSelected} Tareas seleccionadas
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='subtitle1'
          id='tableTitle'
          component='div'
        >
          Calendario de tareas
        </Typography>
      )}

      {numTasksSelected > 0 && (
        <Tooltip title='Descartar selección de tareas'>
          <IconButton aria-label='discard selection' onClick={props.clearTasks}>
            <UndoIcon color={'secondary'} />
          </IconButton>
        </Tooltip>
      )}
      {numTasksSelected > 0 && (
        <Tooltip title='Marcar tareas realizadas'>
          <IconButton
            aria-label='mark done'
            onClick={() => setOpenAssignTasks(true)}
          >
            <AssignmentTurnedInIcon color={'secondary'} />
          </IconButton>
        </Tooltip>
      )}
      {numTasksSelected > 0 && (
        <Tooltip title='Eliminar tareas seleccionadas'>
          <IconButton
            aria-label='mark delete'
            onClick={() => setOpenDelete(true)}
          >
            <DeleteIcon color={'secondary'} />
          </IconButton>
        </Tooltip>
      )}
      {numMeadowsSelected > 0 && (
        <Tooltip title='Añadir manejo'>
          <IconButton
            aria-label='add management'
            onClick={() => setOpenNewManagement(true)}
          >
            <AddIcon color={numTasksSelected > 0 ? 'secondary' : 'primary'} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

function TasksCalendar({
  date,
  setDate,
  tasksByDay = [],
  taskGroupsByDay = [],
  machineriesByDay = [],
  setTaskGroupId,
  setTaskId,
  selected_tasks,
  toggleTasks,
  clearTasks,
  ...props
}) {
  const dates = eachDayOfInterval({
    start: startOfWeek(sub(date, { weeks: 1 })),
    end: endOfWeek(add(date, { weeks: 1 })),
  })

  const [openNewManagement, setOpenNewManagement] = React.useState(false)
  const [openAssignTasks, setOpenAssignTasks] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onDelete = () => {
    async function batchDelete() {
      try {
        await TaskModel.batchDelete(_.keys(selected_tasks))
        enqueueSnackbar('Se eliminaron las tareas con éxito', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })

      } catch (e) {
        enqueueSnackbar('No se pudieron eliminar las tareas seleccionadas', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        /* handle error */
      } finally {
        clearTasks()
        setOpenDelete(false)
        props.refreshData()
      }
    }
    batchDelete()
  }

  return (
    <Box p={1} bgcolor='white' textAlign='center'>
      <NewManagementModal
        open={openNewManagement}
        setOpen={setOpenNewManagement}
        d={date}
        active_meadows={props.active_meadows}
        refreshData={props.refreshData}
      />
      <AssignTasks
        open={openAssignTasks}
        onClose={() => setOpenAssignTasks(false)}
        date={date}
        refreshData={props.refreshData}
        selected_tasks={selected_tasks}
        onSuccess={clearTasks}
      />
      <Dialog
        disablePortal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {'Confirme antes de eliminar'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            ¿Está seguro que desea elimiar las tareas seleccionadas? Todos los
            datos asociados también se eliminarán. Esta operación no se puede
            revertir.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color='primary'>
            Cancelar
          </Button>
          <Button onClick={onDelete} color='danger'>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <EnhancedToolbar
        setOpenNewManagement={setOpenNewManagement}
        setOpenAssignTasks={setOpenAssignTasks}
        setOpenDelete={setOpenDelete}
        active_meadows={props.active_meadows}
        selected_tasks={selected_tasks}
        clearTasks={clearTasks}
      />
      <IconButton
        aria-label='expand row'
        size='small'
        onClick={() => setDate(subDays(date, 7))}
      >
        <KeyboardArrowUpIcon color='primary' />
      </IconButton>
      {_(dates)
        .chunk(7)
        .map((c, i) => (
          <Grid container key={`week-${i}`}>
            {c.map((d) => {
              const dayMachineries =
                machineriesByDay[format(d, 'dd/MM/yyyy')] || []
              const dayTasks = tasksByDay[format(d, 'dd/MM/yyyy')] || []
              const showDayWarning =
                _(dayTasks)
                  .filter(
                    (t) =>
                      isPast(new Date(t.suggested_date)) &&
                      !t.service_provider?.id,
                  )
                  .value().length > 0
              return (
                <Grid item xs key={`day-${d.getTime()}`}>
                  <Card>
                    <CardHeaderDay
                      {...props}
                      title={format(d, 'dd/MM')}
                      d={d}
                      date={date}
                      refreshData={props.refreshData}
                      onClick={() => {
                        setDate(d)
                        toggleTasks(_.map(dayTasks, 'id'))
                      }}
                      machineriesCount={_(dayMachineries).keys().value().length}
                      showDayWarning={showDayWarning}
                    />

                    <CardContentDay>
                      {_(dayMachineries)
                        .mapValues((tasks, machineryName) => (
                          <MachineryEntry
                            key={`task-${tasks.id}`}
                            {...props}
                            selectTask={(t) => {
                              setTaskGroupId(t.task_group_id)
                              setTaskId(t.id)
                            }}
                            selected_tasks={selected_tasks}
                            tasks={tasks}
                            machineryName={machineryName}
                            toggleTasks={toggleTasks}
                          />
                        ))
                        .values()
                        .value()}
                    </CardContentDay>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        ))
        .value()}
      <IconButton
        aria-label='expand row'
        size='small'
        onClick={() => setDate(addDays(date, 7))}
      >
        <KeyboardArrowDownIcon color='primary' />
      </IconButton>
    </Box>
  )
}

const Task = ({ task, ...props }) => {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color='textSecondary'
          gutterBottom
        >
          Maquinaria seleccionada para el día {format(props.day, 'dd-MMM-yy')}
        </Typography>
        <Typography variant='h5' component='h2'>
          {task.machinery && task.machinery.name}
        </Typography>
        <Typography className={classes.pos} color='textSecondary'>
          Frecuencia: {task.machinery_frequency}
        </Typography>
        <Typography variant='body2' component='p'>
          Precio: {task.machinery_price}
          <br />
        </Typography>
        <Box position='relative'>
          <Grid container>
            <ResourcesAsTable
              resources={task.task_soil_resources || []}
              model={TaskSoilResource}
            />
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

function NewManagementModal({ open, setOpen, ...props }) {
  const classes = useStyles()
  const [task, setTask] = React.useState({})
  const [selected, setSelected] = React.useState(false)
  const [selectionState, setSelectionState] = React.useState({})
  const { enqueueSnackbar } = useSnackbar()

  const generateObjectSelected = () => {
    const [management] = denormalizeManagement(selected[2], selectionState)

    const task_groups = _(management.task_groups)
      .filter()
      .map((tg) => ({
        ...tg,
        tasks: _(tg.tasks)
          .filter()
          .map((t) => ({
            ...t,
            suggested_date: addDays(
              props.d,
              t.relative_days - task.relative_days,
            ),
            machinery_id: t.machinery.id,
            task_soil_resources: _.map(t.task_soil_resources, (tsr) => ({
              ...tsr,
              soil_resource_id: tsr.soil_resource.id,
            })),
          }))
          .value(),
      }))
      .value()
    return {
      ...management,
      task_groups,
      soil_management_category_id: management.soil_management_category.id,
      start_date: props.d,
    }
  }

  const createManagements = () => {
    const management = generateObjectSelected()

    Management.createBatch(_(props.active_meadows).keys().value(), {
      ...management,
    })
      .then(function () {
        enqueueSnackbar('Se instanció el manejo con éxito', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        props.refreshData()
        setOpen(false)
      })
      .catch(function () {
        enqueueSnackbar('Error! no se pudo instanciar manejo', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      })
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <div className={classes.modal}>
        <h2 id='simple-modal-title'>
          Planificar Manejo ({format(props.d, 'dd-MMM-yy')})
        </h2>
        <br /> <br />
        <ManagementTemplateSelector
          date={props.d}
          onSelectionComplete={(selections, state) => {
            setSelectionState(state)
            setSelected(selections)
            const selectionsArr = _(selections).values().value()
            const task = state.tasks[selectionsArr[selectionsArr.length - 1]]
            setTask(task)
          }}
        />
        <br />
        <Task task={task || {}} day={props.d} />
        <Button
          variant='contained'
          color='primary'
          size='large'
          fullWidth
          onClick={createManagements}
          disabled={!!_.isEmpty(task)}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  )
}

export default TasksCalendar
