import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

export const _delete = (docId, itemId, detailId) =>
  axios.delete(
    `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/:businessId/documents/${docId}/items/${itemId}/details/${detailId}`,
  )

export const put = (docId, itemId, detailId, item_detail) =>
  axios
    .put(
      `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/:businessId/documents/${docId}/items/${itemId}/details/${detailId}`,
      {
        item_detail,
      },
    )
    .then(dataGetter)

export const post = (docId, itemId, item_detail) =>
  axios
    .post(
      `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/:businessId/documents/${docId}/items/${itemId}/details/`,
      {
        item_detail,
      },
    )
    .then(dataGetter)

export const putMany = async (docId, ids, category) =>
  axios
    .put(
      `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/:businessId/documents/${docId}/item_details`,
      {
        item_details: {
          ids,
          ...category,
        },
      },
    )
    .then(dataGetter)
