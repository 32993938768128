import React, { useEffect, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import { CSVLink } from 'react-csv'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from '@material-ui/core'

import CategoriesTable from '../CategoriesTable'
import StateResultParams from 'components/StateResultParams'

import { useSnackbar } from 'notistack'
import withYearParam from 'hoc/withYearParam'
import { ResultState as model } from 'utils/api/models'
import { useSelector } from 'react-redux'
import withOwnerBusinessParam from 'hoc/withOwnerBusinessParam'
import MonthPicker from './MonthPicker'

const HeaderTableCell = withStyles((theme) => ({
  head: {
    borderBottom: '2px solid black',
    fontWeight: 900,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  titleTable: {
    marginLeft: '25px',
  },
  paperDataParams: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    '& > *': {
      margin: '10px',
    },
  },
})

function ResultState() {
  const [rows, setRows] = useState([])
  const [dataForCSV, setDataForCSV] = useState(null)
  const [toMonth, setToMonth] = useState('')
  const [dataParams, setDataParams] = useState({
    l_vo: 0,
    l_vm: 0,
    c_a: 0,
    l_ha: 0,
  })
  const params = useSelector((state) => state.routeParams)
  const form = useFormik({
    initialValues: {
      liters: 5000,
      liter_price: 260,
      cows: 500,
      ha: 1000,
      from_month: { year: new Date().getFullYear(), month: 1 },
    },
 
  })
  const isLoading = useSelector((state) => state.network.isLoading)

  const headers = [
    { label: 'Categoría', key: 'category' },
    { label: 'Total ($)', key: 'total' },
    { label: 'Total por vaca ($)', key: 'total_by_cow' },
    { label: 'Total por hectarea ($)', key: 'total_by_ha' },
    { label: 'Total por litro de leche ($)', key: 'total_by_milk' },
    { label: '% de I.L.', key: 'i_l_percentage' },
  ]

  const formatToCSV = (name, element) => {
    return {
      category: name,
      total: element.total,
      total_by_cow: element.total_by_cow,
      total_by_ha: element.total_by_ha,
      total_by_milk: element.total_by_milk,
      i_l_percentage: element.i_l_percentage,
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  function monthSum(from_month) {
    const d = moment({ year: from_month.year, month: from_month.month - 1, day: 1});
    console.log('d: ', d)
    var futureMonth = d.add(11, 'M');
    console.log('futureMonth: ', futureMonth)
    return { year: futureMonth.year(), month: futureMonth.month() + 1 }
  }

  const handleSubmit = () => {
    async function getData() {
      const { data, l_vo, l_vm, c_a, l_ha } = await model.all(
        params.businessId,
        form.values,
        monthSum(form.values.from_month),
      )
      setRows(data)
      setDataParams({ l_vo: l_vo, l_vm: l_vm, c_a: c_a, l_ha: l_ha })

      //Setear datos para csv
      const genCSV = []

      // eslint-disable-next-line no-unused-expressions
      data.forEach((row) => {
        genCSV.push(formatToCSV(row.name, row))
        if (row.super_categories.length !== 0) {
          genCSV.push(
            formatToCSV(
              `${row.name}  - ${row.super_categories[0].name}`,
              row.super_categories[0],
            ),
          )
          row.super_categories[0].categories.forEach(
            (super_category_category) => {
              genCSV.push(
                formatToCSV(
                  `${row.name}  - ${row.super_categories[0].name} - ${super_category_category.name} `,
                  row,
                ),
              )
              genCSV.push(
                formatToCSV(
                  `${row.name}  - ${row.super_categories[0].name} - ${super_category_category.name} `,
                  super_category_category,
                ),
              )
            },
          )
        }

        row.categories.forEach((category) => {
          genCSV.push(formatToCSV(`${row.name} - ${category.name}`, category))
          category.sub_categories.forEach((sub_category) => {
            genCSV.push(
              formatToCSV(
                `${row.name} - ${category.name} - ${sub_category.name}`,
                sub_category,
              ),
            )
          })
        })

        if (row.result_name) {
          genCSV.push({
            category: _.capitalize(row.result_name),
            total: row.margin,
          })
        }
      })

      setDataForCSV(genCSV)

      enqueueSnackbar('Se han actualizado los datos', {
        variant: 'success',
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    getData()
  }

  React.useEffect(handleSubmit, [params])

  console.log(rows)

  const classes = useStyles()
  return (
    <React.Fragment>
      <StateResultParams form={form} handleSubmit={handleSubmit} toMonth={monthSum(form.values.from_month)}/>
      <br />

      <Paper className={classes.paperDataParams}>
        
        <Chip label={`L/VO ${Math.round(dataParams.l_vo * 100) / 100}`} />
        <Chip label={`L/VM ${Math.round(dataParams.l_vm * 100) / 100}`} />
        <Chip label={`C.A. ${Math.round(dataParams.c_a * 100) / 100}`} />
        <Chip label={`L/ha ${Math.round(dataParams.l_ha * 100) / 100}`} />
      </Paper>

      <br />
      <TableContainer component={Paper}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <h2 className={classes.titleTable}> Estado Resultado </h2>

          <CSVLink
            data={dataForCSV || []}
            headers={headers}
            filename={'Estado Resultado.csv'}
            style={{ textDecoration: 'none' }}
            separator=';'
            onClick={(e) => {
              if (isLoading) {
                return false
              }
            }}
          >
            <Button
              color='primary'
              variant='contained'
              style={{ margin: '10px 25px' }}
              disabled={isLoading}
            >
              Descargar CSV
            </Button>
          </CSVLink>
        </Box>
        <Table
          className={classes.table}
          aria-label='a dense table'
          size='small'
        >
          <TableHead>
            <TableRow hover>
              <HeaderTableCell variant='head' />
              <HeaderTableCell variant='head'>Categoría</HeaderTableCell>
              <HeaderTableCell variant='head' align='right'>
                Total&nbsp;($)
              </HeaderTableCell>
              <HeaderTableCell variant='head' align='right'>
                Total por vaca&nbsp;($)
              </HeaderTableCell>
              <HeaderTableCell variant='head' align='right'>
                Total por hectarea&nbsp;($)
              </HeaderTableCell>
              <HeaderTableCell variant='head' align='right'>
                Total por litro de leche&nbsp;($)
              </HeaderTableCell>
              <HeaderTableCell variant='head' align='right'>
                % de I.L.
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const hasSuperCategory =
                row.super_categories && row.super_categories.length
              return (
                <CategoriesTable key={row.name} segment={row} dataParams={form.values}>
                  {hasSuperCategory ? (
                    <CategoriesTable
                      key={`super-${row.name}`}
                      segment={row.super_categories[0]}
                      isSuperCategory
                      dataParams={form.values}
                    />
                  ) : null}
                </CategoriesTable>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default ResultState
