import React,{useEffect, useState} from 'react' 
import axios from 'axios'
import { BASE_URL, dataGetter } from '../../utils/api'
import { ResponsiveBar } from '@nivo/bar'


import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GraphCards from './GraphCard'
import { makeStyles } from '@material-ui/core/'
import GlobalFilters from './GlobalFilters';
import { addDays, format, endOfDay, startOfDay } from 'date-fns';
import { ResourceCategory } from 'utils/api/models';


const useStyles = makeStyles((theme) => ({

    root: {
        padding: '30px',
    },

    heading: {
        //marginLeft: '25px',
        fontWeight: 'bold',
        fontSize: '2rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
      },

}))

const RESOURCE_LOCATION = `${BASE_URL}/analytics`


export default function Analytics() {
    
    const[resourceAnalytics, setResourceAnalytics] = useState([])
    const[machinaryAnalytics, setMachinaryAnalytics] = useState([])
    const[selectedFarms, setSelectedFarms] = useState([])
    const[selectedManagementCategories, setSelectedManagementCategories] = useState([])
    const[selectedResourceCategories, setSelectedResourceCategories] = useState([])
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: addDays(endOfDay(new Date()), 7),
          key: 'selection',
        },
    ]);
    const classes = useStyles()

    const resourceData = [
        { url:`${BASE_URL}/analytics`, title: 'Cantidad de Insumo', leftLegend: 'Cantidad', bottomLegend: 'Insumo', valueType: 'Kilógramos o Litros' },
        { url:`${BASE_URL}/analytics/costperinput`, title: 'Costo por Insumo', leftLegend: 'Costo clp', bottomLegend: 'Insumo', valueType:'Pesos Chilenos' },
        { url:`${BASE_URL}/analytics/haperinput`, title: 'Superficie por insumo', leftLegend: 'Superficie por Insumo', bottomLegend: 'Insumo', valueType: 'Hectáreas' },
        { url:`${BASE_URL}/analytics/inputcostperha`, title: 'Costo de insumo por ha', leftLegend: 'Costo clp', bottomLegend: 'Insumo', valueType: 'Pesos Chilenos' },
    ]
    
    const machineryData = [
        { url:`${BASE_URL}/analytics/surfacepermachinery`, title: 'Superficie por Máquinaria', leftLegend: 'superficie', bottomLegend: 'Maquinaria', valueType: 'Hectáreas' },
        { url:`${BASE_URL}/analytics/costpermachinery`, title: 'Costo por Máquina', leftLegend: 'Costo', bottomLegend: 'Maquinaria', valueType:'Pesos Chilenos' },
        { url:`${BASE_URL}/analytics/costpermachineryperha`, title: 'Costo por Máquina por ha', leftLegend: 'Costo clp', bottomLegend: 'Maquinaria', valueType:'Pesos Chilenos' },
    ]
    
    console.log('resource data 1°: ', resourceAnalytics)
    console.log('machinary data 1°: ', machinaryAnalytics)
    

    const fetchdata = (state, apiData, saveToAnalytics) => {
            try {
                const fetchPromises = apiData.map(async (x)=> 
                    await axios.get(x.url,{ 
                        params: {
                            farms: selectedFarms,
                            startDate: format(startOfDay(state[0].startDate), 'yyyy-MM-dd HH:mm:ss'),
                            endDate: format(endOfDay(state[0].endDate), 'yyyy-MM-dd HH:mm:ss'),
                            managementCategories: selectedManagementCategories,
                            resourceCategories: selectedResourceCategories,
                        }}),
                    )
                    Promise.all(fetchPromises).then((values) => {             
                    saveToAnalytics([...values.map(value=>value.data)]);
                        });
            } catch (err) {
            //console.error(err); 
        }
    };
    
    const getData = (state) => {
        fetchdata(state, resourceData, setResourceAnalytics )
        fetchdata(state, machineryData , setMachinaryAnalytics)
    }

    


    

    console.log('resource data: ', resourceAnalytics)
    console.log('machinary data: ', machinaryAnalytics)

    return(
        <>
        <GlobalFilters 
            selectedFarms={selectedFarms} 
            setSelectedFarms={setSelectedFarms} 
            getData={getData} state={state} 
            setState={setState}
            selectedManagementCategories={selectedManagementCategories}
            setSelectedManagementCategories={setSelectedManagementCategories}
            selectedResourceCategories={selectedResourceCategories}
            setSelectedResourceCategories={setSelectedResourceCategories}
            />
        <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h1' className={classes.heading}>Insumos</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={3}>
                {resourceData.map((graph, i)=>(
                    <Grid item xs={4}>
                        <GraphCards data={resourceAnalytics[i]} title={graph.title} leftLegend={graph.leftLegend} bottomLegend={graph.bottomLegend} state={state} valueType={graph.valueType} />
                    </Grid>
                ))}
                </Grid>
            </ExpansionPanelDetails>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h1' className={classes.heading}>Maquinaria</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={3}>
                {machineryData.map((graph, i)=>(
                    <Grid item xs={4}>
                        <GraphCards data={machinaryAnalytics[i]} title={graph.title} leftLegend={graph.leftLegend} bottomLegend={graph.bottomLegend} state={state} machinery={true} valueType={graph.valueType} />
                    </Grid>
                ))}
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
       {/* <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h1' className={classes.heading}>Maquinaria</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={3}>        
                    <Grid item xs={6}>
                        <GraphCards data={data[0]} title='Costo por Maquinaria' leftLegend='Superficie ha' bottomLegend='Maquinaria' state={state}/>
                    </Grid>
                    <Grid item xs={6}>
                        <GraphCards data={data[0]} title='Superficie por Maquinaria' leftLegend='Superficie ha' bottomLegend='Maquinaria' state={state}/>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel> */}
        </>
    ) 

}