import * as types from 'actions/types'

const farmOwners = (state = [], action) => {
  switch (action.type) {
    case types.SET_FARM_OWNERS: {
      return action.farmOwners
    }
    default:
      return state
  }
}

export default farmOwners
