
import React, {useRef} from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    marginRight: '20px',
  },
}))

const MonthPicker = ({ from_month, from_onChange }) => {
  const label1 = useRef(null)
  const label2 = useRef(null)

  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const years = Array.from({ length: 10 }, (_, i) => from_month.year - 5 + i);

  const classes = useStyles()

  console.log(label1)

  return (
    <div>
      <FormControl className={classes.formControl} variant="outlined" >
        <InputLabel ref={label1} >Año</InputLabel>
        <Select value={from_month.year} onChange={from_onChange} name='from_month.year'input={
              <OutlinedInput
                labelWidth={label1.current?.clientWidth}
                name="Año"
              />} >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel ref={label2}>Mes</InputLabel>
        <Select value={from_month.month} onChange={from_onChange} name='from_month.month' input={
              <OutlinedInput
                labelWidth={label2.current?.clientWidth}
                name="Mes"
              />}>
          {months.map((month) => (
            <MenuItem key={month} value={month}>
              {new Date(from_month.year, month - 1, 1).toLocaleString('es-ES', { month: 'long' })}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MonthPicker;