import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/cattle_summary/`

export const cattleSummary1 = [
    { name: 'Vacas en Leche', field: 'dairy_cows', only: ['create', 'edit'], value: '' },
    { name: 'Vacas Desecho', field: 'cull_cows', only: ['create', 'edit'], value: '' },
    { name: 'Vacas Secas', field: 'dry_cows', only: ['create', 'edit'], value: '' },
    { name: 'Vacas Pre Parto', field: 'pre_partum_cows', only: ['create', 'edit'], value: '' },
    { name: 'Vaquillas Pre Parto', field: 'pre_partum_heifers', only: ['create', 'edit'], value: '' },
    { name: 'Vaquillas Preñadas', field: 'pregnant_heifers', only: ['create', 'edit'], value: '' },
    { name: 'Vaquillas Encaste', field: 'breading_heifers', only: ['create', 'edit'], value: '' },
    { name: 'Vaquillas Pre Encaste', field: 'pre_breading_heifers', only: ['create', 'edit'], value: '' },
    { name: 'Recría', field: 'rearing', only: ['create', 'edit'], value: '' },
    { name: 'Terneros', field: 'calves', only: ['create', 'edit'], value: '' },
    { name: 'Novillos-Vaquillas Engorda', field: 'steers_heifers_fattening', only: ['create', 'edit'], value: '' },
    { name: 'Ventas', field: 'elimination_sells', only: ['create', 'edit'], value: '' },
    { name: 'Muertes', field: 'elimination_deaths', only: ['create', 'edit'], value: '' },
]



export const PerformanceProduction1 = [
    { name: 'Tasa Crecimiento Actual', field: 'grazing_growth_rate', only: ['create', 'edit'], type: 'number', value: '' },
    { name: 'Cosecha', field: 'grazing_harvest', only: ['create', 'edit'], value: '' },
    { name: 'Rotación', field: 'grazing_rotation', only: ['create', 'edit'], value: '' },
    { name: 'Leche a Estanque', field: 'production_milk_pond', only: ['create', 'edit'], value: '' },
    { name: 'Leche a Terneros', field: 'production_milk_calves', only: ['create', 'edit'], value: '' },
    { name: 'Leche a Personal', field: 'production_milk_staff', only: ['create', 'edit'], value: '' },
    { name: 'Leche a Descarte', field: 'production_milk_discard', only: ['create', 'edit'], value: '' },
    { name: 'Superficie de Rotación', field: 'surface_rotation', only: ['create', 'edit'], value: '' },
    { name: 'Hectáreas Disponibles', field: 'surface_available', only: ['create', 'edit'], value: '' },
    { name: 'Hectáreas de Rezago', field: 'surface_lag', only: ['create', 'edit'], value: '' },
    { name: 'Días en Leche', field: 'performance_days_on_milk', only: ['create', 'edit'], value: '' },
    { name: 'Condición Corporal', field: 'performance_CC', only: ['create', 'edit'], value: '' },
    { name: 'Peso Vivo', field: 'performance_living_weight', only: ['create', 'edit'], value: '' },
    { name: 'Grasa Láctea', field: 'performance_milk_fat', only: ['create', 'edit'], value: '' },
    { name: 'Proteína Láctea', field: 'performance_milk_protein', only: ['create', 'edit'], value: '' },
    { name: 'Úrea', field: 'performance_urea', only: ['create', 'edit'], value: '' },
    { name: 'Recuento Célula Somática', field: 'performance_count_somatic_cells', only: 'create', value: '' },
    { name: 'UFC', field: 'performance_UFC', only: 'create', value: '' },
    { name: 'Concentrado a Vaca por Día', field: 'efficiency_concentrate_cows_daily', only: 'create', value: '' },
]



export const SanitaryEvents1 = [
    { name: 'Meteorismo', field: 'sanitary_event_meteorism', only: ['create', 'edit'], value: '' },
    { name: 'Acidosis', field: 'sanitary_event_acidosis', only: ['create', 'edit'], value: '' },
    { name: 'Parálisis Ruminal', field: 'sanitary_ruminal_paralysis', only: ['create', 'edit'], value: '' },
    { name: 'Mastitis', field: 'sanitary_event_mastitis', only: ['create', 'edit'], value: '' },
    { name: '% Vacas > 200 RCS', field: 'sanitary_event_recount_somatic_cells_200_cows', only: ['create', 'edit'], value: '' },
    { name: '% Vacas > 1M RCS', field: 'sanitary_event_recount_somatic_cells_1M_cows', only: ['create', 'edit'], value: '' },
    { name: 'Empedradura', field: 'sanitary_event_cobblestones', only: ['create', 'edit'], value: '' },
    { name: 'Dermatitis', field: 'sanitary_event_dermatitis', only: ['create', 'edit'], value: '' },
    { name: 'Doble Suela', field: 'sanitary_event_doble_sole', only: ['create', 'edit'], value: '' },
    { name: 'Otros-Laminitis', field: 'sanitary_event_others_laminitis', only: ['create', 'edit'], value: '' },
    { name: 'Hipocalcemia', field: 'sanitary_event_hypocalcemia', only: ['create', 'edit'], value: '' },
    { name: 'Hipomagnesemia', field: 'sanitary_event_hypomagnesemia', only: ['create', 'edit'], value: '' },
    { name: 'Otros Problemas Meta.', field: 'sanitary_event_others_legs_problems', only: ['create', 'edit'], value: '' },
    { name: 'Recención de Placenta', field: 'sanitary_event_retention_placenta', only: ['create', 'edit'], value: '' },
    { name: 'Metritis', field: 'sanitary_event_metritis', only: ['create', 'edit'], value: '' },
    { name: 'Anestro > 75 DEL', field: 'sanitary_event_anestrus', only: ['create', 'edit'], value: '' },
    { name: 'Quistes Ovaricos', field: 'sanitary_event_ovarian_cyst', only: ['create', 'edit'], value: '' },
    { name: 'Tasa de Preñez', field: 'sanitary_event_pregnancy_rate', only: ['create', 'edit'], value: '' },
    { name: 'Partos Vacas', field: 'sanitary_event_cows_births', only: ['create', 'edit'], value: '' },
    { name: 'Partos Vaquillas', field: 'sanitary_event_heifers_births', only: ['create', 'edit'], value: '' },
    { name: 'Partos Asistidos', field: 'sanitary_event_assisted_births', only: ['create', 'edit'], value: '' },
    { name: 'Muertes al Parto', field: 'sanitary_event_dead_in_births', only: ['create', 'edit'], value: '' },
    { name: 'Abortos Tempranos', field: 'sanitary_event_early_abortions', only: ['create', 'edit'], value: '' },
    { name: 'Abortos Tardíos', field: 'sanitary_event_late_abortions', only: ['create', 'edit'], value: '' },
]

export const dateFields = [{ name: 'Fecha', field: 'date', type: 'date' }]

export const fields = [
    ...dateFields,
    ...cattleSummary1,
    ...PerformanceProduction1,
    ...SanitaryEvents1,
]

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const create = async (cattle_summary) => {
    const formattedDate = new Date(cattle_summary.date).toISOString()


    const dataToSend = {
        ...cattle_summary,
        date: formattedDate,
    }

    return axios
        .post(RESOURCE_LOCATION, {
            cattle_summary: dataToSend,
        })
        .then(dataGetter)
        .catch((e) => {
            throw e.response
        })
}

export const edit = async (farm_id, monthly_cattle_summary_id, cattle_summary) => {
    const formattedDate = new Date(cattle_summary.date).toISOString()

    const dataToSend = {
        ...cattle_summary,
        date: formattedDate,
    }

    return axios
        .put(`${RESOURCE_LOCATION}/${monthly_cattle_summary_id}`, {
            cattle_summary: dataToSend,
        })
        .then(dataGetter)
        .catch((e) => {
            throw e.response
        })
}


export const remove = (monthlyCattleSummary) =>
    axios.delete(`${RESOURCE_LOCATION}/${monthlyCattleSummary}`).then(dataGetter)
