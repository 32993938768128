import React from 'react'
import ResourcesModalForm from 'components/ResourcesModalForm'
import { ManualDocument } from 'utils/api/models'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import { useStyles } from 'components/ResourcesModalForm/ResourcesModalForm'
import Documents, { Row } from './Documents/Documents'

function NewManualDocument({
  open,
  onClose,
  isEmitter,
  refreshData,
  ...props
}) {
  const classes = useStyles()
  const businesses = useSelector((s) => s.businesses)
  const { businessId: id } = useSelector((s) => s.routeParams)
  const business = _.find(businesses, { id })
  const [doc, setDoc] = React.useState({})
  const [openAssignment, setOpenAssignment] = React.useState(false)

  React.useEffect(() => {
    if (doc.id) {
      setOpenAssignment(true)
    }
  }, [doc.id])

  return (
    <React.Fragment>
      <ResourcesModalForm
        model={ManualDocument}
        setSelected={() => null}
        refreshData={refreshData}
        mode='create'
        title='Documento manual'
        hideAddButton
        openModal={open}
        setOpenModal={onClose}
        additionalValues={{ business, isEmitter }}
        onRecord={setDoc}
      />
      <Modal open={openAssignment} onClose={() => setOpenAssignment(false)}>
        <Box className={classes.paper}>
          <Typography variant='h2'>Asignación centro de costo</Typography>
          <Box pt={2}>
            <Documents
              {...props}
              getData={() => ({ documents: [doc] })}
              singleDoc
            />
          </Box>
          <Box pt={2}>
            <Button
              variant='contained'
              onClick={() => {
                refreshData()
                setOpenAssignment(false)
              }}
            >
              Finalizar
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default NewManualDocument
