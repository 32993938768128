import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/`

export const all = (business_id, params, to_month) => {
  return axios
    .post(`${RESOURCE_LOCATION}${business_id}/monthly_summary/`, 
      { ...params, to_month},
    )
    .then(({ data }) => data)
  }