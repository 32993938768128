import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },

  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '6px',
  },
  inputBox: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid #cccccc',
    borderRadius: '6px',
  },
  tableCell: {
    padding: 0,
  },
  tableCellName: {
    padding: 5,
  },
  tableCellMonths: {
    padding: 5,
  },
}))

function createData(
  name,
  ene,
  feb,
  mar,
  abr,
  may,
  jun,
  jul,
  ago,
  sep,
  oct,
  nov,
  dic,
) {
  return { name, ene, feb, mar, abr, may, jun, jul, ago, sep, oct, nov, dic }
}

const data = [
  {
    name: 'conservados',
    ene: 13,
    feb: 197,
    mar: 189,
    abr: 20,
    may: 40,
    jun: 60,
    jul: 80,
    ago: 90,
    sep: 55,
    oct: 22,
    nov: 21,
    dic: 20,
  },
  {
    name: 'concertrados',
    ene: 16,
    feb: 198,
    mar: 180,
    abr: 25,
    may: 430,
    jun: 690,
    jul: 883,
    ago: 933,
    sep: 545,
    oct: 290,
    nov: 290,
    dic: 2100,
  },
]
const months = [
  'ene',
  'feb',
  'mar',
  'abr',
  'may',
  'jun',
  'jul',
  'ago',
  'sep',
  'oct',
  'nov',
  'dic',
]

const siembras = {
  0: 'Soiling de alfalfa',
  1: 'Raps',
  2: 'Triticale inv pastoreo',
}

export default function DenseTable(props) {
  const classes = useStyles()
  const rows = _(props.data.Siembra)
    .mapValues((m, id) => ({ name: siembras[id], ..._.zipObject(months, m) }))
    .values()
    .value()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>{props.name}</TableCell>
            {months.map((m, key) => (
              <TableCell
                key={`${key}-head`}
                align='center'
                className={classes.tableCellMonths}
              >
                {m}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, keyR) => (
            <TableRow key={row.name} key={`${keyR}-row`}>
              <TableCell
                className={classes.tableCellName}
                component='th'
                scope='row'
              >
                {row.name}
              </TableCell>
              {months.map((m, keyM) => (
                <TableCell
                  align='center'
                  key={`${keyM}-cell${keyR}`}
                  className={classes.tableCell}
                >
                  {row[m]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
