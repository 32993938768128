import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ResourcesAsList from 'components/ResourcesAsList'

import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  centeredButtons: {
    alignSelf: 'center',
  },
}))

export default function ResourcesAsTransferList({
  resourcesFiltered,
  setResourcesFiltered,
  ...props
}) {
  const classes = useStyles()

  const [elementsToTransfer, setElementsToTransfer] = React.useState({
    left: [],
    right: [],
  })

  const keyedResources = React.useMemo(() => _.keyBy(props.resources, 'id'), [
    props.resources,
  ])

  const handleAllRight = () => {
    setResourcesFiltered(props.resources)
  }

  const handleCheckedRight = () => {
    setResourcesFiltered((prev) => {
      return [
        ...prev,
        ..._(keyedResources).pick(elementsToTransfer.left).values().value(),
      ]
    })

    setElementsToTransfer((prev) => ({ left: [], right: prev.right }))
  }

  const handleCheckedLeft = () => {
    setResourcesFiltered((prev) => {
      const keyedPrev = _.keyBy(prev, 'id')
      return _(keyedPrev).omit(elementsToTransfer.right).values().value()
    })
    setElementsToTransfer((prev) => ({ left: prev.left, right: [] }))
  }

  const handleAllLeft = () => {
    setResourcesFiltered([])
  }

  const toggleElementSelection = (side, id, reset = false) => {
    setElementsToTransfer((prev) => {
      const elementIndex = _(prev[side]).indexOf(id)
      return {
        ...prev,
        [side]:
          elementIndex > -1
            ? reset
              ? []
              : _(prev[side]).without(id).value()
            : reset
            ? [id]
            : [...prev[side], id],
      }
    })
  }

  const ids = _.map(resourcesFiltered, 'id')

  const resourcesFilteredLeft = React.useMemo(
    () =>
      _.filter(props.resources, (e) => {
        return _.indexOf(ids, e.id) === -1
      }),
    [ids, props.resources],
  )

  const lenghtParentSelection = _.without(props.parentSelections, undefined)
    .length

  const resourcesFilteredRight =
    lenghtParentSelection === props.parentSelections.length
      ? resourcesFiltered.filter(
          (r) =>
            r[props.model.parentKey] ===
            props.parentSelections[lenghtParentSelection - 1],
        )
      : resourcesFiltered

  return (
    <Grid
      container
      spacing={2}
      justify='center'
      alignItems='flex-start'
      className={classes.root}
    >
      <Grid item xs={5}>
        Recursos disponibles
        <ResourcesAsList
          {...props}
          selected={elementsToTransfer.left}
          setSelected={(selected) => {
            toggleElementSelection('left', selected)
          }}
          resources={resourcesFilteredLeft}
        />
      </Grid>
      <Grid item xs={2} className={classes.centeredButtons}>
        <Grid container direction='column' alignItems='center'>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllRight}
            disabled={resourcesFilteredLeft.length === 0}
            aria-label='move all right'
          >
            ≫
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={elementsToTransfer.left.length === 0}
            aria-label='move selected right'
          >
            &gt;
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={elementsToTransfer.right.length === 0}
            aria-label='move selected left'
          >
            &lt;
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllLeft}
            disabled={resourcesFiltered.length === 0}
            aria-label='move all left'
          >
            ≪
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={5}>
        Recursos a utilizar
        <ResourcesAsList
          {...props}
          resources={resourcesFilteredRight}
          selected={elementsToTransfer.right}
          setSelected={(selected) => {
            props.setSelected(selected)
            toggleElementSelection('right', selected, true)
          }}
        />
      </Grid>
    </Grid>
  )
}
