import React from 'react'
import GoogleMapReact from 'google-map-react'

const Map = ({ libraries = [], children, mapType = 'hybrid', ...rest }) => {
  return (
    <GoogleMapReact
      {...rest}
      bootstrapURLKeys={{
        key: 'AIzaSyAMJ6G8DC3G8oulclyOW01GKGd8o_RBDhI',
        libraries,
      }}
      options={{ styles: [], mapTypeId: mapType, gestureHandling: 'greedy' }}
    >
      {children}
    </GoogleMapReact>
  )
}

export default Map
