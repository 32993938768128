import React from 'react'

const withReducerState = (model, reducer, initialState) => (Component) => {
  const WithReducerState = ({ key, ...props }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    const getResources = (returnFunction = false) => {
      async function getData() {
        const _data = await model.all()

        dispatch({ type: 'SET_DATA', data: _data })
      }
      if (returnFunction) {
        return getData
      } else {
        getData()
      }
    }
    React.useEffect(getResources, [key])
    return (
      <Component
        key={`c-${key}`}
        externalState={state}
        {...props}
        refreshDataTree={async () => {
          await getResources(true)()
        }}
      />
    )
  }
  return WithReducerState
}

export default withReducerState
