import React from 'react'
import { Route } from 'react-router'
import { ReactReduxContext } from 'react-redux'

const CustomRoute = ({ component: Component, computedMatch, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <ReactReduxContext.Consumer>
        {({ store }) => {
          store.dispatch({
            type: '@@router/ROUTE_CHANGED',
            params: (computedMatch && computedMatch.params) || {},
            path: (computedMatch && computedMatch.path) || {},
            url: (computedMatch && computedMatch.url) || '',
          })
          return <Component {...props} />
        }}
      </ReactReduxContext.Consumer>
    )}
  />
)
export default CustomRoute
