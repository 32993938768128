import React from 'react'
import { Box, Typography } from '@material-ui/core'
import axios from 'axios'
import ResourcesAsTable from 'components/ResourcesAsTable'
import ResourcesModalForm from 'components/ResourcesModalForm'
import { BASE_URL, dataGetter, findIn } from 'utils/api'
import { Business, Farm } from '.'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/users`
const USER_PERMISSION_BASE = `${BASE_URL}/api/farm_owners/:farmOwnerId/users`

export const customName = (r) => r.username

function NewUserBusinessPermission({ resource: user }) {
  const [businessData, setBusinessData] = React.useState([])

  const businesses = useSelector(({ businesses }) => businesses)
  const model = {
    fields: [
      {
        name: 'Empresa',
        value: ({ business_id }) =>
          _.find(businesses, { id: business_id })?.name,
        model: Business,
        only: 'list',
      },
      {
        name: 'Empresa',
        field: 'business_id',
        model: Business,
        only: 'create',
        editValue: 'business_id',
      },
      { name: 'Solo lectura', field: 'read_only', type: 'boolean' },
    ],
    create: (user_business_permission) =>
      axios
        .post(`${USER_PERMISSION_BASE}/${user.id}/business_permissions`, {
          user_business_permission,
        })
        .catch((e) => {
          throw e.response
        }),
    all: () =>
      axios
        .get(`${USER_PERMISSION_BASE}/${user.id}/business_permissions`)
        .then(dataGetter),
    remove: (id) =>
      axios
        .delete(`${USER_PERMISSION_BASE}/${user.id}/business_permissions/${id}`)
        .then(dataGetter),
  }

  async function getBusinessData() {
    const businesses = await model.all()
    setBusinessData(businesses)
  }

  React.useEffect(() => getBusinessData(), [])

  return user?.id ? (
    <Box py={1}>
      <Typography variant='h2'>Acceso a Empresas</Typography>
      <Box py={1}>
        <ResourcesAsTable
          resources={businessData}
          model={model}
          title='Permiso empresa'
          refreshData={async () => await getBusinessData()}
          setSelected={(id) => console.log(id)}
          parentSelections={[]}
          noContainer
        />
      </Box>
    </Box>
  ) : null
}
function NewUserFarmPermission({ resource: user }) {
  const [farmsData, setFarmsData] = React.useState([])

  const farms = useSelector(({ farms }) => farms)
  const model = {
    fields: [
      {
        name: 'Campo',
        value: ({ farm_id }) => _.find(farms, { id: farm_id })?.name,
        model: Farm,
        only: 'list',
      },
      { name: 'Campo', field: 'farm_id', model: Farm, only: 'create' },
      { name: 'Solo lectura', field: 'read_only', type: 'boolean' },
    ],
    create: (user_farm_permission) =>
      axios
        .post(`${USER_PERMISSION_BASE}/${user.id}/farm_permissions`, {
          user_farm_permission,
        })
        .catch((e) => {
          throw e.response
        }),
    all: () =>
      axios
        .get(`${USER_PERMISSION_BASE}/${user.id}/farm_permissions`)
        .then(dataGetter),
    remove: (id) =>
      axios
        .delete(`${USER_PERMISSION_BASE}/${user.id}/farm_permissions/${id}`)
        .then(dataGetter),
  }

  async function getFarmData() {
    const farms = await model.all()
    setFarmsData(farms)
  }

  React.useEffect(() => getFarmData(), [])

  return user?.id ? (
    <Box py={1}>
      <Typography variant='h2'>Acceso a Campos</Typography>
      <Box py={1}>
        <ResourcesAsTable
          resources={farmsData}
          model={model}
          title='Permiso campo'
          refreshData={async () => await getFarmData()}
          setSelected={(id) => console.log(id)}
          parentSelections={[]}
          noContainer
        />
      </Box>
    </Box>
  ) : null
}

export const fields = [
  { name: 'id', field: 'id', only: 'list' },
  { name: 'Nombre de Usuario', field: 'username' },
  { name: 'Contraseña', field: 'password', only: 'create', type: 'password' },

  { name: 'Administrador', field: 'is_admin', type: 'boolean' },
  {
    name: 'Campos',
    field: 'farm_permission',
    only: 'create',
    Component: NewUserFarmPermission,
  },
  {
    name: 'Empresas',
    field: 'business_permission',
    only: 'create',
    Component: NewUserBusinessPermission,
  },
]

export const create = (user) =>
  axios
    .post(RESOURCE_LOCATION, {
      user,
    })
    .then(dataGetter)

export const edit = (user_id, user) =>
  axios
    .put(`${RESOURCE_LOCATION}/${user_id}`, {
      user,
    })
    .then(dataGetter)

export const remove = async (user_id) =>
  axios.delete(`${RESOURCE_LOCATION}/${user_id}`).then(dataGetter)

export const all = async () => {
  const users = await axios.get(RESOURCE_LOCATION).then(dataGetter)
  return users.filter((user) => user.username !== '__admin__')
}

export const findOrCreate = async (user) => {
  const _user = await findIn(await all(), user.username, 'username')
  return _user ? _user : await create(user)
}

export const current = () =>
  axios.get(`${BASE_URL}/api/account/`).then(dataGetter)
