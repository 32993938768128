import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import _ from 'lodash'
import clsx from 'clsx'

const formatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
})

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  category: {
    borderRight: '1px solid black',
  },
  superCategory: {
    fontWeight: '500 !important',
  },
  childRow: {
    paddingLeft: '26px',
  },
})

const SegTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    borderBottom: '2px solid black',
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
  },
}))(TableCell)

const CatTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    borderBottom: '2px solid black',
  },
  body: {
    fontSize: 14,
    fontWeight: 300,
  },
}))(TableCell)

export default function MonthlyCategoriesTable(props) {
  const { segment, isSuperCategory } = props
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  return (
    <React.Fragment>
      <TableRow hover className={classes.root}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon color='primary' />
            ) : (
              <KeyboardArrowRightIcon color='primary' />
            )}
          </IconButton>
        </TableCell>
        <SegTableCell className={classes.category} component='th' scope='row'>
          {segment.name}
        </SegTableCell>

        {segment.totals.map((total) => (
          <SegTableCell align='right'>{formatter.format(total)}</SegTableCell>
        ))}
        <SegTableCell
          align='right'
          className={clsx(isSuperCategory && classes.superCategory)}
        >
          {formatter.format(segment.total)}
        </SegTableCell>
      </TableRow>
      <React.Fragment />

      {segment.result_name && (
        <TableRow hover className={classes.root}>
          <TableCell />
          <SegTableCell className={classes.category} component='th' scope='row'>
            {_.upperFirst(segment.result_name)}
          </SegTableCell>
          <SegTableCell align='right'>
            {formatter.format(segment.margin)}
          </SegTableCell>
          <SegTableCell align='right'></SegTableCell>
          <SegTableCell align='right'></SegTableCell>
          <SegTableCell align='right'></SegTableCell>
          <SegTableCell align='right'></SegTableCell>
        </TableRow>
      )}
      {open &&
        segment.sub_categories.map((subcategory) => (
          <TableRow hover>
            <TableCell />
            <CatTableCell
              className={clsx(classes.category, classes.childRow)}
              component='th'
              scope='row'
            >
              {/* span>&nbsp;&nbsp;&nbsp;</span> */}
              {subcategory.name}
            </CatTableCell>
            {subcategory.totals.map((total) => (
              <CatTableCell
                align='right'
                className={clsx(isSuperCategory && classes.superCategory)}
              >
                {formatter.format(total)}
              </CatTableCell>
            ))}
            <CatTableCell
              align='right'
              className={clsx(isSuperCategory && classes.superCategory)}
            >
              {formatter.format(subcategory.total)}
            </CatTableCell>
          </TableRow>
        ))}
    </React.Fragment>
  )
}
