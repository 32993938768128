import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Toolbar,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Box,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import clsx from 'clsx'
import { ItemDetail } from 'utils/api/models'
import {
  filterOptions,
  findCategoryOption,
  renderCategoryLabel,
} from 'components/CostCenterAssignmentModal/CostCenterAssignmentModal'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Alert } from '@material-ui/lab'
import { useSnackbar } from 'notistack'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.primary.main,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}))

const EnhancedTableToolbar = ({
  numSelected,
  refreshData,
  selectedIds,
  ...props
}) => {
  const classes = useToolbarStyles()
  const [open, setOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = () => {
    setOpen(false)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const [category, setCategory] = React.useState({
    category_id: null,
    sub_category_id: null,
  })

  const putItemDetails = () => {
    async function putData() {
      try {
        await ItemDetail.putMany(props.document.id, selectedIds, category)
        enqueueSnackbar('Se asignaron centros de costo', { variant: 'success' })
        await refreshData()
        setOpen(false)
      } catch (e) {
        enqueueSnackbar(
          'No se pudo hacer la asignación. Verifique los datos e intente nuevamente.',
          { variant: 'error' },
        )
      }
    }
    putData()
  }

  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color='inherit'
            variant='subtitle1'
            component='div'
          >
            {numSelected} items seleccionados
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant='h6'
            id='tableTitle'
            component='div'
          >
            Detalle
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title='Asignar centro de costo'>
            <IconButton aria-label='add' onClick={handleClickOpen}>
              <AddIcon color='secondary' />
            </IconButton>
          </Tooltip>
        ) : null}
      </Toolbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          Asignación de centro de costo
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione el centro de costo a asignar a los ítems seleccionados.
          </DialogContentText>
          <Box py={2}>
            <Autocomplete
              id='category'
              options={props.categories}
              renderOption={(option) => option.sub_category}
              onChange={(_event, value) =>
                setCategory({
                  category_id: value?.category_id,
                  sub_category_id: value?.sub_category_id,
                })
              }
              groupBy={(option) =>
                `${option.parent ? `${option.parent} - ` : ''}${
                  option.category
                }`
              }
              getOptionLabel={(option) => {
                if (option.category) {
                  return renderCategoryLabel(option)
                } else {
                  const category = findCategoryOption(
                    props.categories,
                    option.category_id,
                    option.sub_category_id,
                  )
                  return category ? renderCategoryLabel(category) : ''
                }
              }}
              filterOptions={filterOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Centro de Costo'
                  size='small'
                  style={{ width: '100%' }}
                />
              )}
            />
          </Box>
          <DialogContentText>
            <Alert severity='warning'>
              Esta acción sobreescribe cualquier asignación existente.
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>
          <Button onClick={putItemDetails} color='primary'>
            Asignar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default EnhancedTableToolbar
