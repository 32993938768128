import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/service_providers`

export const fields = [
  { name: 'Razón social', field: 'name' },
  { name: 'RUT', field: 'business_rut' },
]

export const resourceKey = 'service_provider'
export const collectionKey = 'service_providers'

export const create = async (service_provider) =>
  axios
    .post(RESOURCE_LOCATION, {
      service_provider,
    })
    .then(dataGetter)
    .catch((e) => {
      throw e.response
    })

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const edit = (service_provider_id, service_provider) =>
  axios
    .put(`${RESOURCE_LOCATION}/${service_provider_id}`, {
      service_provider,
    })
    .then(dataGetter)

export const remove = (service_provider_id) =>
  axios.delete(`${RESOURCE_LOCATION}/${service_provider_id}`).then(dataGetter)
