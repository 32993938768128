import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRange } from 'react-date-range'
import { format, endOfDay, startOfDay, addDays } from 'date-fns'
import es from 'date-fns/locale/es'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Typography, Box } from '@material-ui/core/'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import Snackbar from '@material-ui/core/Snackbar'
import { useSelector } from 'react-redux'
import { FarmOwner, Farm, ProductiveUnits } from 'utils/api/models'
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    border: '1px solid rgba(180, 180, 180, 0.2)',
  },
  heading: {
    //marginLeft: '25px',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  formControl: {
    //margin: theme.spacing.unit,
    marginTop: '20px',
    minWidth: 400,
    maxWidth: 400,
  },
  textInput: {
    width: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  filterContainer: {
    display: 'flex',
    gap: '60px',
    alignItems: 'flex-start',
    //border: '1px solid red',
  },
  filterContainerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    //height: 'max-content',
    gap: '30px',
    //border: '1px solid red',
  },
  managementSelector: {
    margin: '0px',
  },
}))

export default function FilterMilkByDAy({ type_date, getData }) {
  const [owners, setOwners] = useState()
  const [farmsData, setFarmsData] = useState()

  const [selectedFarmOwners, setSelectedFarmOwners] = useState([])
  const [selectedFarms, setSelectedFarms] = useState([])
  const [selectedPU, setSelectedPU] = useState([])

  const states = useSelector((state) => state)
  //console.log('analytics state : ', states.farmOwners)

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(endOfDay(new Date()), 7),
      key: 'selection',
    },
  ])

  const stateHandleChange = (value) => {
    setState([value.selection])
    //console.log(state)
  }

  function getOwnersData() {
    //const states = useSelector((state) => state)
    const _providers = states.farmOwners
    setOwners(_providers)
  }

  async function getFarmData() {
    let callFarms = []
    for (var i = 0; i < selectedFarmOwners.length; i++) {
      const f = await Farm.byId(selectedFarmOwners[i])
      callFarms.push(f)
    }
    let flattenFarms = _.flatten(callFarms)
    //console.log('callFarms: ', flattenFarms)
    setFarmsData(flattenFarms)
  }

  useEffect(() => getOwnersData(), [states.farmOwners])

  useEffect(() => {
    getFarmData()
  }, [selectedFarmOwners])

  const [snackOwners, setSnackOwners] = useState(false)
  const [snackFarms, setSnackFarms] = useState(false)

  const handleChangeselectedFarmOwners = (value) => {
    if (value.length <= 5) {
      setSelectedFarmOwners(value)
    } else {
      setSnackOwners(true)
    }
  }

  const handleChangeselectedFarms = (value) => {
    if (value.length <= 5) {
      setSelectedFarms(value)
    } else {
      setSnackFarms(true)
    }
  }

  useEffect(() => {
    setSelectedFarms([])
  }, [selectedFarmOwners])

  const [productiveUnits, setProductiveUnits] = useState([])
  const [snackPU, setSnackPU] = useState(false)

  async function getproductiveUnits(farms) {
    const data = await ProductiveUnits.byFarmIds(farms)
    setProductiveUnits(data['productive_units'])
    //console.log('pd: ', data)
  }

  useLayoutEffect(() => {
    setProductiveUnits([])
    if (selectedFarms.length >= 1) {
      getproductiveUnits(selectedFarms)
    }
  }, [selectedFarms, state])

  const handleChangeselectedProductiveUnits = (value, ref) => {
    if (isLoading === false) {
      if (value.length <= 5) {
        setSelectedPU(value)
      } else {
        setSnackPU(true)
      }
    }
  }

  const [isLoading, setIsLoading] = useState(false)

  const openPicker = (ref) => {
    ref.current.show()
  }

  const rangeRef = useRef(null)
  const fixedRef = useRef(null)

  const [range, setRange] = useState({
    from: { month: 6, year: 2023 }, // Default end month: December 2023
    to: { month: 12, year: 2023 }, // Default start month: June 2023
  })

  const [SelectedFixedMonth, setSelectedFixedMonth] = useState({
    month: 1,
    year: 2024,
  })

  const handleRangeChange = (year, month, index) => {
    if (index === 1) {
      setRange({ from: range.from, to: { year: year, month: month } })
    } else if (index === 0) {
      setRange({ from: { year: year, month: month }, to: range.to })
    }
  }

  const handleMonthChange = (year, month) => {
    setSelectedFixedMonth({ year, month })
  }

  const month = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ]

  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <div className={classes.filterContainer}>
        {type_date === 'day' && (
          <DateRange
            onChange={(item) => stateHandleChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            rangeColors={['#5FAA00']}
            fixedHeight={true}
            direction='horizontal'
            locale={es}
          />
        )}
        {type_date === 'months' && (
          <div>
            <TextField
              id='Dates'
              label='Fechas'
              fullWidth
              className={classes.textInput}
              value={`inicio: ${month[range.from.month - 1]} - ${
                range.from.year
              } / final: ${month[range.to.month - 1]} - ${range.to.year}`}
              //onChange={this.handleChange('name')}
              onClick={() => openPicker(rangeRef)}
              margin='normal'
              variant='outlined'
            />
            <Picker
              ref={rangeRef}
              value={range}
              onChange={(year, month, index) =>
                handleRangeChange(year, month, index)
              }
              lang={{ months: month }}
            />
          </div>
        )}
        {type_date === 'fixed_months' && (
          <div>
            <TextField
              id='fixed_month'
              label='Mes seleccionado'
              fullWidth
              className={classes.textInput}
              value={`${month[SelectedFixedMonth.month - 1]} -  ${
                SelectedFixedMonth.year
              }`}
              onClick={() => openPicker(fixedRef)}
              margin='normal'
              variant='outlined'
            />
            <Picker
              ref={fixedRef}
              value={SelectedFixedMonth}
              lang={{ months: month }}
              onChange={handleMonthChange}
            />
          </div>
        )}
        <div className={classes.filterContainerColumn}>
          <MultipleSelector
            value={selectedFarmOwners}
            handleChange={handleChangeselectedFarmOwners}
            snackOpen={snackOwners}
            setSnackOpen={setSnackOwners}
            menuValue={owners}
            snakMessage='Se deben seleccionar como máximo 5 Propietarios'
            label='Propietarios'
          />
          <MultipleSelector
            value={selectedFarms}
            handleChange={handleChangeselectedFarms}
            snackOpen={snackFarms}
            setSnackOpen={setSnackFarms}
            menuValue={farmsData}
            disabled={selectedFarmOwners.length === 0 && true}
            snakMessage='Se deben seleccionar como máximo 5 Campos '
            label='Campos'
          />
          <MultipleSelector
            value={selectedPU}
            handleChange={handleChangeselectedProductiveUnits}
            snackOpen={snackPU}
            setSnackOpen={setSnackPU}
            menuValue={productiveUnits}
            disabled={selectedFarms.length === 0 && true}
            snakMessage='Se deben seleccionar como máximo 5 Unidades productivas '
            label='Unidades productivas'
            product_unit={true}
          />
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            disabled={selectedFarms.length === 0}
            onClick={() =>
              type_date === 'day'
                ? getData(state[0].startDate, state[0].endDate, selectedPU)
                : type_date === 'months'
                ? getData(range.from, range.to, selectedPU)
                : type_date === 'fixed_months'
                ? getData(SelectedFixedMonth, selectedPU)
                : null
            }
          >
            {selectedFarms.length === 0
              ? 'Seleccionar Campos'
              : 'Aplicar Filtro'}
          </Button>
        </div>
      </div>
    </Paper>
  )
}

function MultipleSelector({
  value,
  handleChange,
  snackOpen,
  setSnackOpen,
  menuValue,
  snakMessage,
  label,
  disabled = false,
  product_unit = false,
}) {
  const [close, setClose] = useState(false)

  const classes = useStyles()
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor='select-multiple-Farms-Owners'>{label}</InputLabel>
        <Select
          disabled={disabled}
          multiple
          //onClick={()=> setClose(!close)}
          value={value}
          //open={close}
          onChange={(e) => handleChange(e.target.value)}
          input={<Input id='select-multiple-chip' />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    product_unit === false
                      ? _.find(menuValue, { id: value })?.name
                      : `${_.find(menuValue, { id: value })?.name} - ${
                          _.find(menuValue, { id: value })?.farm_name
                        }`
                  }
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {menuValue?.map((value) => (
            <MenuItem key={value.name} value={value.id}>
              {product_unit === false
                ? value.name
                : `${value.name} - ${value.farm_name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={(e, r) => r === 'timeout' && setSnackOpen(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id='message-id'>{snakMessage}</span>}
      />
    </div>
  )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
