import React from 'react'
import { Paper, TextField, Button, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MonthPicker from './ResultState/MonthPicker'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  rootForm: {
    display: 'flex',
    flexDirection: 'row',
    //textAlign: 'center',
    justifyContent: 'center',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    padding: '15px',
  },
  buttonConfirm: {
    margin: '10px',
  },
  caption: {
    textAlign: 'left',
    paddingLeft: '10px',
  },
}))

const StateResultParams = (props) => {
  const { form, handleSubmit } = props
  const classes = useStyles()

  const d = moment({ year: props.toMonth.year, month: props.toMonth.month - 1, day: 1}).format('MMMM - YYYY')

  console.log(d)

  return (
    <Paper elevation={3}>
      <div className={classes.rootForm}>
        <Box marginTop='8px'> 
          <MonthPicker from_month={form.values.from_month} from_onChange={form.handleChange} />
          <Typography variant='caption'>Seleccionar fecha desde*</Typography>
        </Box>
        <Box display='flex' flexDirection='column'>
          <TextField
            disabled
            label='Hasta'
            variant='outlined'
            size='small'
            value={d}
          />
          <Typography variant='caption' className={classes.caption}>Un año móvil</Typography>
        </Box>
        <TextField
          label='Litros'
          variant='outlined'
          onChange={form.handleChange}
          name='liters'
          size='small'
          value={form.values.liters}
        />
        <TextField
          label='Precio x Litro'
          variant='outlined'
          onChange={form.handleChange}
          name='liter_price'
          size='small'
          value={form.values.liter_price}
        />
        <TextField
          label='N° Vacas'
          variant='outlined'
          onChange={form.handleChange}
          name='cows'
          size='small'
          value={form.values.cows}
        />
        <TextField
          label='N° Hectareas'
          variant='outlined'
          onChange={form.handleChange}
          name='ha'
          size='small'
          value={form.values.ha}
        />
        <Button
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          className={classes.buttonConfirm}
        >
          Confirmar
        </Button>

      </div>
    </Paper>
  )
}

export default StateResultParams
