import React from 'react'
import {
  Typography,
  Box,
  makeStyles,
  Paper,
  Grid,
  Tab,
  Tabs,
  Fab,
} from '@material-ui/core'
import { map } from 'lodash-es'
import { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import InputsByYear from './InputsByYear'
import CowFoodGraph from './CowFoodGraph'
import CowFoodTable from './CowFoodTable'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { OptimizerRun } from 'utils/api/models'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import withOwnerFarmParam from 'hoc/withOwnerFarmParam'

const useStyles = makeStyles((theme) => ({
  background: {
    display: 'flex',
    flexGrow: '3',
    flexDirection: 'column',
  },
  colorText: {
    color: theme.palette.primary.main,
  },
  TabPanelBackground: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid #cccccc',
    borderRadius: '6px',
  },
  InputsByDayPaper: {
    padding: '1px',
  },
  TextField: {
    color: 'black',
  },
  fab: {
    position: 'fixed',
    bottom: '70px',
    right: '10px',
  },
}))

function ForageBalance(props) {
  const classes = useStyles()
  const tabsInputs = ['Datos', 'restriciones']
  const tabsOutputs = ['Gráficos', 'Tablas']
  const [inputTab, setInputTab] = React.useState(0)
  const [outputTab, setOutputTab] = React.useState(0)
  const [optimizerRun, setOptimizerRun] = React.useState({})
  const [data, setData] = React.useState({})
  const { enqueueSnackbar } = useSnackbar()

  const [irrigationMeadow, setIrrigationMeadow] = useState(null)

  const runModel = () => {
    async function postData() {
      try {
        const result = await OptimizerRun.create({ Terreno: form.values })
        setOptimizerRun(result)
        enqueueSnackbar('Optimización iniciada', {
          variant: 'info',
        })
      } catch (e) {
        enqueueSnackbar('No se pudo iniciar el optimizador', {
          variant: 'error',
        })
        console.log(e)
      }
    }
    postData()
  }

  async function refreshOptimizer() {
    try {
      if (optimizerRun.id) {
        const {
          result: { data: _data },
        } = await OptimizerRun.get(optimizerRun.id)
        if (_data.Animal) {
          setData(_data)
          enqueueSnackbar('Optimización completada', {
            variant: 'success',
          })
        } else {
          setTimeout(() => refreshOptimizer(), 2000)
        }
      }
    } catch {
      setTimeout(() => refreshOptimizer(), 2000)
    }
  }

  React.useEffect(() => {
    setTimeout(() => refreshOptimizer(), 2000)
  }, [optimizerRun.id])

  const form = useFormik({
    onSubmit: () => null,
    initialValues: {
      'Pradera riego': 60,
      'Pradera secano': 70,
      'Pradera deteriorada': 200,
      'Pradera rotación': 90,
    },
  })

  return (
    <React.Fragment>
      <Fab
        className={classes.fab}
        color='primary'
        aria-label='run'
        onClick={runModel}
      >
        <PlayArrowIcon />
      </Fab>
      <Box display='flex'>
        <Paper elevation={1} className={classes.background}>
          <Box margin='15px'>
            <Box marginBottom='15px'>
              <Typography variant='h3'>
                Balance <span className={classes.colorText}>forrajero</span>
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Nav
                  tabs={tabsInputs}
                  setTab={setInputTab}
                  activeTab={inputTab}
                ></Nav>
                <Box className={classes.TabPanelBackground} mt='10px'>
                  <TabPanel index={0} value={inputTab}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <InputsByDay name='Distribución de há.'>
                          <InputData
                            name='Pradera riego'
                            onChange={form.handleChange}
                            formValues={form.values}
                            type='number'
                          />
                          <InputData
                            name='Pradera secano'
                            onChange={form.handleChange}
                            formValues={form.values}
                            type='number'
                          />
                          <InputData
                            name='Pradera deteriorada'
                            onChange={form.handleChange}
                            formValues={form.values}
                            type='number'
                          />
                          <InputData
                            name='Pradera rotación'
                            onChange={form.handleChange}
                            formValues={form.values}
                            type='number'
                          />
                        </InputsByDay>
                      </Grid>
                      <Grid item xs={6}>
                        <InputsByDay name='Variable animal'>
                          <InputData name='Peso animal' value={500} />
                        </InputsByDay>
                      </Grid>
                      <Grid item xs={12}>
                        <InputsByYear name='Datos por mes'></InputsByYear>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel index={1} value={inputTab}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <InputsByDay name='Restricciones Globales'>
                          <InputData name='azucar' />
                        </InputsByDay>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Nav
                  tabs={tabsOutputs}
                  setTab={setOutputTab}
                  activeTab={outputTab}
                ></Nav>
                <Box className={classes.TabPanelBackground} mt='10px'>
                  <TabPanel index={0} value={outputTab}>
                    <Box height='500px'>
                      <CowFoodGraph data={data} />
                    </Box>
                  </TabPanel>

                  <TabPanel index={1} value={outputTab}>
                    <CowFoodTable data={data} />
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default ForageBalance

function indexProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  const classes = useStyles()

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  )
}

const Nav = ({ tabs, setTab, activeTab }) => {
  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <Tabs
      value={activeTab}
      indicatorColor='primary'
      textColor='primary'
      onChange={handleChange}
      aria-label='disabled tabs example'
    >
      {map(tabs, (name, index) => (
        <Tab label={name} {...indexProps(index)} />
      ))}
    </Tabs>
  )
}

function InputsByDay(props) {
  const classes = useStyles()
  return (
    <Box>
      <Paper className={classes.InputsByDayPaper}>
        <Box p={1}>
          <Typography>{props.name}</Typography>
        </Box>
        <Box m={1} className={classes.TabPanelBackground}>
          {props.children}
        </Box>
      </Paper>
    </Box>
  )
}

function InputData(props) {
  const classes = useStyles()
  return (
    <Box m={1}>
      <Paper>
        <Box display='flex' flexDirection='row'>
          <Box p={1}>
            <TextField
              className={classes.TextField}
              label={props.name}
              value={props.formValues?.[props.name]}
              {...props}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
