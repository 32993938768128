import React from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import LayoutElement from 'components/LayoutElement'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '82vh',
  },
  column: {
    display: 'flex',
  },
  rowContainer: {
    display: 'flex',
    flexGrow: 1,
  },
}))

const getLayoutViews = (layout) => {
  const flatLayout = _(layout)
    .flatMap((l) => l.elements || [l])
    .value()
  const layoutById = _(flatLayout).keyBy('id').value()
  const layoutByParent = _(flatLayout).keyBy('parent_id').value()
  const filterListKeys = _(flatLayout)
    .filter((l) => l.listMode === 'transfer-list')
    .map((l) => l.model.collectionKey)
    .value()
  return { layoutById, layoutByParent, filterListKeys }
}

const NestedResourcesLayout = ({ layout, ...props }) => {
  const { layoutById, layoutByParent, filterListKeys } = React.useMemo(
    () => getLayoutViews(layout),
    [layout],
  )

  const [filteredResources, setFilteredResources] = React.useState(
    _([...filterListKeys])
      .zipObject()
      .mapValues((_v, k) => _(props.externalState[k]).values().value())
      .value(),
  )

  React.useEffect(() => {
    setFilteredResources((prev) =>
      _(prev)
        .mapValues((_v, k) => _(props.externalState[k]).values().value())
        .value(),
    )
  }, [props.externalState])

  const classes = useStyles()
  const [selected, setSelected] = React.useState([])
  const resetChildrenSelections = (id) => {
    const childrenKeys = []
    let childrenId = id
    while (childrenId) {
      const child = layoutByParent[childrenId]
      if (child) {
        childrenKeys.push(child.id)
      }
      childrenId = child ? child.id : undefined
    }
    const _selected = { ...selected }
    childrenKeys.map((k) => delete _selected[k])
    return _selected
  }

  const setElementSelected = (id) => (newSelection) => {
    const _selected = resetChildrenSelections(id)
    setSelected({ ..._selected, [id]: newSelection })
  }

  const getParentSelections = (_parentId) => {
    const parentSelections = []
    let parentId = _parentId
    let enoughSelections = true
    while (parentId) {
      const parentSelection = selected[parentId]
      if (parentSelection === undefined) {
        enoughSelections = false
      }
      parentSelections.unshift(parentSelection)
      parentId = layoutById[parentId]?.parent_id
    }
    return { parentSelections, enoughSelections }
  }

  const hasAllSelections =
    _(selected).values().filter().value().length === layout.length

  React.useEffect(() => {
    if (hasAllSelections && props.onSelectionComplete) {
      const keyedFilteredResources = _(filteredResources)
        .mapValues((r) => _.keyBy(r, 'id'))
        .value()
      props.onSelectionComplete(
        selected,
        _({ ...props.externalState })
          .mergeWith(keyedFilteredResources, (_pv, kv) => kv)
          .value(),
      )
    }
  }, [hasAllSelections, selected, filteredResources])

  return (
    <Grid
      container
      spacing={1}
      className={props.elementHeights != 'full' && classes.container}
    >
      {layout.map((l) => {
        if (l.elements) {
          /* Aca considera los que tienen elements */
          return (
            <Grid
              item
              xs={l.size}
              key={`column-${l.id}`}
              className={classes.column}
            >
              <Grid
                container
                direction='column'
                className={classes.rowContainer}
              >
                {l.elements.map((row) => {
                  const {
                    parentSelections,
                    enoughSelections,
                  } = getParentSelections(row.parent_id)

                  return (
                    <LayoutElement
                      key={row.id}
                      layout={row}
                      setSelected={setElementSelected(row.id)}
                      selected={selected[row.id]}
                      parentSelections={parentSelections}
                      enoughSelections={enoughSelections}
                      refreshDataTree={props.refreshDataTree}
                      {...props}
                    />
                  )
                })}
              </Grid>
            </Grid>
          )
        } else {
          const { parentSelections, enoughSelections } = getParentSelections(
            l.parent_id,
          )
          const resourcesFiltered =
            filteredResources[l.model.collectionKey] || []

          const getNewValue = (prev, resources) => {
            const prevSlice = prev[l.model.collectionKey]
            const newSlice = _.isFunction(resources)
              ? resources(prevSlice || [])
              : resources

            const toRemove = _(prevSlice).differenceBy(newSlice, 'id').value()
            const toAdd = _(newSlice).differenceBy(prevSlice, 'id').value()

            const newValue = _(prev[l.model.collectionKey])
              .concat(toAdd)
              .differenceBy(toRemove, 'id')
              .value()

            return newValue
          }
          const setResourcesFiltered = (resources) => {
            setFilteredResources((prev) => {
              const newValue = getNewValue(prev, resources)
              return {
                ...prev,
                [l.model.collectionKey]: newValue,
              }
            })
          }

          return (
            <LayoutElement
              key={l.id}
              layout={l}
              setSelected={setElementSelected(l.id)}
              selected={selected[l.id]}
              parentSelections={parentSelections}
              enoughSelections={enoughSelections}
              refreshDataTree={props.refreshDataTree}
              resourcesFiltered={resourcesFiltered}
              setResourcesFiltered={setResourcesFiltered}
              {...props}
            />
          )
        }
      })}
    </Grid>
  )
}

export default NestedResourcesLayout
