import { ResponsiveBar } from '@nivo/bar'
import { BasicTooltip } from '@nivo/tooltip';
//import dayjs from 'dayjs';
import React,{useEffect, useState} from 'react' 
import _ from 'lodash'
import { prop, props } from 'lodash/fp';



export default function Graph({
        data = false, 
        graphType = 'sum', 
        bottomLegend = 'sin definir', 
        leftLegend = 'sin definir',
        height = '500px',
        large = false,
    }) 
    {

    const [dataByIsumoToNivoState, setDataByInsumoToNivoState] = useState([])
    const [shortData, setShortData] = useState([])
    //const [dataByIsumoFullToNivoState, setDataByInsumoFullToNivoState] = useState([])
    const [farmsNamesState, setFarmsNamesState] = useState([])

    const dataByInsumo = _.groupBy(data?.data, (x) => x.insumo)

    useEffect(()=>{
        let dataByInsumoToNivo = [] 
        let getFarmsNames = []
        Object.entries(dataByInsumo).forEach(([k,v])=>{
            //console.log(k, v)
            let insumo = {}
            insumo['insumo'] = k
            for (var j = 0; j < v.length ; j++ ) {
                insumo[v[j].campo] = Math.round(Number(v[j].dosis))
                getFarmsNames.push(v[j].campo)
            }
            //console.log('insumo objeto: ', insumo)
            dataByInsumoToNivo.push(insumo)
        })
        setDataByInsumoToNivoState(dataByInsumoToNivo)
        setFarmsNamesState(Array.from(new Set(getFarmsNames)))
        //console.log('data: ',data)
        //console.log('groupBydata ',dataByInsumo)
        //console.log('groupBydatNivo ',dataByInsumoToNivo)
        //console.log('keys: ', FarmsNames)
    }, [data])
    
    
    /*useEffect(()=>{
        let dataByInsumoToNivoFull = [] 
        Object.entries(dataByInsumo).forEach(([k,v])=>{
        //console.log(k, v)
        let insumo = {'dosis': 0}
        insumo['insumo'] = k
        for (var j = 0; j < v.length ; j++ ) {
            insumo['dosis'] += Number(v[j].dosis)
            //getFarmsNames.push(v[j].campo)
        }
        //console.log('insumo objeto: ', insumo)
        dataByInsumoToNivoFull.push(insumo)
        setDataByInsumoFullToNivoState(dataByInsumoToNivoFull)
    })
    }, [data])*/


    
    useEffect(()=>{
        let shortDataByIsumoToNivoState = []
        let shortDataSpare = []
        //setDataByInsumoToNivoState(dataByIsumoFullToNivoState.slice(0,6))
        for (var i = 0; i < dataByIsumoToNivoState.length ; i++ ) {
            if (i < 10) {
                shortDataByIsumoToNivoState.push(dataByIsumoToNivoState[i])
            } else {
                shortDataSpare.push(dataByIsumoToNivoState[i])
            }
        }
        let others = {insumo: 'Otros'}
        shortDataSpare.forEach(element => {
            Object.entries(element).forEach(([k,v])=>{ 
                if (k !== 'insumo') {
                    others[k] === undefined ? others[k] = 0 : others[k] += Number(v)
                }
            })
        });
        dataByIsumoToNivoState.length > 10 && shortDataByIsumoToNivoState.push(others) 
        //console.log('shortDataByIsumoToNivoState: ', shortDataByIsumoToNivoState)
        setShortData(shortDataByIsumoToNivoState)
    }, [dataByIsumoToNivoState])

    //console.log('spare data: ', shortData)
    
    //console.log(shortDataByIsumoToNivoState)

    let ResponsiveBarProps = {
        data: large ? dataByIsumoToNivoState : shortData,
        keys:  farmsNamesState,
        indexBy: 'insumo',
        groupMode: graphType === 'grouped' ? 'grouped' : 'stacked', 
        margin: { top: 50, right: 130, bottom: 250, left: 125 },
        padding: 0.2,
        valueScale:{ type: 'linear' },
        indexScale: { type: 'band', round: true },
        colors: graphType === 'sum' ? ['#5FAA00'] : ['#5FAA00', '#9CC26A', '#F9A825', '#FF7A00'],   
        axisTop: null,
        axisRight: null,
        axisBottom:{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: bottomLegend,
            legendPosition: 'middle',
            legendOffset: 150,
        },
        axisLeft:{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: leftLegend,
            legendPosition: 'middle',
            legendOffset: -100,
            },
        enableLabel:false,
        labelSkipWidth:12,
        labelSkipHeight:12,
        labelTextColor:{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6,
                ],
            ],
        },
        animate:false,
        legends:[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1,
                        },
                    },
                ],
            },
        ],
        role: 'application',
        ariaLabel: 'Nivo bar chart demo',
        //barAriaLabel: e => e.id+': '+e.formattedValue+' in country: '+e.indexValue,
        layers: [
            'grid',
            'axes',
            'markers',
            'bars',
            'legends',
            'annotations',
            ],
    }

    if ( graphType === 'sum' ) {
        ResponsiveBarProps.tooltip = BarTooltip
    }

    return(
        <div style={{width: '100%', height: height}}>        
            {data && <ResponsiveBar
            {...ResponsiveBarProps}
            
        />}
    </div>
    )

}



const BarTooltip = (props) => {
    //const dayStr = dayjs(props.data.month).format('ll');
    let dataToArray = Object.entries(props.data)
    
    var removeIsumo = _.remove(dataToArray, function([k,v]) {
        return k === 'insumo'
      }); 

    //console.log('remove isumo: ',dataToArray)

    let totalData =_.reduce(dataToArray, function(sum, v){
        return sum + Number(v[1])
    },0)
    //console.log('reduce: ',totalData)

    return (
        <BasicTooltip
            //id={dayStr}
            value={`${props.indexValue}: ${totalData}`}
            color={props.color}
            enableChip
        />
    );
};

/*const BarTotalsLayer = (props) => {
    const labelOffset = 10;
    const labelFontSize = 12;
    if (props.bars.length === 0) return null;
    // compute totals for each index/bar
    const totals = {};
    const bandwidth = props.bars[0].width;
    props.bars.forEach((bar) => {
      const indexValue = bar.data.indexValue;
      if (!(indexValue in totals)) {
        totals[indexValue] = 0;
      }
      if (!bar.data.hidden) {
        totals[indexValue] += bar.data.value;
      }
    });
    // place text elements above the bars
    const labels = Object.keys(totals).map((indexValue) => {
      const x = props.xScale(indexValue) + bandwidth ;
      const y = props.yScale(totals[indexValue]) - labelOffset;
      return (
        <text
          key={'total.' + indexValue}
          x={x}
          y={y}
          textAnchor={'middle'}
          fontWeight={'bold'}
          fontSize={labelFontSize}
        >
          {totals[indexValue]}
        </text>
      );
    });
    return <>{labels}</>;
  };*/