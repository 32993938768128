import React from 'react'
import { makeStyles, Typography, Box } from '@material-ui/core/'

const useStyles = makeStyles((theme) => ({
    kpiShape: {
        height: 'auto',
        minHeight: '170px',
        backgroundColor: 'white',
        minWindth: '300px',
    },
    Title: {
        fontFamily: 'Roboto',
        display: 'flex',
        fontSize: '20px',
        lineHeight: '19px',
        paddingLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '5px',
        color: '#305600',
    },
    subTitle: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '18,75px',
        fontWeight: '300',
        paddingLeft: '10px',
        paddingTop: '10px',
    },
    keyData: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '36px',
        textAlign: 'center',
        marginTop: '5px',
        paddingTop: '15px',
    },
    DeltaPositive: {
        fontFamily: 'Roboto',
        fontWeight: '350',
        fontSize: '36px',
        textAlign: 'center',
        color: '#5FAA00',
    },
    DeltaNegative: {
        fontFamily: 'Roboto',
        fontWeight: '350',
        fontSize: '36px',
        textAlign: 'center',
        color: '#FF0000',
    },
    DeltaNeutral: {
        fontFamily: 'Roboto',
        fontWeight: '350',
        fontSize: '36px',
        textAlign: 'center',
        color: '#5e5e5e',
    },
    Period: {
        fontFamily: 'Roboto',
        fontWeight: '300',
        fontSize: '16px',
        textAlign: 'center',
        color: '#000000',
        paddingTop: '15px',
        paddingBottom: '7px',
    },
    unit: {
        fontFamily: 'Roboto',
        fontWeight: '300',
        fontSize: '24px',
        textAlign: 'center',
        color: '#5FAA00',
    },
    unitNeutral: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '24px',
        textAlign: 'center',
        color: '#000000',
    },
    unitPositive: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '24px',
        textAlign: 'center',
        color: '#5FAA00',
    },
    unitNegative: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '24px',
        textAlign: 'center',
        color: '#FF0000',
    },
}))


export default function Boxes(props) {
    console.log(props)

    const classes = useStyles()



    if (props.color == 'verde' && props.delta > '0') {
        return (
            <Box className={classes.kpiShape} borderRadius='8px' boxShadow='0px 0px 4px 3px #00000040'>
                <Typography variant='p' className={classes.Title} >{props.name}</Typography>
                <Typography variant='p' className={classes.subTitle} >{props.category}</Typography>
                <Typography variant='h1' className={classes.keyData}>{props.value} <span className={classes.unitNeutral}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.DeltaPositive}>+{props.delta} <span className={classes.unitPositive}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.Period}>respecto al periodo a comparar</Typography>
            </Box>
        )
    }
    if (props.color == 'verde' && props.delta < '0') {
        return (
            <Box className={classes.kpiShape} borderRadius='8px' boxShadow='0px 0px 4px 3px #00000040'>
                <Typography variant='p' className={classes.Title} >{props.name}</Typography>
                <Typography variant='p' className={classes.subTitle} >{props.category}</Typography>
                <Typography variant='h1' className={classes.keyData}>{props.value} <span className={classes.unitNeutral}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.DeltaNegative}>{props.delta} <span className={classes.unitNegative}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.Period}>respecto al periodo a comparar</Typography>
            </Box>
        )
    }
    if (props.color == 'rojo' && props.delta > '0') {
        return (
            <Box className={classes.kpiShape} borderRadius='8px' boxShadow='0px 0px 4px 3px #00000040'>
                <Typography variant='p' className={classes.Title} >{props.name}</Typography>
                <Typography variant='p' className={classes.subTitle} >{props.category}</Typography>
                <Typography variant='h1' className={classes.keyData}>{props.value} <span className={classes.unitNeutral}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.DeltaNegative}>+{props.delta} <span className={classes.unitNegative}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.Period}>respecto al periodo a comparar</Typography>
            </Box>
        )

    }
    if (props.color == 'rojo' && props.delta <= '0') {
        return (
            <Box className={classes.kpiShape} borderRadius='8px' boxShadow='0px 0px 4px 3px #00000040'>
                <Typography variant='p' className={classes.Title} >{props.name}</Typography>
                <Typography variant='p' className={classes.subTitle} >{props.category}</Typography>
                <Typography variant='h1' className={classes.keyData}>{props.value} <span className={classes.unitNeutral}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.DeltaPositive}>{props.delta} <span className={classes.unitPositive}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.Period}>respecto al periodo a comparar</Typography>
            </Box>
        )

    }
    else {
        return (
            <Box className={classes.kpiShape} borderRadius='8px' boxShadow='0px 0px 4px 3px #00000040'>
                <Typography variant='p' className={classes.Title} >{props.name}</Typography>
                <Typography variant='p' className={classes.subTitle} >{props.category}</Typography>
                <Typography variant='h1' className={classes.keyData}>{props.value} <span className={classes.unitNeutral}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.DeltaNeutral}>{props.delta} <span className={classes.unit}>{props.unit}</span></Typography>
                <Typography variant='h1' className={classes.Period}>respecto al periodo a comparar</Typography>
            </Box>
        )
    }
}