import React from 'react'

const withProps = (injectedProps) => (WrappedComponent) => {
  const WithProps = (props) => (
    <WrappedComponent {...injectedProps} {...props} />
  )

  return WithProps
}

export default withProps
