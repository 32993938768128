import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/soil_management/machineries_category`

export const resourceKey = 'machinery'
export const collectionKey = 'machineries'

export const fields = [
  { name: 'Nombre', field: 'name' },
  { name: 'Precio unitario', field: 'unit_price', type: 'currency' },
]

export const all = (category_id) =>
  axios.get(`${RESOURCE_LOCATION}/${category_id}/machineries`).then(dataGetter)

export const create = (category_id, machinery) =>
  axios
    .post(`${RESOURCE_LOCATION}/${category_id}/machineries`, { machinery })
    .then(dataGetter)

export const remove = (category_id, machinery_id) =>
  axios
    .delete(`${RESOURCE_LOCATION}/${category_id}/machineries/${machinery_id}`)
    .then(dataGetter)

export const edit = (machinery_id, machinery, category_id) =>
  axios
    .put(`${RESOURCE_LOCATION}/${category_id}/machineries/${machinery_id}`, {
      machinery,
    })
    .then(dataGetter)
