import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

export const cattleSummaryMonthlyByPdIds = (
  dateFrom,
  dateTo,
  productiveUnitsIds,
) =>
  axios
    .get(`${BASE_URL}/analytics/milk_report/monthly`, {
      params: {
        date_from: dateFrom,
        date_to: dateTo,
        productive_units_ids: productiveUnitsIds,
      },
    })
    .then(dataGetter)

export const dailyDairyByPdIds = (dateFrom, dateTo, productiveUnitsIds) =>
  axios
    .get(`${BASE_URL}/analytics/milk_report/daily`, {
      params: {
        date_from: dateFrom,
        date_to: dateTo,
        productive_units_ids: productiveUnitsIds,
      },
    })
    .then(dataGetter)
