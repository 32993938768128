import Table from './Table'
import es from 'date-fns/locale/es'
import { format } from 'date-fns'

export default function PreTable({ data, month, units, keys }) {
  //console.log('pre_table_Days: ', data)

  let unit_ids = []
  let storage = {}
  data.forEach(({ id, data }) => {
    unit_ids.push(id)
    data.forEach(({ x, y }) => {
      if (storage[x]) {
        storage[x][id] = y
      } else {
        let value_by_date = {}
        value_by_date[id] = y
        value_by_date['fecha'] = format(new Date(x), 'dd-MMMM-yyyy', {
          locale: es,
        })
        storage[x] = value_by_date
      }
    })
  })
  let result = Object.values(storage).map((element) => {
    unit_ids.forEach((k) => {
      if (element[k] === undefined) {
        element[k] = 0
      }
    })
    return element
  })

  let finalarray = { data: result, headers: unit_ids }

  const headers = [{ label: 'fecha', key: 'fecha' }]

  const columns = [
    {
      name: 'unidad productiva',
      selector: (row) => row.fecha,
    },
  ]

  unit_ids.forEach((element) => {
    let head = {
      name: element,
      selector: (row) => row[element],
    }
    let csvHead = { label: element, key: element }
    columns.push(head)
    headers.push(csvHead)
  })

  //console.log('columns: ', columns)

  return (
    <>
      <Table data={finalarray} columns={columns} headers={headers} />
    </>
  )
}
