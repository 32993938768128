// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/boxplot
import { ResponsiveBoxPlot } from '@nivo/boxplot'
import es from 'date-fns/locale/es'
import { format, add } from 'date-fns'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const CustomTooltip = ({ data, month, units }) => {
  //console.log('toltip: ', data)
  const initialDate = new Date(data.group)
  const newDate = add(initialDate, { months: 1 })
  return (
    <div
      style={{
        padding: '20px',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    >
      Fecha:{' '}
      <strong>
        {format(newDate, month ? 'yyyy' : 'MMMM-yyyy', { locale: es })}
      </strong>
      <br />
      Unidad productiva: <strong>{data.subGroup}</strong>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '30px',
          paddingTop: '15px',
        }}
      >
        <div>
          Resumen:
          <br />
          media:{' '}
          <b>
            {data.data.mean.toFixed(0)}
            {units === 'porcentaje' && '%'}
          </b>
          <br />
          min:{' '}
          <b>
            {data.data.extrema[0].toFixed(0)}
            {units === 'porcentaje' && '%'}
          </b>
          <br />
          max:
          <b>
            {data.data.extrema[1].toFixed(0)}
            {units === 'porcentaje' && '%'}
          </b>
          <br />
        </div>
        <div>
          Quantiles:
          <br />
          10%:{' '}
          <b>
            {data.data.values[0].toFixed(0)}
            {units === 'porcentaje' && '%'}
          </b>
          <br />
          25%:{' '}
          <b>
            {data.data.values[1].toFixed(0)}
            {units === 'porcentaje' && '%'}
          </b>
          <br />
          50%:{' '}
          <b>
            {data.data.values[2].toFixed(0)}
            {units === 'porcentaje' && '%'}
          </b>
          <br />
          75%:{' '}
          <b>
            {data.data.values[3].toFixed(0)}
            {units === 'porcentaje' && '%'}
          </b>
          <br />
          90%:{' '}
          <b>
            {data.data.values[4].toFixed(0)}
            {units === 'porcentaje' && '%'}
          </b>
        </div>
      </div>
    </div>
  )
}
export const MyResponsiveBoxPlot = ({
  data /* see data tab */,
  month,
  units,
}) => (
  <ResponsiveBoxPlot
    data={data}
    margin={{ top: 60, right: 140, bottom: 60, left: 60 }}
    subGroupBy='subgroup'
    quantiles={[0.1, 0.25, 0.5, 0.75, 0.9]}
    padding={0.12}
    enableGridX={true}
    tooltip={(data) => (
      <CustomTooltip data={data} month={month} units={units} />
    )}
    axisTop={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: 36,
      truncateTickAt: 0,
    }}
    axisRight={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: 0,
      truncateTickAt: 0,
    }}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Fechas',
      //format: '%Y', // Formato de fecha en el eje X
      legendPosition: 'middle',
      legendOffset: 32,
      truncateTickAt: 0,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: (value) => (units == 'porcentaje' ? `${value}%` : value),
      legend:
        units == 'int'
          ? 'Unidades'
          : units == 'porcentaje'
          ? 'Porcentaje'
          : 'count',
      legendPosition: 'middle',
      legendOffset: -40,
      truncateTickAt: 0,
    }}
    colors={['#5FAA00', '#A03CB5', '#148AF5', '#FF7A00']}
    borderRadius={2}
    borderWidth={2}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 0.3]],
    }}
    medianWidth={2}
    medianColor={{
      from: 'color',
      modifiers: [['darker', 0.3]],
    }}
    whiskerEndSize={0.6}
    whiskerColor={{
      from: 'color',
      modifiers: [['darker', 0.3]],
    }}
    motionConfig='stiff'
    legends={[
      {
        anchor: 'right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemWidth: 60,
        itemHeight: 20,
        itemsSpacing: 3,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        symbolSize: 20,
        symbolShape: 'square',

        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]}
  />
)
