import React, { useState, useEffect } from 'react'
import { Grid, Paper, Box, makeStyles } from '@material-ui/core'
import { Farm } from 'utils/api/models'
import ResourcesAsTable from './ResourcesAsTable'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'actions'

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    flexGrow: '3',
    flexDirection: 'column',
    marginTop: '-85px',
    right: theme.spacing(2),
  },
  formContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[5],
  },
}))

const Farms = () => {
  const classes = useStyles()
  const [farmsData, setFarmsData] = useState([])
  const [selectedFarm, setSelectedFarm] = useState('')
  const routeParams = useSelector((state) => state.routeParams)
  const dispatch = useDispatch()

  useEffect(() => {
    getFarmData()
  }, [routeParams.userId, routeParams.farmOwnerId])

  async function getFarmData() {
    const farms = await Farm.all()
    setFarmsData(farms)
    dispatch(actions.breadcrumbResourcesUpdated({ farmOwnerId: true }))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
          >
            <h2>Administrar campos</h2>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          position='relative'
          height='80vh'
        >
          <ResourcesAsTable
            resources={farmsData}
            model={Farm}
            title='Campo'
            refreshData={getFarmData}
            setSelected={(id) => setSelectedFarm(id)}
            parentSelections={[]}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Farms
