import React, { useState } from 'react'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'

import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { DatePicker } from '@material-ui/pickers'
import CostCenterTableCell from 'components/CostCenterTableCell'
import CostCenterItemTableCell from 'components/CostCenterItemTableCell'
import { useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  filterOptions,
  findCategoryOption,
  renderCategoryLabel,
} from 'components/CostCenterAssignmentModal/CostCenterAssignmentModal'
import NewManualDocument from 'components/NewManualDocument'
import { removeResource } from 'components/ResourcesAsTable/ResourcesAsTable'
import { useSnackbar } from 'notistack'
import { Document as DocumentModel } from 'utils/api/models'
import _ from 'lodash'
import EnhancedTableToolbar from 'components/EnhancedTableToolbar'
import EditIcon from '@material-ui/icons/Edit';
import EditDocumentModalComponent from './EditDocumentModal'
import DocumentFilter from './DocumentFilter'
import { addDays, format, endOfDay, startOfDay, endOfYear,formatISO, startOfYear } from 'date-fns';
const formatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
})

const useRowStyles = makeStyles((theme) => ({
  root: {
    // '& > *': {
    //   borderBottom: 'unset',
    // },
  },
  nestedRow: { borderBottom: 'unset' },
  titleTable: {
    marginLeft: '25px',
  },
  greenTheme: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  redTheme: {
    color: '#fff',
    backgroundColor: 'red',
  },
  avatar: {
    height: '28px',
    width: '28px',
    fontSize: '.8em',
  },
  avatarCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    border: 'unset',
    cursor: 'pointer',
  },
  plusCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paperDatesPickers: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    alignItems: 'center',
  },
  paperDatesPickersChild: {
    padding: '0px 10px',
  },
  iconbuttonActive: {
    color: 'black',
  },
  pointer: {
    cursor: 'pointer',
  },
}))

const HeaderTableCell = withStyles(() => ({
  head: {
    borderBottom: '2px solid black',
    fontWeight: 900,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export function Row({ row, businessNameKeys, itemsOnly = false, EditDocumentModal,
  setEditDocumentModal, setSelectedDocument, ...props }) {
  const [open, setOpen] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [selected, setSelected] = React.useState({})
  
  const numSelected = _(selected).keys().value().length
  const onSelectAllClick = () =>
    setSelected((s) =>
      _(s).keys().value().length
        ? {}
        : _(row.items)
            .map('id')
            .zipObject()
            .mapValues(() => true)
            .value(),
    )
  const rowCount = row.items.length
  console.log(selected)

  const classes = useRowStyles()
  const businessName =
    row.content.Encabezado[businessNameKeys[0]][businessNameKeys[1]]

  const { enqueueSnackbar } = useSnackbar()
  const remove = () =>
    removeResource(
      { model: DocumentModel, parentSelections: [], ...props },
      row,
      enqueueSnackbar,
      () => null,
    )
  const deleteRow = (e) => {
    e.stopPropagation()
    setOpenDelete(true)
  }

  return (
    <React.Fragment>
      
      {!itemsOnly && (
        <TableRow
          hover
          className={classes.root}
        >
          <TableCell onClick={() => setOpen((o) => !o)} className={classes.pointer}>
            <IconButton aria-label='expand row' size='small' >
              {open ? (
                <KeyboardArrowDownIcon color='primary' />
              ) : (
                <KeyboardArrowRightIcon color='primary' />
              )}
            </IconButton>
          </TableCell>

          <TableCell component='th' scope='row' onClick={() => setOpen((o) => !o)} lassName={classes.pointer}>
            {format(new Date(row.date), 'dd/MM/yyyy')}
          </TableCell>

          <TableCell align='center' onClick={() => setOpen((o) => !o)} className={classes.pointer}>{formatter.format(row.total)}</TableCell>
          <TableCell align='center' onClick={() => setOpen((o) => !o)} className={classes.pointer}>{row.items.length}</TableCell>
          <TableCell align='center' onClick={() => setOpen((o) => !o)} className={classes.pointer}>{row.dte_id}</TableCell>
          <TableCell align='center' onClick={() => setOpen((o) => !o)} className={classes.pointer}>{businessName}</TableCell>
          <TableCell align='center' className={classes.avatarCell} onClick={() => setOpen((o) => !o)}>
            <CostCenterTableCell
              data={row.items}
              availableAmount={row.availableAmount}
              categories={props.categories}
            />
          </TableCell>
          <TableCell align='center'> 
            <IconButton onClick={() => {setEditDocumentModal(!EditDocumentModal); setSelectedDocument(row)}} size='small' > 
              <EditIcon color='primary' className={row.payments_first_date === null && classes.iconbuttonActive} />
            </IconButton>
            {row.code === 'manual' ? <DeleteIcon onClick={deleteRow} /> : null}
          </TableCell>

        </TableRow>
        
      )}
      <TableRow className={classes.nestedRow}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
          colSpan={8}
        >
          <Collapse in={open || itemsOnly} timeout='auto' unmountOnExit>
            <div style={{ paddingBottom: '10px' }}>
              <Paper margin={1} elevation={3}>
                <EnhancedTableToolbar
                  numSelected={numSelected}
                  selectedIds={Object.keys(selected).map(Number)}
                  document={row}
                  {...props}
                />
                <Table size='small' aria-label='purchases'>
                  <TableHead>
                    <TableRow hover>
                      <HeaderTableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          indeterminate={
                            numSelected > 0 && numSelected < rowCount
                          }
                          checked={rowCount > 0 && numSelected === rowCount}
                          onChange={onSelectAllClick}
                        />
                      </HeaderTableCell>
                      <HeaderTableCell>Nombre</HeaderTableCell>
                      <HeaderTableCell align='center'>Cantidad</HeaderTableCell>
                      <HeaderTableCell align='center'>
                        Precio unitario
                      </HeaderTableCell>
                      <HeaderTableCell align='center'>
                        Total&nbsp;($)
                      </HeaderTableCell>
                      <HeaderTableCell align='center'>
                        Centro de Costo
                      </HeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.items.map((item) => {
                      const isItemSelected = selected[item.id] !== undefined
                      return (
                        <TableRow key={item.id} hover selected={isItemSelected}>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              checked={isItemSelected}
                              onChange={(e) =>
                                setSelected((s) => {
                                  return e.target.checked
                                    ? {
                                        ...s,
                                        [item.id]: true,
                                      }
                                    : _.omit(s, [item.id])
                                })
                              }
                            />
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.name}
                          </TableCell>
                          <TableCell align='center'>{item.quantity}</TableCell>
                          <TableCell align='center'>
                            {formatter.format(item.unit_value)}
                          </TableCell>
                          <TableCell align='center'>
                            {formatter.format(item.total)}
                          </TableCell>

                          <TableCell
                            align='center'
                            className={classes.avatarCell}
                          >
                            <CostCenterItemTableCell
                              data={item}
                              /* availableAmount={row.availableAmount} */
                              rznSoc={businessName}
                              documentId={row.id}
                              onDelete={(item_detail_id) =>
                                props.deleteCostCenterToItem(
                                  row.id,
                                  item.id,
                                  item_detail_id,
                                )
                              }
                              onEdit={(id, item_detail) =>
                                props.replaceItemDetail(
                                  row.id,
                                  item.id,
                                  id,
                                  item_detail,
                                )
                              }
                              onSuccess={(item_detail) =>
                                props.appendCostCenterToItem(
                                  row.id,
                                  item.id,
                                  item_detail,
                                )
                              }
                              {...props}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle id='alert-dialog-title'>
          {'Eliminar documento'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            ¿Eliminar este documento? Se eliminarán también todos los items y
            asociaciones a centros de costo. Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              remove()
              setOpenDelete(false)
            }}
            color='primary'
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

function Documents({ getData: _getData, title, singleDoc = false, ...props }) {
  /* ESTE ES EL COMPONENTE GRANDE */
  const [docs, setDocs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [EditDocumentModal, setEditDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({}) 
 

  const year = useSelector(
    (state) => state.routeParams.year || new Date().getFullYear(),
  )

  /*
  const [selectedDate, setSelectedDate] = useState(
    startOfYear(new Date().setFullYear(year)),
  )
  const [selectedDateFinal, setSelectedDateFinal] = useState(
    endOfYear(new Date().setFullYear(year)),
  )
    */

  const [state, setState] = useState([
    {
      startDate: startOfYear(new Date().setFullYear(year)),
      endDate: endOfYear(new Date().setFullYear(year)),
      key: 'selection',
    },
  ]); 

  const [categoryFilter, setCategoryFilter] = useState({
    category_id: null,
    sub_category_id: null,
  })

  const [pagination, setPagination] = React.useState({
    page_number: 0,
    page_size: 15,
    total_entries: 0,
    total_pages: 0,
  })
  const [openNewDoc, setOpenNewDoc] = React.useState(false)

  const { userId, farmOwnerId, businessId } = useSelector(
    (state) => state.routeParams,
  )

  const [dteIdFilter, setDteIdFilter] = useState(undefined)
    const [contentRsFilter, setContentRsFilter] = useState(undefined)

  const handleSubmit = () => {
    async function getData() {
      let _pagination = {}
      setIsLoading(true)
      await setPagination((pagination) => {
        _pagination = { ...pagination }
        return pagination
      })

      try {
        const { documents, ...paginationData } = await _getData(
          format(startOfDay(state[0].startDate), 'yyyy-MM-dd HH:mm:ss'),
          format(endOfDay(state[0].endDate), 'yyyy-MM-dd HH:mm:ss'),
          _pagination.page_number,
          _pagination.page_size,
          categoryFilter.category_id,
          categoryFilter.sub_category_id,
          dteIdFilter,
          contentRsFilter,
        )

        setDocs(documents)
        setPagination(paginationData)
      } catch {
        setDocs([])
      } finally {
        setIsLoading(false)
      }
    }
    getData()
  }

  const appendCostCenterToItem = (document_id, item_id, item_detail) => {
    setDocs([
      ...docs.map((d) => {
        if (d.id == document_id) {
          const items = d.items.map((item) => {
            if (item.id == item_id) {
              const details = [...item.details, item_detail]
              return { ...item, details }
            } else {
              return { ...item }
            }
          })
          return { ...d, items }
        } else {
          return { ...d }
        }
      }),
    ])
  }

  const deleteCostCenterToItem = (document_id, item_id, item_detail_id) => {
    setDocs([
      ...docs.map((d) => {
        if (d.id == document_id) {
          const items = d.items.map((item) => {
            if (item.id == item_id) {
              const details = item.details.filter((d) => d.id != item_detail_id)

              return { ...item, details }
            } else {
              return { ...item }
            }
          })
          return { ...d, items }
        } else {
          return { ...d }
        }
      }),
    ])
  }

  const replaceItemDetail = (
    document_id,
    item_id,
    item_detail_id,
    item_detail,
  ) => {
    setDocs([
      ...docs.map((d) => {
        if (d.id == document_id) {
          const items = d.items.map((item) => {
            if (item.id == item_id) {
              const details = item.details.map((d) => {
                if (d.id === item_detail_id) {
                  return item_detail
                } else {
                  return d
                }
              })

              return { ...item, details }
            } else {
              return { ...item }
            }
          })
          return { ...d, items }
        } else {
          return { ...d }
        }
      }),
    ])
  }

  const handleChangePage = (event, page_number) => {
    setPagination({ ...pagination, page_number: page_number + 1 })
    handleSubmit()
  }

  const handleChangeRowsPerPage = (event) => {
    const page_size = Number(event.target.value)
    setPagination({ ...pagination, page_size, page_number: 0 })
    handleSubmit()
  }

  React.useEffect(handleSubmit, [userId, farmOwnerId, businessId])

  const classes = useRowStyles()

  const toggleAddManualDoc = () => setOpenNewDoc((o) => !o)

  return singleDoc ? (
    docs.length ? (
      <Row
        key={docs[0].id}
        row={docs[0]}
        appendCostCenterToItem={appendCostCenterToItem}
        deleteCostCenterToItem={deleteCostCenterToItem}
        replaceItemDetail={replaceItemDetail}
        EditDocumentModal={EditDocumentModal}
        setEditDocumentModal={setEditDocumentModal}
        itemsOnly
        {...props}
      />
    ) : (
      <CircularProgress color='white' />
    )
  ) : (
    <React.Fragment>
      <EditDocumentModalComponent 
        open={EditDocumentModal} 
        setOpen={setEditDocumentModal} 
        row={selectedDocument} 
      />
      <DocumentFilter 
        state={state} 
        setState={setState} 
        handleSubmit={handleSubmit} 
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        dteIdFilter={dteIdFilter}
        setDteIdFilter={setDteIdFilter}
        contentRsFilter={contentRsFilter} 
        setContentRsFilter={setContentRsFilter}
        {...props} 
        />
      <br />
      <TableContainer component={Paper}>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <h2 className={classes.titleTable}>{title}</h2>
          <Box pl={5}>
            <Button
              color='primary'
              size='small'
              endIcon={<AddIcon />}
              onClick={toggleAddManualDoc}
            >
              Documento manual
            </Button>
          </Box>
        </Box>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow hover>
              <HeaderTableCell />
              <HeaderTableCell>Fecha</HeaderTableCell>
              <HeaderTableCell align='center'>Total&nbsp;($)</HeaderTableCell>
              <HeaderTableCell align='center'>Items</HeaderTableCell>
              <HeaderTableCell align='center'>N° de factura</HeaderTableCell>
              <HeaderTableCell align='center'>Razón social</HeaderTableCell>
              <HeaderTableCell align='center'>Centro de costo</HeaderTableCell>
              <HeaderTableCell align='center'>Acciones</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? (
              docs.length !== 0 ? (
                docs.map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    appendCostCenterToItem={appendCostCenterToItem}
                    deleteCostCenterToItem={deleteCostCenterToItem}
                    replaceItemDetail={replaceItemDetail}
                    refreshData={handleSubmit}
                    EditDocumentModal={EditDocumentModal}
                    setEditDocumentModal={setEditDocumentModal}
                    setSelectedDocument={setSelectedDocument}

                    {...props}
                  />
                ))
              ) : (
                <Box p={2}>
                  <span>
                    <small> No hay datos</small>
                  </span>
                </Box>
              )
            ) : (
              <Box p={2}>
                <CircularProgress size={20} />
              </Box>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[15, 25, 100]}
          component='div'
          count={pagination.total_entries}
          rowsPerPage={pagination.page_size}
          page={pagination.page_number - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        ></TablePagination>
      </TableContainer>
      <NewManualDocument
        open={openNewDoc}
        onClose={toggleAddManualDoc}
        isEmitter={props.isEmitter}
        refreshData={handleSubmit}
        {...props}
      />
      
    </React.Fragment>
  )
}

export default Documents
