import React from 'react'
import {
  Typography,
  useTheme,
  Box,
  withStyles,
  makeStyles,
} from '@material-ui/core'
import { getPolygonData } from 'utils/common'
import { getShortName } from 'utils/api/models/Meadow'
import clsx from 'clsx'

const MeadowNameTerrain = withStyles({
  root: {
    fontWeight: 'bold',
    color: 'rgba(0,0,0,.8)',
    textShadow: '0px 0px 3px white',
  },
})(Typography)
const MeadowNameHybrid = withStyles({
  root: {
    fontWeight: 'bold',
    color: 'white',
    textShadow: '0px 0px 3px black',
  },
})(Typography)

const useStyles = makeStyles({
  small: {
    fontSize: 12,
    fontWeight: 'thin',
  },
})

const getStrokeColor = (isSelected) => ({
  terrain: isSelected ? 'black' : '#888',
  hybrid: isSelected ? '#ff9800' : '#880',
  satellite: isSelected ? 'black' : '#888',
})
const getStrokeWeight = (isSelected) => ({
  terrain: isSelected ? 2 : 1,
  hybrid: isSelected ? 4 : 3,
  satellite: isSelected ? 2 : 1,
})
const defaultFillColor = {
  terrain: '#aaa',
  hybrid: '#ff9800',
  satellite: '#aaa',
}

export default function MeadowPolygon({
  resource: meadow,
  isSelected,
  onClick,
  mapApi,
  mapInstance,
  isEditing = false,
  currentZoom,
  color,
  mapType,
  ...props
}) {
  const theme = useTheme()
  const classes = useStyles()
  const polyRef = React.useRef()

  const updatePolygon = () => {
    if (mapApi) {
      const commonProps = {
        strokeColor: getStrokeColor(isSelected)[mapType],
        fillColor: color || defaultFillColor[mapType],
        strokeWeight: getStrokeWeight(isSelected)[mapType],
        fillOpacity: isSelected ? 0.5 : 0.1,
        editable: isEditing,
      }
      if (!polyRef.current) {
        const polygon = new mapApi.Polygon({
          ...commonProps,
          paths: meadow.coordinates.polygon,
        })
        polygon.addListener('click', (e) => onClick(e))
        polygon.setMap(mapInstance)
        polyRef.current = polygon
        const path = polygon.getPath()

        path.addListener('set_at', () =>
          props.onPolygonUpdate(meadow.id, getPolygonData(mapApi, polygon)),
        )
        path.addListener('remove_at', () =>
          props.onPolygonUpdate(meadow.id, getPolygonData(mapApi, polygon)),
        )
        path.addListener('insert_at', () =>
          props.onPolygonUpdate(meadow.id, getPolygonData(mapApi, polygon)),
        )
        const deleteVertex = function (mev) {
          if (mev.vertex != null) {
            polygon.getPath().removeAt(mev.vertex)
            props.onPolygonUpdate(meadow.id, getPolygonData(mapApi, polygon))
          }
        }
        polygon.addListener('rightclick', deleteVertex)
      } else {
        polyRef.current.setOptions(commonProps)
      }
    }
  }
  React.useEffect(updatePolygon, [
    isSelected,
    mapApi,
    mapInstance,
    meadow,
    onClick,
    theme.palette.warning.main,
    isEditing,
    color,
  ])

  const resetPath = () => {
    if (!isEditing && mapApi) {
      polyRef.current?.setPath(meadow.coordinates.polygon)
      const polygon = polyRef.current
      const path = polygon?.getPath()
      path.addListener('set_at', () =>
        props.onPolygonUpdate(meadow.id, getPolygonData(mapApi, polygon)),
      )
      path.addListener('remove_at', () =>
        props.onPolygonUpdate(meadow.id, getPolygonData(mapApi, polygon)),
      )
      path.addListener('insert_at', () =>
        props.onPolygonUpdate(meadow.id, getPolygonData(mapApi, polygon)),
      )
    }
  }
  React.useEffect(resetPath, [isEditing, polyRef, meadow.coordinates.polygon])

  React.useEffect(
    () =>
      function cleanUp() {
        polyRef.current?.setMap(null)
      },
    [],
  )
  const MeadowName =
    mapType === 'terrain' ? MeadowNameTerrain : MeadowNameHybrid

  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      lineHeight={0.5}
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      <MeadowName className={clsx(currentZoom <= 13 && classes.small)}>
        {getShortName(meadow)}
      </MeadowName>
      {currentZoom >= 15 && (
        <MeadowName variant='caption'>{meadow.area_in_ha}ha</MeadowName>
      )}
    </Box>
  )
}
