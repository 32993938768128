const path = (state = '', action) => {
  switch (action.type) {
    case '@@router/ROUTE_CHANGED':
      return action.url

    default:
      return state
  }
}

export default path
