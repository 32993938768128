import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core'

import React from 'react'
import withProps from 'hoc/withProps'
import withReducerState from 'hoc/withReducerState'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  ManagementCategory,
  ManagementTemplateOwner,
  TaskGroupTemplate,
  TaskResourceTemplate,
  TaskTemplate,
} from 'utils/api/models'

import NestedResourcesLayout from 'components/NestedResourcesLayout'

import _ from 'lodash'
import {
  managementInitialState,
  managementReducer,
} from './ManagementTemplates'

const AccordionSelector = ({ children, active, title }) => {
  const accordionRef = React.createRef()
  React.useEffect(() => {
    if (active) {
      accordionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [active])
  //console.log(children, `${title}`)
  return (
    <>
      <Accordion style={{ width: '100%' }} expanded={active} ref={accordionRef}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <h3 style={{ margin: '0px' }}>{title}</h3>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  )
}

const ManagementTemplateSelector = withProps({
  elementHeights: 'full',
  layout: [
    {
      id: 1,
      title: 'Categorías',
      model: ManagementCategory,
      size: 2,
      listMode: 'list',
      Component: (props) => (
        <AccordionSelector {...props} title='Seleccione Cultivo' />
      ),
    },
    {
      id: 2,
      title: 'Manejos',
      model: ManagementTemplateOwner,
      size: 3,
      parent_id: 1,
      listMode: 'list',
      Component: (props) => (
        <AccordionSelector {...props} title='Seleccione Tipo de Cultivo' />
      ),
      _get: (state, categoryId) => {
        return _.values(state.managements).filter(
          (m) => m.soil_management_category.id == categoryId,
        )
      },
    },
    {
      id: 3,
      title: 'Etapas',
      model: TaskGroupTemplate,
      size: 3,
      parent_id: 2,
      listMode: 'transfer-list',
      Component: (props) => (
        <AccordionSelector {...props} title='Seleccione Manejo Agronómico' />
      ),
      _get: (state, _categoryId, managementId) => {
        return state.managements[managementId].task_groups.map(
          (id) => state.task_groups[id],
        )
      },
    },
    {
      id: 4,
      title: 'Maquinarias',
      model: TaskTemplate,
      parent_id: 3,
      listMode: 'transfer-list',
      Component: (props) => (
        <AccordionSelector {...props} title='Seleccione Maquinaria' />
      ),
      _get: (state, _categoryId, _managementId, taskGroupId) => {
        return state.task_groups[taskGroupId].tasks.map((id) => state.tasks[id])
      },
    },
  ],
})(NestedResourcesLayout)

const ManagementTemplateSelectorWithState = withReducerState(
  ManagementTemplateOwner,
  managementReducer,
  managementInitialState,
)(ManagementTemplateSelector)

export default ManagementTemplateSelectorWithState
