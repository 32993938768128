import React from 'react'
import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
import { Box, Typography } from '@material-ui/core'
import ResourcesModalForm from 'components/ResourcesModalForm'
import { formatISO } from 'date-fns'
import { Alert } from '@material-ui/lab'
const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/`
const SCRAPPER_LOCATION = `${BASE_URL}/api/sii_scraper/`

export const resourceKey = 'business'
export const collectionKey = 'businesses'

function ScrapperRun({ resource }) {
  const model = {
    fields: [
      { name: 'Fecha inicio', field: 'desde', type: 'date' },
      { name: 'Fecha fin', field: 'hasta', type: 'date' },
    ],
    create: (params) =>
      axios
        .post(SCRAPPER_LOCATION, {
          desde: formatISO(params.desde, { representation: 'date' }),
          hasta: formatISO(params.hasta, { representation: 'date' }),
          business_rut: resource.business_rut,
        })
        .catch((e) => {
          throw e.response
        }),
  }

  return resource?.id ? (
    <Box py={1}>
      <Typography variant='h2'>Obtener DTEs desde SII</Typography>
      <Alert severity='info'>
        Presione el botón e indique el periodo requerido. Luego de unos minutos
        podrá encontrar los DTE en el módulo de finanzas.
      </Alert>
      <Box py={1}>
        <ResourcesModalForm
          resources={[]}
          parentSelections={[]}
          setSelected={() => null}
          refreshData={() => null}
          model={model}
          noContainer
          title='Ejecución'
        />
      </Box>
    </Box>
  ) : null
}
export const fields = [
  { name: 'Nombre', field: 'name' },
  { name: 'RUT persona SII', field: 'admin_rut', only: 'create' },
  {
    name: 'Contraseña persona SII',
    field: 'admin_sii_password',
    only: 'create',
  },
  { name: 'RUT empresa', field: 'business_rut' },
  {
    name: 'Requiere actualiazar contraseña',
    field: 'deprecated_sii_password',
    only: 'list',
    type: 'boolean',
  },
  {
    name: 'Contraseña empresa SII',
    field: 'business_sii_password',
    only: 'create',
  },
  {
    name: 'Obtención de DTE',
    field: 'dte',
    only: 'create',
    Component: ScrapperRun,
  },
]

export const create = async (business) =>
  axios
    .post(RESOURCE_LOCATION, {
      business,
    })
    .then(dataGetter)
    .catch((e) => {
      throw e.response
    })

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const edit = (business_id, business) =>
  axios
    .put(`${RESOURCE_LOCATION}/${business_id}`, {
      business,
    })
    .then(dataGetter)

export const remove = (business_id) =>
  axios.delete(`${RESOURCE_LOCATION}/${business_id}`).then(dataGetter)
