import { connect } from 'react-redux'
import { compose } from 'lodash/fp'

import Documents from 'components/Documents'
import withProps from 'hoc/withProps'
import { Spend } from 'utils/api/models'
import withYearParam from 'hoc/withYearParam'
import withOwnerBusinessParam from 'hoc/withOwnerBusinessParam'

const getData = Spend?.all

const mapState = ({ categories: { spends } }) => ({ categories: spends })

const Spends = compose(
  connect(mapState),
  withProps({
    getData,
    title: 'Compras',
    businessNameKeys: ['Emisor', 'RznSoc'],
    isEmitter: false,
  }),
)(Documents)

export default Spends
