import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/soil_management/machineries_category/`

export const resourceKey = 'machinery_category'
export const collectionKey = 'machinery_categories'

export const fields = [{ name: 'Nombre', field: 'name' }]

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const create = (machinery_category) =>
  axios.post(RESOURCE_LOCATION, { machinery_category }).then(dataGetter)

export const remove = (machinery_category_id) =>
  axios.delete(`${RESOURCE_LOCATION}${machinery_category_id}`).then(dataGetter)

export const edit = (machinery_category_id, machinery_category) =>
  axios
    .put(`${RESOURCE_LOCATION}${machinery_category_id}`, { machinery_category })
    .then(dataGetter)
