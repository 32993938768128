import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { ManagementCategory } from 'utils/api/models'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
    marginRight: '20px',
    //marginLeft: '20px',
  },
}))

function TaskCategorySelector({ setValue, analytics = false }) {
  const [categories, setCategories] = React.useState([])

  React.useEffect(() => {
    async function getData() {
      const data = await ManagementCategory.all()
      setCategories(data)
      //console.log('data management: ', data)
      if (analytics){ setValue(data.map((x)=>(x.id)))} 
    }
    getData()
  }, [])


  function handleValueChange(value, analytics){
    //console.log(value)
    if (analytics){
      if (value === null) {
        setValue(categories.map((x)=>(x.id)))
      } else { setValue([value?.id])}
    } else { setValue(value?.id) }
  }

  const classes = useStyles()

  return (
    <Autocomplete
      className={classes.fullWidth}
      id='category'
      options={categories}
      getOptionLabel={(option) => option.name}
      onChange={(_event, value) => handleValueChange(value, analytics)}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Cultivos'
          defaultValue='Todos los cultivos'
          size='small'
          style={{
            width: '100%',
          }}
        />
      )}
    />
  )
}

export default TaskCategorySelector
