import _ from 'lodash'

 export function dosisPorCampo(data) {

    let dataByInsumo = _.groupBy(data?.data, (x) => x.insumo)
    let dataByInsumoToNivo = [] 
    let getFarmsNames = []
    Object.entries(dataByInsumo).forEach(([k,v])=>{
        //console.log(k, v)
        let insumo = {}
        insumo['insumo'] = k
        for (var j = 0; j < v.length ; j++ ) {
            insumo[v[j].campo] = Math.round(Number(v[j].dosis)).toLocaleString('es-CL') 
            getFarmsNames.push(v[j].campo)
        }
        //console.log('insumo objeto: ', insumo)
        dataByInsumoToNivo.push(insumo)
    })
    
    let headers = Array.from(new Set(getFarmsNames))
    let result = dataByInsumoToNivo.map(element => {
        headers.forEach((k)=>{ 
                if(element[k] === undefined){ element[k] = 0} 
        }) 
        return element
    });
    //console.log('data: ',data)
    //console.log('groupBydata ',dataByInsumo)
    console.log('groupBydatNivo ',dataByInsumoToNivo)
    //console.log('keys: ', FarmsNames)

    return {data: result, headers: headers }
}