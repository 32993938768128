import { TextField, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { MachineryCategory } from 'utils/api/models'

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
    marginRight: '20px',
    marginLeft: '20px',
  },
}))

function MachineryCategorySelector({ setValue }) {
  const [categories, setCategories] = React.useState([])

  React.useEffect(() => {
    async function getData() {
      const data = await MachineryCategory.all()
      setCategories(data)
    }
    getData()
  }, [])

  const classes = useStyles()

  return (
    <Autocomplete
      id='category'
      className={classes.fullWidth}
      options={categories}
      getOptionLabel={(option) => option.name}
      onChange={(_event, value) => setValue(value?.id)}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Categoría de maquinaria'
          defaultValue='Todas las maquinarias'
          size='small'
          style={{
            width: '100%',
          }}
        />
      )}
    />
  )
}

export default MachineryCategorySelector
