import * as types from 'actions/types'

const INITIAL_STATE = {
  isLoading: false,
  pendingRequests: 0,
}

const network = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.START_LOADING: {
      return {
        ...state,
        isLoading: true,
        pendingRequests: state.pendingRequests + 1,
      }
    }

    case types.STOP_LOADING: {
      const isLoading = state.pendingRequests - 1 !== 0
      return {
        ...state,
        isLoading,
        pendingRequests: state.pendingRequests - 1,
      }
    }

    default:
      return state
  }
}

export default network
