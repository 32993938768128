import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/`

export const fields = [
  // { name: 'id', field: 'id', only: 'list' },
  { name: 'Nombre de Cuenta', field: 'name' },
  {
    name: 'Limite de usuarios',
    field: 'max_users',
    type: 'number',
  },
]

export const create = async ({ ...farm_owner }) =>
  axios
    .post(`${RESOURCE_LOCATION}`, {
      farm_owner,
    })
    .then(dataGetter)

export const all = () => axios.get(`${RESOURCE_LOCATION}`).then(dataGetter)

export const get = (id) =>
  axios.get(`${RESOURCE_LOCATION}${id}`).then(dataGetter)

export const edit = (farm_owner_id, farm_owner) =>
  axios
    .put(`${RESOURCE_LOCATION}${farm_owner_id}`, { farm_owner })
    .then(dataGetter)
