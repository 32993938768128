import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import {
  Grid,
  Paper,
  Box,
  Divider,
  Button,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import {
  CattleSumm,
  monthlyCattleSumm,
  ProductiveUnits,
} from 'utils/api/models'
import { format, isSameMonth, isSameDay } from 'date-fns'
import _ from 'lodash'
import CattleSummariesSelector from './CattleSummariesSelector'

const useStyles = makeStyles((theme) => ({
  background: {
    display: 'flex',
    flexGrow: '3',
    flexDirection: 'column',
  },
  colorText: {
    color: theme.palette.primary.main,
  },
  TabPanelBackground: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid #cccccc',
    borderRadius: '6px',
  },
  InputsByDayPaper: {
    padding: '1px',
  },
  TextField: {
    color: 'black',
  },
  space: {
    margin: '5px',
  },
  productiveSelector: {
    marginTop: '10px',
    marginLeft: '20px',
    width: '180px',
  },
}))

const initialValues = {
  dailyMilk: CattleSumm.DailyMilk,
  dailyCattle: CattleSumm.DailyCattle,
  dailyMeasurements: CattleSumm.DailyMeasurements,
  livestockInventory: monthlyCattleSumm.cattleSummary1,
  productionPerformance: monthlyCattleSumm.PerformanceProduction1,
  healthEvents: monthlyCattleSumm.SanitaryEvents1,
}

export default function CattleSummaries() {
  const classes = useStyles()
  const [summary, setSummary] = useState([])
  const [summaryMonthly, setSummaryMonthly] = useState([])
  const [disabledData, setDisabledData] = useState(true)
  const [date, setDate] = useState(new Date())
  const [CattleSummariesType, setCattleSummariesType] = useState('diario')
  const routeParams = useSelector((state) => state.routeParams)
  const { enqueueSnackbar } = useSnackbar()

  async function getCattleSummary() {
    try {
      const response = await CattleSumm.all()
      const data = response.data // Extraer la propiedad 'data' de la respuesta
      setSummary(data)
    } catch (error) {
      console.error('Error al obtener los datos del resumen:', error)
    }
  }

  async function getMonthlyCattleSummary() {
    const data = await monthlyCattleSumm.all()
    if (data.length > 0 && !('date' in data[0])) {
      console.error(
        'Los datos de resumen mensual no tienen la propiedad "date".',
      )
    }
    setSummaryMonthly(data)
  }

  useEffect(() => {
    getCattleSummary()
  }, [routeParams.userId, routeParams.farmOwnerId, routeParams.farmId])

  useEffect(() => {
    getMonthlyCattleSummary()
  }, [routeParams.userId, routeParams.farmOwnerId, routeParams.farmId])

  useEffect(() => {
    setDisabledData(true)
  }, [date, CattleSummariesType])
  const formik = useFormik({
    initialValues,
    onSubmit: () => null,
  })

  async function postCattleSummary() {
    const farmId = routeParams.farmId
    const rawDailyData = [
      ...formik.values.dailyCattle,
      ...formik.values.dailyMilk,
      ...formik.values.dailyMeasurements,
      { field: 'date', value: date },
    ]
    const productiveUnitId = selectedProductiveUnit
    const data = _.map(rawDailyData, (obj) => ({ [obj.field]: obj.value }))
    const finalData = data.reduce((acc, obj) => {
      return { ...acc, ...obj }
    })
    finalData.farm_id = farmId
    finalData.date = date.toISOString()
    finalData.productive_unit_id = productiveUnitId
    //console.log('vaca', finalData)

    try {
      await CattleSumm.create(finalData)
      enqueueSnackbar('Informe diario creado con éxito', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar(
        'Hubo un error al ingresar los datos, verifique los campos e intente nuevamente',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      )
    }
  }

  async function postMonthlyCattleSummary() {
    const farmId = routeParams.farmId
    const rawMonthlyData = [
      ...formik.values.livestockInventory,
      ...formik.values.productionPerformance,
      ...formik.values.healthEvents,
      { field: 'date', value: date },
    ]
    const productiveUnitId = selectedProductiveUnit // Obtener el ID de la unidad productiva seleccionada
    const data = _.map(rawMonthlyData, (obj) => ({ [obj.field]: obj.value }))
    const finalData = data.reduce((acc, obj) => {
      return { ...acc, ...obj }
    })

    finalData.farm_id = farmId
    finalData.date = date.toISOString()
    finalData.productive_unit_id = productiveUnitId
    try {
      await monthlyCattleSumm.create(finalData)
      enqueueSnackbar('Informe diario creado con éxito', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar(
        'Hubo un error al ingresar los datos, verifique los campos e intente nuevamente',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      )
    }
  }

  const refreshDailyData = async () => await getCattleSummary()
  const refreshMonthlyData = async () => await getMonthlyCattleSummary()

  const [selectedProductiveUnit, setSelectedProductiveUnit] = useState('')

  useEffect(() => {
    if (CattleSummariesType === 'diario') {
      let isSummary = _.filter(
        summary,
        (o) =>
          isSameDay(new Date(o.date), date) &&
          o.productive_unit === parseInt(selectedProductiveUnit),
      )
      if (isSummary.length > 0) {
        setActiveCattleSummaryId(isSummary[0].id)
      } else {
        setActiveCattleSummaryId(null)
        formik.setValues(initialValues)
      }
      if (isSummary.length === 0) {
        formik.setValues(initialValues)
      } else {
        const dailyMilkWithData = initialValues.dailyMilk.map((obj1) => ({
          ...obj1,
          value: isSummary[0][obj1.field],
        }))
        const dailyCattleWithData = initialValues.dailyCattle.map((obj1) => ({
          ...obj1,
          value: isSummary[0][obj1.field],
        }))
        const dailyMeasurementsWithData = initialValues.dailyMeasurements.map(
          (obj1) => ({ ...obj1, value: isSummary[0][obj1.field] }),
        )

        const initialValuesWithData = {
          dailyMilk: dailyMilkWithData,
          dailyCattle: dailyCattleWithData,
          dailyMeasurements: dailyMeasurementsWithData,
        }
        formik.setValues(initialValuesWithData)
      }
    } else {
      let isSummary = _.filter(
        summaryMonthly,
        (o) =>
          isSameMonth(new Date(o.date), date) &&
          o.productive_unit_id === parseInt(selectedProductiveUnit),
      )
      if (isSummary.length > 0) {
        setActiveMonthlyCattleSummaryId(isSummary[0].id)
      } else {
        setActiveMonthlyCattleSummaryId(null)
      }
      if (isSummary.length === 0) {
        formik.setValues(initialValues)
      } else {
        const livestockWithData = initialValues.livestockInventory.map(
          (obj1) => ({ ...obj1, value: isSummary[0][obj1.field] }),
        )
        const productionPerformanceWithData =
          initialValues.productionPerformance.map((obj1) => ({
            ...obj1,
            value: isSummary[0][obj1.field],
          }))
        const healthEventsWithData = initialValues.healthEvents.map((obj1) => ({
          ...obj1,
          value: isSummary[0][obj1.field],
        }))

        const initialValuesWithData = {
          livestockInventory: livestockWithData,
          productionPerformance: productionPerformanceWithData,
          healthEvents: healthEventsWithData,
        }
        formik.setValues(initialValuesWithData)
      }
    }
  }, [date, selectedProductiveUnit, disabledData])

  const handleProductiveUnitChange = (event) => {
    setSelectedProductiveUnit(event.target.value)
  }

  const [productiveUnits, setProductiveUnits] = useState([])

  useEffect(() => {
    if (productiveUnits.length > 0 && selectedProductiveUnit && date) {
      if (CattleSummariesType === 'diario') {
        getCattleSummary()
      } else {
        getMonthlyCattleSummary()
      }
    }
  }, [productiveUnits, selectedProductiveUnit, date, CattleSummariesType])

  useEffect(
    () => {
      async function fetchProductiveUnits() {
        try {
          const response = await ProductiveUnits.all()
          // Filtrar las unidades productivas por el farm_id actual
          const filteredUnits = response.data.filter(
            (unit) => unit.farm_id === routeParams.farmId,
          )
          setProductiveUnits(filteredUnits)
          // Establecer la primera unidad productiva como seleccionada por defecto
          if (filteredUnits.length > 0) {
            setSelectedProductiveUnit(filteredUnits[0].id)
          }
        } catch (error) {
          console.error('Error al obtener las unidades productivas:', error)
        }
      }
      fetchProductiveUnits()
    },
    [routeParams.farmId],
    [routeParams.productive_unit],
  )

  const filteredSummary = summary.filter(
    (item) => item.productive_unit === parseInt(selectedProductiveUnit),
  )
  const modifiedSummaryMonthly = summaryMonthly.map((item) => ({
    ...item,
    productive_unit: item.productive_unit_id,
  }))
  const filteredSummaryMonthly = modifiedSummaryMonthly.filter(
    (item) => item.productive_unit === parseInt(selectedProductiveUnit),
  )
  const [activeCattleSummaryId, setActiveCattleSummaryId] = useState(null)
  const [activeMonthlyCattleSummaryId, setActiveMonthlyCattleSummaryId] =
    useState(null)

  //REMOVE DE REGISTROS
  const handleRemoveCattleSummary = async () => {
    if (activeCattleSummaryId) {
      try {
        await CattleSumm.remove(
          routeParams.farmOwnerId,
          routeParams.farmId,
          activeCattleSummaryId,
        )
      } catch (error) {
        console.error('Error al eliminar el CattleSummary:', error)
      }
    } else {
      console.warn('No hay un activeCattleSummaryId definido')
    }
  }

  const handleRemoveMonthlyCattleSummary = async () => {
    if (activeMonthlyCattleSummaryId) {
      try {
        await monthlyCattleSumm.remove(activeMonthlyCattleSummaryId)
      } catch (error) {
        console.error('Error al eliminar el MonthlyCattleSummary:', error)
      }
    } else {
      console.warn('No hay un activeMonthlyCattleSummaryId definido')
    }
  }

  //EDIT DE REGISTROS
  async function editCattleSummary() {
    const farmId = routeParams.farmId
    const rawDailyData = [
      ...formik.values.dailyCattle,
      ...formik.values.dailyMilk,
      ...formik.values.dailyMeasurements,
      { field: 'date', value: date },
    ]
    const productiveUnitId = selectedProductiveUnit
    const data = _.map(rawDailyData, (obj) => ({ [obj.field]: obj.value }))
    const finalData = data.reduce((acc, obj) => {
      return { ...acc, ...obj }
    })
    finalData.farm_id = farmId
    finalData.date = date.toISOString()
    finalData.productive_unit_id = productiveUnitId

    try {
      await CattleSumm.edit(activeCattleSummaryId, finalData)
      enqueueSnackbar('Informe diario actualizado con éxito', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar(
        'Hubo un error al actualizar los datos, verifique los campos e intente nuevamente',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      )
    }
  }

  async function editMonthlyCattleSummary() {
    const farmId = routeParams.farmId
    const rawMonthlyData = [
      ...formik.values.livestockInventory,
      ...formik.values.productionPerformance,
      ...formik.values.healthEvents,
      { field: 'date', value: date },
    ]
    const productiveUnitId = selectedProductiveUnit
    const data = _.map(rawMonthlyData, (obj) => ({ [obj.field]: obj.value }))
    const finalData = data.reduce((acc, obj) => {
      return { ...acc, ...obj }
    })

    finalData.farm_id = farmId
    finalData.date = date.toISOString()
    finalData.productive_unit_id = productiveUnitId
    //console.log('editar', finalData)

    try {
      await monthlyCattleSumm.edit(
        farmId,
        activeMonthlyCattleSummaryId,
        finalData,
      )
      enqueueSnackbar('Informe mensual actualizado con éxito', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar(
        'Hubo un error al actualizar los datos, verifique los campos e intente nuevamenteeeeeeeeee',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      )
    }
  }

  return (
    <Paper>
      <FormControl className={classes.productiveSelector}>
        <InputLabel id='productive-unit-select-label'>
          Unidad Productiva
        </InputLabel>
        <Select
          labelId='productive-unit-select-label'
          id='productive-unit-select'
          onChange={handleProductiveUnitChange}
          value={selectedProductiveUnit}
        >
          {productiveUnits
            .reduce((uniqueUnits, unit) => {
              const isUnitExist = uniqueUnits.some(
                (u) => u.productive_unit_name === unit.productive_unit_name,
              )
              if (!isUnitExist) {
                uniqueUnits.push(unit)
              }
              return uniqueUnits
            }, [])
            .map((unit) => (
              <MenuItem key={unit.id} value={unit.id}>
                {unit.productive_unit_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <CattleSummariesSelector
        summary={filteredSummary}
        summaryMonthly={filteredSummaryMonthly}
        date={date}
        setDate={setDate}
        CattleSummariesType={CattleSummariesType}
        setCattleSummariesType={setCattleSummariesType}
        setDisabledData={setDisabledData}
      />
      {CattleSummariesType === 'diario' ? (
        <div>
          <Box margin='20px'></Box>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} lg={4}>
              <Section
                data={formik.values.dailyCattle}
                name={'Datos de rebaño'}
                objectName={'dailyCattle'}
                disabledData={disabledData}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Section
                data={formik.values.dailyMilk}
                name={'Datos de leche'}
                objectName={'dailyMilk'}
                disabledData={disabledData}
                formik={formik}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Section
                data={formik.values.dailyMeasurements}
                objectName={'dailyMeasurements'}
                name={'Medidas'}
                disabledData={disabledData}
                formik={formik}
              />
            </Grid>
          </Grid>
        </div>
      ) : (
        <Box
          //border='1px solid red'
          margin='20px'
          //margin='auto'
        >
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} lg={4}>
              <Section
                data={formik.values.livestockInventory}
                name={'Inventario ganadero'}
                objectName={'livestockInventory'}
                disabledData={disabledData}
                formik={formik}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Section
                data={formik.values.productionPerformance}
                name={'Desempeño Producción'}
                objectName={'productionPerformance'}
                disabledData={disabledData}
                formik={formik}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Section
                data={formik.values.healthEvents}
                objectName={'healthEvents'}
                name={'Eventos Sanitarios'}
                disabledData={disabledData}
                formik={formik}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box padding='20px'>
        <Divider />
        <Box paddingTop='20px' display='flex'>
          <Box paddingRight='20px'>
            <Button
              variant='outlined'
              color='primary'
              disabled={disabledData}
              onClick={() => setDisabledData(true)}
            >
              Descartar
            </Button>
          </Box>
          <Button
            variant='contained'
            color='primary'
            disabled={disabledData}
            onClick={async () => {
              if (CattleSummariesType === 'diario') {
                if (activeCattleSummaryId) {
                  await editCattleSummary()
                } else {
                  await postCattleSummary()
                }
                await refreshDailyData()
              } else {
                if (activeMonthlyCattleSummaryId) {
                  await editMonthlyCattleSummary()
                } else {
                  await postMonthlyCattleSummary()
                }
                await refreshMonthlyData()
              }
              setDisabledData(true)
            }}
          >
            Guardar
          </Button>
          <Box paddingLeft='20px'>
            <Button
              variant='contained'
              color='secondary'
              disabled={disabledData}
              onClick={async () => {
                if (CattleSummariesType === 'diario') {
                  await handleRemoveCattleSummary()
                  await refreshDailyData()
                } else {
                  await handleRemoveMonthlyCattleSummary()
                  await refreshMonthlyData()
                }
                setDisabledData(true)
              }}
            >
              Eliminar
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

function Section({ data, name, disabledData, formik, objectName }) {
  return (
    <Paper>
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='flex-start'
        p={4}
      >
        <Typography variant='h3' gutterBottom>
          {name}
        </Typography>
        <br />
        <Grid container spacing={3}>
          {data &&
            data.map((field, i) => (
              <Grid item xs={6} key={`${name}-section-${i}`}>
                <InputData
                  name={field.name}
                  formik={formik}
                  field={field.field}
                  data={data}
                  index={i}
                  disabled={disabledData}
                  objectName={objectName}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Paper>
  )
}

function InputData({ name, data, index, formik, objectName, disabled }) {
  const classes = useStyles()
  return (
    <Box display='flex' flexDirection='row' justifyContent='center' p={1}>
      <TextField
        name={`${objectName}[${index}].value`}
        label={name}
        value={data[index]?.value}
        onChange={formik.handleChange}
        disabled={disabled}
      />
    </Box>
  )
}
