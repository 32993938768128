import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import MonthPicker from '../ResultState/MonthPicker'

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

import MonthlyCategoriesTable from 'components/MonthlyCategoriesTable'
import withYearParam from 'hoc/withYearParam'

import { MonthlyResultState } from 'utils/api/models'
import { useSelector } from 'react-redux'
import { Box, CircularProgress } from '@material-ui/core'
import { es } from 'date-fns/locale'
import 'moment/locale/es';

const HeaderTableCell = withStyles((theme) => ({
  head: {
    borderBottom: '2px solid black',
    fontWeight: 900,
  },
  body: {
    fontSize: 14,
    fontWeight: 700,
  },
}))(TableCell)

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  titleTable: {
    marginLeft: '25px',
  },
  monthSelectorRoot: {
    padding: '20px',
    marginBottom: '40px',
    display: 'flex',
  },
})

function MonthlyStateResult() {
  const [rows, setRows] = useState([])
  const [months, setMonths] = useState([])

  const [csv, setCSV] = useState(null)
  const form = useFormik({
    initialValues: {
      liters: 5000,
      liter_price: 260,
      cows: 500,
      ha: 1000,
      from_month: { year: new Date().getFullYear(), month: 1 },
    },
  })
  const routeParams = useSelector((state) => state.routeParams)

  
  

  const headersCSV = [
    { label: 'Categoría', key: 'category' },
    { label: 'Enero', key: 'january' },
    { label: 'Febrero', key: 'february' },
    { label: 'Marzo', key: 'march' },
    { label: 'Abril', key: 'april' },
    { label: 'Mayo', key: 'may' },
    { label: 'Junio', key: 'june' },
    { label: 'Julio', key: 'july' },
    { label: 'Agosto', key: 'august' },
    { label: 'Septiembre', key: 'september' },
    { label: 'Octubre', key: 'october' },
    { label: 'Noviembre', key: 'novemeber' },
    { label: 'Diciembre', key: 'december' },
    { label: 'Total', key: 'total' },
  ]

  const formatToCSV = (name, element) => {
    return {
      category: name,
      total: element.total,
      january: element.totals[0],
      february: element.totals[1],
      march: element.totals[2],
      april: element.totals[3],
      may: element.totals[4],
      june: element.totals[5],
      july: element.totals[6],
      august: element.totals[7],
      september: element.totals[8],
      october: element.totals[9],
      novemeber: element.totals[10],
      december: element.totals[11],
    }
  }

  function month(from_month) {
    moment.locale('es')
    const date = moment({ year: from_month.year, month: from_month.month -1, day: 1})
    let monthArray = [date.format('MMMYY')]
    for (let i = 0; i < 11; i++) {
      console.log(i)
      monthArray.push(date.add(1, 'M').format('MMMYY'))
    }
    monthArray.push('total')
    return monthArray
  }

  function monthSum(from_month) {
    const d = moment({ year: from_month.year, month: from_month.month - 1, day: 1});
    console.log('d: ', d)
    var futureMonth = d.add(11, 'M');
    console.log('futureMonth: ', futureMonth)
    return { year: futureMonth.year(), month: futureMonth.month() + 1 }
  }

  const handleSubmit = () => {
    async function getData() {
      const { data } = await MonthlyResultState.all(
        routeParams.businessId,
        form.values,
        monthSum(form.values.from_month),
      )
      setRows(data)
      setMonths(month(form.values.from_month)) 
      console.log(data)
      
      const genCSV = []

      genCSV.push({
        category: 'Ingresos',
      })

      data[0].categories.map((row) => {
        genCSV.push(formatToCSV(row.name, row))
        row.sub_categories.map((category) => {
          genCSV.push(formatToCSV(`${row.name} - ${category.name}`, category))
        })
      })

      genCSV.push({
        category: 'Egresos',
      })

      data[1].categories.map((row) => {
        genCSV.push(formatToCSV(row.name, row))
        row.sub_categories.map((category) => {
          genCSV.push(formatToCSV(`${row.name} - ${category.name}`, category))
        })
      })
      setCSV(genCSV)
    }
    getData()

  }

  React.useEffect(handleSubmit,[routeParams])
  React.useEffect(()=>(setMonths(month(form.values.from_month))),[])

  const classes = useStyles()
  return (
    <React.Fragment>
      <Paper className={classes.monthSelectorRoot}>
        <MonthPicker from_month={form.values.from_month} from_onChange={form.handleChange} />
        <Button
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            className={classes.buttonConfirm}
          >
            Confirmar
          </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <h2 className={classes.titleTable}>Ingresos</h2>

          {csv ? (
            <CSVLink
              data={csv}
              headers={headersCSV}
              filename={'Estado Resultado.csv'}
              separator=';'
              style={{ textDecoration: 'none' }}
            >
              <Button
                color='primary'
                variant='contained'
                style={{ margin: '10px 25px' }}
              >
                Descargar CSV
              </Button>
            </CSVLink>
          ) : null}
        </Box>

        <Table
          className={classes.table}
          aria-label='a dense table'
          size='small'
        >
          <TableHead>
            <TableRow hover>
              <HeaderTableCell variant='head' />
              <HeaderTableCell variant='head'>Categoría</HeaderTableCell>
              {months.map((month) => (
                <HeaderTableCell variant='head' align='right'>
                  {month}
                </HeaderTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length != 0 ? (
              rows[0].categories.map((row) => (
                <MonthlyCategoriesTable key={row.name} segment={row} />
              ))
            ) : (
              <Box p={2}>
                <CircularProgress size={20} />
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <TableContainer component={Paper}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <h2 className={classes.titleTable}>Egresos</h2>
        </Box>

        <Table
          className={classes.table}
          aria-label='a dense table'
          size='small'
        >
          <TableHead>
            <TableRow hover>
              <HeaderTableCell variant='head' />
              <HeaderTableCell variant='head'>Categoría</HeaderTableCell>
              {months.map((month) => (
                <HeaderTableCell variant='head' align='right'>
                  {month}
                </HeaderTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length != 0 ? (
              rows[1].categories.map((row) => (
                <MonthlyCategoriesTable key={row.name} segment={row} />
              ))
            ) : (
              <Box p={2}>
                <CircularProgress size={20} />
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default MonthlyStateResult
