import { call, fork, put, takeEvery, take, select } from 'redux-saga/effects'
import { Business, Farm, FarmOwner, User } from 'utils/api/models'
import * as actions from 'actions'
import * as types from 'actions/types'
import { ensureUserLoaded } from 'sagas/user'
import { getParamFromUrl } from 'utils/common'
import _ from 'lodash'

export function* main() {
  yield fork(getUser)
  yield takeEvery(
    ['@@router/ROUTE_CHANGED', types.SET_ROUTE_PARAMS],
    getFarmOwners,
  )
  yield takeEvery(
    ['@@router/ROUTE_CHANGED', types.SET_ROUTE_PARAMS],
    getBusinesses,
  )
  yield takeEvery(['@@router/ROUTE_CHANGED', types.SET_ROUTE_PARAMS], getFarms)
  yield takeEvery(types.BREADCRUMB_RESOURCES_UPDATED, getAll)
}

function* getAll(action) {
  yield fork(getFarmOwners, action)
  yield fork(getBusinesses, action)
  yield fork(getFarms, action)
}

function* getUser() {
  while (true) {
    try {
      const { is_super_admin, farm_owner_id } = yield call(ensureUserLoaded)
      if (is_super_admin) {
        const farmOwners = yield call(FarmOwner.all)
        yield put(actions.setFarmOwners(farmOwners))
      } else {
        const farmOwner = yield call(FarmOwner.get, farm_owner_id)
        yield put(actions.setFarmOwners([farmOwner]))
      }
    } catch (e) {
      //
    }
    yield take([types.UNSET_USER, types.BREADCRUMB_RESOURCES_UPDATED])
  }
}

function* getFarmOwners({ params }) {
  if (params.userId) {
    try {
      const farmOwners = yield call(FarmOwner.all, params.userId)
      yield put(actions.setFarmOwners(farmOwners))
      yield call(autoSetRouteParams, 'farmOwnerId', farmOwners)
    } catch (e) {
      return
    }
  }
}
function* getBusinesses({ params }) {
  if (params.farmOwnerId) {
    try {
      const businesses = yield call(Business.all)
      yield put(actions.setBusinesses(businesses))
      yield call(autoSetRouteParams, 'businessId', businesses)
    } catch (e) {
      return
    }
  }
}

function* getFarms({ params }) {
  if (params.farmOwnerId) {
    try {
      const farms = yield call(Farm.all)
      yield put(actions.setFarms(farms))
      yield call(autoSetRouteParams, 'farmId', farms)
    } catch (e) {
      return
    }
  }
}

function* autoSetRouteParams(key, resources) {
  const [firstResource] = resources
  const id = yield call(getCurrentRouteParam, key)
  const found = yield call(_.find, resources, { id })
  if ((id && !found) || !id) {
    yield put({
      type: types.SET_ROUTE_PARAMS,
      params: { [key]: firstResource.id },
    })
  }
}

function* getCurrentRouteParam(key) {
  const { path, url } = yield select((state) => state)
  return Number(yield call(getParamFromUrl, path, url, ':' + key))
}
