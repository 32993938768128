import React from 'react'
import { Grid, makeStyles, Paper } from '@material-ui/core'
import withProps from 'hoc/withProps'
import { denormalize, normalize, schema } from 'normalizr'

import {
  ManagementCategory,
  ManagementTemplate,
  Task,
  TaskGroupTemplate,
  TaskResourceTemplate,
  TaskTemplate,
  Resource,
} from 'utils/api/models'

import NestedResourcesLayout from 'components/NestedResourcesLayout'
import { props } from 'lodash/fp'

import _ from 'lodash'
import withReducerState from 'hoc/withReducerState'
import useParams from 'hooks/useParams'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}))

const ManagementTemplateLayout = withProps({
  layout: [
    {
      id: 1,
      title: 'Cultivo',
      model: ManagementCategory,
      size: 2,
      listMode: 'list',
    },
    {
      id: 2,
      title: 'Tipo de cultivo',
      model: ManagementTemplate,
      size: 2,
      parent_id: 1,
      listMode: 'list',
      _get: (state, categoryId) => {
        return _.values(state.managements).filter(
          (m) => m.soil_management_category.id == categoryId,
        )
      },
    },
    {
      id: 3,
      title: 'Manejo agronómico',
      model: TaskGroupTemplate,
      size: 2,
      parent_id: 2,
      listMode: 'list',
      _get: (state, _categoryId, managementId) => {
        return state.managements[managementId].task_groups.map(
          (id) => state.task_groups[id],
        )
      },
    },
    {
      size: 6,
      //si height=half, entonces se muestra a la mitad de la altura
      elements: [
        {
          id: 4,
          title: 'Maquinarias',
          model: TaskTemplate,
          parent_id: 3,
          listMode: 'table',
          height: 'half',
          _get: (state, _categoryId, _managementId, taskGroupId) => {
            return state.task_groups[taskGroupId].tasks.map(
              (id) => state.tasks[id],
            )
          },
        },
        {
          id: 5,
          title: 'Insumos',
          model: TaskResourceTemplate,
          parent_id: 4,
          listMode: 'table',
          height: 'half',
          _get: (state, _categoryId, _managementId, _taskGroupId, taskId) => {
            return _.values(state.tasks).filter((element) => {
              return element.id == taskId
            })[0].task_soil_resources
          },
        },
      ],
    },
  ],
})(NestedResourcesLayout)

const taskProcessStrategy = (value, parent) => ({
  ...value,
  task_group_id: parent.id,
})

const taskGroupProcessStrategy = (value, parent) => ({
  ...value,
  management_id: parent.id,
})

const taskSchema = new schema.Entity(
  'tasks',
  {},
  { processStrategy: taskProcessStrategy },
)
const taskGroupSchema = new schema.Entity(
  'task_groups',
  {
    tasks: [taskSchema],
  },
  { processStrategy: taskGroupProcessStrategy },
)
const managementSchema = new schema.Entity('managements', {
  task_groups: [taskGroupSchema],
})

export const managementReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA': {
      const normalized = normalize(action.data, [managementSchema])
      return {
        ...state,
        ...normalized.entities,
      }
    }
    default:
      return state
  }
}

export const denormalizeManagement = (management_id, entities) =>
  denormalize([management_id], [managementSchema], entities)

export const managementInitialState = {
  managements: [],
  tasks: [],
  task_groups: [],
}

const ManagementTemplateLayoutWithState = withReducerState(
  ManagementTemplate,
  managementReducer,
  managementInitialState,
)(ManagementTemplateLayout)

const ManagementTemplates = (_props) => {
  const classes = useStyles()
  const templateMeadowId = useSelector(
    ({ routeParams: { templateMeadowId } }) => templateMeadowId,
  )
  return (
    <Paper className={classes.container}>
      <Grid container>
        <ManagementTemplateLayoutWithState key={templateMeadowId} />
      </Grid>
    </Paper>
  )
}

export default ManagementTemplates
