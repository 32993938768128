import { ResponsiveStream } from '@nivo/stream'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
import _ from 'lodash'

const siembras = {
  0: 'Soiling de alfalfa',
  1: 'Raps',
  2: 'Triticale inv pastoreo',
}
function CowFoodGraph({ data }) {
  const horizonCount = data.Siembra?.[0].length
  const series = _.range(0, horizonCount).map((m) => {
    let month = {}
    _.each(
      Object.keys(data.Siembra),
      (s) => (month[siembras[s]] = data.Siembra[s][m]),
    )
    return month
  })
  return (
    <ResponsiveStream
      data={series}
      keys={Object.values(siembras)}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: 36,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: -40,
      }}
      curve='linear'
      offsetType='diverging'
      colors={['#305600', '#5FAA00', '#9CC26A', '#F9A825', '#FF7A00']}
      borderColor={{ theme: 'background' }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#2c998f',
          size: 4,
          padding: 2,
          stagger: true,
        },
        {
          id: 'squares',
          type: 'patternSquares',
          background: 'inherit',
          color: '#e4c912',
          size: 6,
          padding: 2,
          stagger: true,
        },
      ]}
      dotSize={8}
      dotColor={{ from: 'color' }}
      dotBorderWidth={2}
      dotBorderColor={{ from: 'color', modifiers: [['darker', 0.7]] }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          translateX: 100,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: '#999999',
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000000',
              },
            },
          ],
        },
      ]}
    />
  )
}

export default CowFoodGraph
