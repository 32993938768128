import { getDocuments } from 'utils/api/models/Document'

export const all = (
  date_start,
  date_end,
  page_number,
  page_size,
  category_id,
  sub_category_id,
  folio,
  rs,
) =>
  getDocuments(
    'income',
    date_start,
    date_end,
    page_number,
    page_size,
    category_id,
    sub_category_id,
    folio,
    rs,
  )
