import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_BASE = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/meadows/`

export const allInMeadows = (
  meadow_ids,
  start_date,
  end_date,
  machinery_category_id,
  management_category_id,
) =>
  axios
    .get(`${RESOURCE_BASE}soil_management/managements`, {
      params: {
        meadow_ids,
        start_date,
        end_date,
        machinery_category_id,
        management_category_id,
      },
    })
    .then(dataGetter)

export const all = (meadowId, start_date, end_date, machinery_category_id) =>
  axios
    .get(`${RESOURCE_BASE}${meadowId}/soil_management/managements`, {
      params: { start_date, end_date, machinery_category_id },
    })
    .then(dataGetter)
    .then((meadows) => meadows.map((m) => ({ ...m, meadow_id: meadowId })))

export const create = (meadowId, managements) =>
  axios
    .post(
      `${RESOURCE_BASE}${meadowId}/soil_management/managements`,
      managements,
    )
    .then(dataGetter)

export const createBatch = (meadowIds, management) => {
  return axios
    .post(
      `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/soil_management_batch`,
      { management, meadow_ids: meadowIds },
    )
    .then(dataGetter)
}
