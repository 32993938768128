import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import BackgroundLogin from '../../images/CAP_Farm_72.jpeg'
import LogoLogin from '../../images/logo-isotipo.svg'
import * as api from 'utils/api'
import * as actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles({
  containerLogin: {
    backgroundColor: 'white',
    backgroundImage: `url(${BackgroundLogin})`,
    width: '100%',
    minHeight: '500px',
    height: '100vh',
    overflow: 'hidden',
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  sectionTwo: {
    width: '5000px' /* 500 */,
    float: 'right',
    height: '100%',
    background: '#fff',
    transform: 'skew(-17.07deg)',
    position: 'relative',
    zIndex: 1,
    transition: 'width 0.5s',
    right: '-4615px' /* 115 */,
    boxShadow: '0px 0px 25px -4px rgba(0,0,0,0.67)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    '@media (max-width:360px)': {
      // eslint-disable-line no-useless-computed-key
      transform: 'skew(0deg)',
      right: 0,
      width: '100%',
    },
  },
  content: {
    transform: 'skew(17.07deg)',

    width: '350px',

    color: '#000',
    textAlign: 'center',
    paddingLeft: '25px',

    '@media (max-width:360px)': {
      width: '80%',
      transform: 'skew(0deg)',
    },
  },
  LogoLogin: {
    width: '200px',
    '@media (max-width:360px)': {
      width: '80%',
    },
  },
  input: {
    width: '100%',
  },
  root: {
    width: '280px',
    margin: 'auto',
  },
  button: {
    marginTop: '20px',
  },
})

export default function Login() {
  const classes = useStyles()
  const [auth, setAuth] = React.useState({ username: '', password: '' })
  const [error, setError] = React.useState(false)
  const isLoading = useSelector((state) => state.network.isLoading)

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const dispatch = useDispatch()
  const doLogin = async () => {
    try {
      setError(false)
      const { token } = await api.getToken(auth)
      dispatch(actions.setToken(token))
    } catch (e) {
      setError(true)
    }
  }

  return (
    <>
      <div className={classes.containerLogin}>
        <div className={classes.sectionTwo}>
          <div className={classes.content}>
            <img alt='logo' src={LogoLogin} className={classes.LogoLogin} />
            <br /> <br />
            <form className={classes.root} noValidate autoComplete='off'>
              <TextField
                id='outlined-basic'
                label='Usuario'
                variant='outlined'
                className={classes.input}
                error={error}
                value={auth.username}
                onChange={(e) =>
                  setAuth((state) => ({ ...state, username: e.target.value }))
                }
              />
              <br /> <br />
              <TextField
                id='outlined-basic'
                label='Contraseña'
                variant='outlined'
                className={classes.input}
                type={showPassword ? 'text' : 'password'}
                error={error}
                helperText={error && 'Usuario y/o contraseña incorrecto(s)'}
                value={auth.password}
                onChange={(e) =>
                  setAuth((state) => ({ ...state, password: e.target.value }))
                }
                //onKeyPress={(e) => e.key === 'Enter' && isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type='submit'
                variant='contained'
                className={classes.button}
                color='primary'
                size='large'
                onClick={doLogin}
                disabled={isLoading}
              >
                Iniciar Sesión
              </Button>
            </form>
            <p>
              Olvidaste tu <strong>contraseña </strong>?
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
