import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/soil_management/management_category`


export const fields = [{ name: 'Nombre', field: 'name' }]

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const byId = (farms, startDate, endDate) => axios.get(`${BASE_URL}/analytics/categories`,{ 
  params: {
      farms,
      startDate,
      endDate,
      //managementCategories: selectedManagement,
  }}).then(dataGetter)

export const remove = (category_id) =>
  axios.delete(`${RESOURCE_LOCATION}/${category_id}`).then(dataGetter)

export const create = (management_category) =>
  axios.post(RESOURCE_LOCATION, { management_category }).then(dataGetter)

export const edit = (category_id, management_category) =>
  axios
    .put(`${RESOURCE_LOCATION}/${category_id}`, { management_category })
    .then(dataGetter)


 
 