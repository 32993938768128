import React, { useCallback, useRef, useEffect, useLayoutEffect } from 'react'
import { Box, Grid, Paper, makeStyles, ListItem } from '@material-ui/core'
import {
  parseJSON,
  toDate,
  format,
  parse,
  startOfYear,
  sub,
  add,
  endOfYear,
  formatDistance,
  eachDayOfInterval,
} from 'date-fns'
import _, { concat } from 'lodash'
import { normalize, schema } from 'normalizr'

import { Management, Meadow } from 'utils/api/models'
import TasksCalendar from 'components/TasksCalendar'
import CalendarNavigator from 'components/CalendarNavigator'
import TaskGroupDetail from 'components/TaskGroupDetail'
import withYearParam from 'hoc/withYearParam'
import withOwnerFarmParam from 'hoc/withOwnerFarmParam'
import { useSelector } from 'react-redux'
import ResourcesAsPolygons from 'components/ResourcesAsPolygons'
import MeadowPolygon from 'components/MeadowPolygon'
import MachineryCategorySelector from 'components/MachineryCategorySelector'
import { columnsTotalWidthSelector } from '@material-ui/data-grid'
import PdfExporter from 'components/PdfExporter'
import ManagementCategorySelector from 'components/ManagementCategorySelector'

const useStyles = makeStyles(() => ({
  responsiveCalendarMap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    height: '200px',
  },
  fullWidth: {
    width: '100%',
  },
}))

const taskProcessStrategy = (value, parent) => ({
  ...value,
  task_group_id: parent.id,
})
const taskGroupProcessStrategy = (value, parent) => ({
  ...value,
  management_id: parent.id,
})

const managementReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MANAGEMENTS': {
      const taskSchema = new schema.Entity(
        'tasks',
        {},
        { processStrategy: taskProcessStrategy },
      )
      const taskGroupSchema = new schema.Entity(
        'task_groups',
        {
          tasks: [taskSchema],
        },
        { processStrategy: taskGroupProcessStrategy },
      )
      const managementSchema = new schema.Entity('managements', {
        task_groups: [taskGroupSchema],
      })
      const normalized = normalize(action.managements, [managementSchema])

      const tasksByDay = _(normalized.entities.tasks)
        .groupBy((t) =>
          format(toDate(parseJSON(t.suggested_date)), 'dd/MM/yyyy'),
        )
        .value()
      const machineriesByDay = _(tasksByDay)
        .mapValues((tasks, _day) => _.groupBy(tasks, 'machinery.name'))
        .value()

      const taskGroupsByDay = _(tasksByDay)
        .mapValues((ts) =>
          _(ts)
            .groupBy('task_group_id')
            .values()
            .map(_.first)
            .map((t) => normalized.entities.task_groups[t.task_group_id])
            .value(),
        )
        .value()

      const taskFrequencyByDay = _(tasksByDay)
        .toPairs()
        .map(([day, tasks]) => [
          parse(day, 'dd/MM/yyyy', new Date()),
          tasks.length,
        ])
        .value()
      // date fns (interval)

      const meadowsColorByDay = _(normalized.entities.managements).reduce(
        (
          acc,
          {
            start_date: start,
            end_date: end,
            soil_management_category,
            meadow_id,
          },
        ) =>
          _(
            eachDayOfInterval({
              start: new Date(start),
              end: new Date(end),
            }),
          )
            .map((d) => [
              format(d, 'dd/MM/yyyy'),
              { [meadow_id]: soil_management_category },
            ])
            .fromPairs()
            .merge(acc)
            .value(),
        {},
      )

      const task_group = state.task_group?.id
        ? (normalized.entities.task_groups &&
            normalized.entities.task_groups[state.task_group.id]) ||
          {}
        : {}

      return {
        ...state,
        ...normalized.entities,
        task_group,
        tasksByDay,
        taskGroupsByDay,
        taskFrequencyByDay,
        machineriesByDay,
        meadowsColorByDay,
      }
    }

    case 'SET_MEADOWS': {
      const meadowSchema = new schema.Entity('meadows')
      const normalized = normalize(action.meadows, [meadowSchema])
      return {
        ...state,
        ...normalized.entities,
        active_meadows: _(normalized.entities.meadows)
          .map('id')
          .keyBy()
          .mapValues(() => true)
          .value(),
      }
    }

    case 'SET_MEADOW': {
      const newMeadows = _.pick(state.meadows, action.id)
      return { ...state, meadow: newMeadows }
    }

    case 'TOGGLE_MEADOW': {
      return {
        ...state,
        active_meadows: state.active_meadows[action.id]
          ? _.omit(state.active_meadows, [action.id])
          : { ...state.active_meadows, [action.id]: true },
      }
    }

    case 'SET_ACTIVE_MEADOW': {
      return { ...state, active_meadows: { [action.id]: true } }
    }

    case 'SET_ACTIVE_MEADOWS': {
      return {
        ...state,
        active_meadows: _(action.ids)
          .keyBy((id) => id)
          .mapValues(() => true)
          .value(),
      }
    }

    case 'SET_TASK_GROUP': {
      const task_group = state.task_groups[action.taskGroupId] || {}
      return { ...state, task_group }
    }

    case 'SET_TASK': {
      const task_id = action.taskId
      return { ...state, task_id }
    }

    case 'TOGGLE_TASKS': {
      const selected_tasks = {
        ...state.selected_tasks,
        ..._(action.ids)
          .keyBy((id) => id)
          .mapValues((_v, k) => (state.selected_tasks[k] ? undefined : true))
          .value(),
      }
      Object.keys(selected_tasks).forEach(
        (key) =>
          selected_tasks[key] === undefined && delete selected_tasks[key],
      )
      return {
        ...state,
        selected_tasks: selected_tasks,
      }
    }

    case 'CLEAR_SELECTED_TASKS': {
      return {
        ...state,
        selected_tasks: {},
      }
    }

    default:
      return state
  }
}

function ManagementCalendar() {
  const { year, userId, farmOwnerId, farmId } = useSelector(
    (state) => state.routeParams,
  )
  const [date, setDate] = React.useState(
    new Date(
      (year && new Date().setFullYear(year)) ||
        new Date().setFullYear(new Date().getFullYear()),
    ),
  )
  const [machineryCategoryId, setMachineryCategoryId] = React.useState(
    undefined,
  )
  const [managementCategoryId, setManagementCategoryId] = React.useState(
    undefined,
  )
  const [state, dispatch] = React.useReducer(managementReducer, {
    managements: {},
    task_id: {},
    tasks: {},
    task_groups: {},
    tasksByDay: [],
    machineriesByDay: [],
    taskGroupsByDay: [],
    meadows: {},
    meadow: {},
    active_meadows: {},
    task_group: {},
    taskFrequencyByDay: [],
    selected_tasks: {},
    meadowsColorByDay: {},
  })
  const classes = useStyles()

  const meadowsArr = React.useMemo(() => _(state.meadows).values().value(), [
    state.meadows,
  ])

  const yearSelected = date.getFullYear()

  React.useEffect(() => dispatch({ type: 'SET_ACTIVE_MEADOWS', ids: [] }), [
    userId,
    farmOwnerId,
    farmId,
  ])

  const syncDate = () => {
    if (year && yearSelected !== year) {
      setDate(new Date(new Date().setFullYear(year)))
    }
  }
  React.useEffect(syncDate, [year, yearSelected])

  const getMeadows = () => {
    async function getData() {
      const meadows = await Meadow.all()
      dispatch({ type: 'SET_MEADOWS', meadows })
    }
    getData()
  }
  React.useEffect(getMeadows, [userId, farmOwnerId, farmId])

  const getManagements = () => {
    async function getData() {
      const meadowIds = _(state.active_meadows).keys().value()

      if (meadowIds.length) {
        const managements = await Management.allInMeadows(
          meadowIds,
          sub(startOfYear(date), { weeks: 2 }),
          add(endOfYear(date), { weeks: 2 }),
          machineryCategoryId,
          managementCategoryId,
        )

        dispatch({
          type: 'SET_MANAGEMENTS',
          managements,
        })
      } else {
        dispatch({ type: 'SET_MANAGEMENTS', managements: [] })
      }
    }
    getData()
  }

  React.useEffect(getManagements, [
    yearSelected,
    state.active_meadows,
    machineryCategoryId,
    managementCategoryId,
  ])

  const [disablePdf, setDisablePdf] = React.useState(true)

  React.useEffect(() => {
    if (
      machineryCategoryId !== undefined &&
      managementCategoryId !== undefined
    ) {
      setDisablePdf(false)
    } else {
      setDisablePdf(true)
    }
  }, [machineryCategoryId, managementCategoryId])

  //console.log(machineryCategoryId, managementCategoryId)

  const management =
    state.task_group.id && state.managements[state.task_group.management_id]

  const resourcesColor = state.meadowsColorByDay[format(date, 'dd/MM/yyyy')]

  //console.log('colors:', resourcesColor)

  //console.log(date)

  return (
    <React.Fragment>
      <PdfExporter
        date={date}
        activePdf={disablePdf}
        state={state}
        //getMap={setActive}
        //mapImage={mapImage}
        resources={meadowsArr}
        resourcesColor={resourcesColor}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} direction='column'>
          <Box pb={1}>
            <Paper>
              <Box flex={0.5} p={1} display='flex' flexDirection='row'>
                <MachineryCategorySelector setValue={setMachineryCategoryId} />
                <ManagementCategorySelector
                  setValue={setManagementCategoryId}
                />
              </Box>
            </Paper>
          </Box>
          <Box pb={1}>
            <Paper className={classes.fullWidth}>
              <Box p={1} justifyContent='center'>
                <CalendarNavigator
                  date={date}
                  setDate={setDate}
                  taskFrequencyByDay={state.taskFrequencyByDay}
                />
              </Box>
            </Paper>
          </Box>
          <Paper className={classes.fullWidth}>
            <Box p={1}>
              <TasksCalendar
                date={date}
                refreshData={getManagements}
                setDate={setDate}
                {...state}
                setTaskGroupId={(id) =>
                  dispatch({ type: 'SET_TASK_GROUP', taskGroupId: id })
                }
                setTaskId={(id) => dispatch({ type: 'SET_TASK', taskId: id })}
                toggleTasks={(ids) => dispatch({ type: 'TOGGLE_TASKS', ids })}
                clearTasks={() => dispatch({ type: 'CLEAR_SELECTED_TASKS' })}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} direction='column'>
          <Box pb={1}>
            <Paper>
              <Box p={1}>
                <ResourcesAsPolygons
                  resources={meadowsArr}
                  resourcesColor={resourcesColor}
                  active_meadows={state.active_meadows}
                  Resource={MeadowPolygon}
                  model={Meadow}
                  togglePolygon={(id) =>
                    dispatch({ type: 'TOGGLE_MEADOW', id })
                  }
                  setActivePolygon={(id) =>
                    dispatch({ type: 'SET_ACTIVE_MEADOW', id })
                  }
                  setActivePolygons={(ids) =>
                    dispatch({ type: 'SET_ACTIVE_MEADOWS', ids })
                  }
                  multiSelect
                  allowCreation={false}
                />
              </Box>
            </Paper>
          </Box>
          <Paper className={classes.fullWidth}>
            <Box p={1}>
              {management && (
                <TaskGroupDetail
                  refreshData={getManagements}
                  management={management}
                  {...state}
                />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ManagementCalendar
