import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import DeleteIcon from '@material-ui/icons/Delete'
import { Form, useFormik } from 'formik'
import axios from 'axios'

import {
  Card,
  CardActions,
  Grid,
  CardContent,
  Typography,
  Button,
  TextField,
  IconButton,
  Modal,
  Avatar,
  Paper,
  Tooltip,
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { ItemDetail } from 'utils/api/models'
import { useSnackbar } from 'notistack'

import clsx from 'clsx'

import _ from 'lodash'

function getModalStyle() {
  const top = 300
  const left = 210

  return {
    top: '50%',
    left: '50%',
    transform: `translate(-${top}px, -${left}px)`,
  }
}

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#bb9832',
    color: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    textAlign: 'center',
  },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    /*   border: '2px solid #000', */
    boxShadow: theme.shadows[5],

    outline: 'none',
    padding: '10px',
  },
  avatar: {
    height: '28px',
    width: '28px',
    fontSize: '.8em',
  },
  yellowTheme: {
    color: '#fff',
    backgroundColor: '#bb9832',
  },
  greenTheme: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  textField: {
    width: '100%',
    backgroundColor: 'white',
    marginBottom: '10px',
  },
}))

export const filterOptions = createFilterOptions({
  stringify: (option) =>
    `${option.sub_category} ${option.category} ${
      option.parent && option.parent
    }`,
})

export const renderCategoryLabel = (option) => {
  return `${option.parent ? `${option.parent} - ` : ''}${option.category} - ${
    option.sub_category
  } `
}

export const findCategoryOption = (categories, category, sub_category) =>
  _(categories).find(
    (c) => c.category_id == category && c.sub_category_id == sub_category,
  )

function CostCenterAssignmentModal({ categories, model, ...props }) {
  const classes = useStyles()

  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    ItemDetail._delete(
      props.documentId,
      props.item.id,
      props.costCenter.id_element,
    )
      .then(function (response) {
        props.onDelete(props.costCenter.id_element)
        enqueueSnackbar('Se ha eliminado el centro de costo', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      })
      .then(handleClose)
      .catch(function (error) {
        enqueueSnackbar('Error al eliminar el centro de costo', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      })
  }

  const form = useFormik({
    initialValues: {
      item_fraction: props.edit ? props.assignedValue : props.availableAmount,
      /* category: 0,
      sub_category: 0, */
      category_data: props.edit
        ? {
            category_id: props.costCenter.id_category,
            sub_category_id: props.costCenter.id_subcategory,
          }
        : { category_id: 0, sub_category_id: 0 },
    },
    onSubmit: (values) => {
      if (props.edit) {
        let item_detail = {}

        if (
          props.costCenter.id_category != values.category_data.category_id ||
          props.costCenter.id_subcategory !=
            values.category_data.sub_category_id
        ) {
          /* cambia categoria o subcategoria*/
          item_detail.category = values.category_data.category_id
          item_detail.sub_category = values.category_data.sub_category_id
        }
        if (props.costCenter.item_fraction != values.item_fraction) {
          /* cambia item_fraction */
          item_detail.item_fraction = values.item_fraction
        }

        ItemDetail.put(
          props.documentId,
          props.item.id,
          props.costCenter.id_element,
          item_detail,
        )
          .then((data) => {
            props.onEdit(props.costCenter.id_element, data)
            enqueueSnackbar('Editado el centro de costo', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            })

            handleClose()
          })
          .catch(function (error) {
            enqueueSnackbar('Error al editar el centro de costo', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            })
          })
      } else {
        ItemDetail.post(props.documentId, props.item.id, {
          category: values.category_data.category_id,
          sub_category: values.category_data.sub_category_id,
          item_fraction: values.item_fraction,
        })
          .then(props.onSuccess)
          .then(() =>
            enqueueSnackbar('Se ha asignado el centro de costo', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            }),
          )
          .then(handleClose)

          .catch(function (error) {
            console.log(error)
            if (
              error.response.data.errors.sub_category[0] ==
              'sub-category is already assigned'
            ) {
              enqueueSnackbar(
                'Error, categoria ya asignada en centro de costo',
                {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                },
              )
            } else {
              enqueueSnackbar('Error al asignar el centro de costo', {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              })
            }
          })
      }

      //Esto va a depender si es añadir o editar
    },
  })

  return (
    <React.Fragment>
      {props.edit ? (
        <HtmlTooltip
          title={
            <React.Fragment>
              <em> {props.costCenter.name_category} </em>
              <Typography color='inherit'>
                {props.costCenter.name_subcategory}
              </Typography>
              <span>
                {/*  categoria-
                {props.costCenter.id_category} - subcategoria -
                {props.costCenter.id_subcategory} -  */}
                Monto Asignado: ${props.costCenter.item_fraction}
              </span>
            </React.Fragment>
          }
        >
          <Avatar
            onClick={handleOpen}
            className={clsx(classes.avatar, classes.yellowTheme)}
          >
            {props.label}
          </Avatar>
        </HtmlTooltip>
      ) : (
        <Avatar
          onClick={handleOpen}
          className={clsx(classes.avatar, classes.greenTheme)}
        >
          {props.label}
        </Avatar>
      )}
      {/*   </HtmlTooltip> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <Card className={clsx(classes.root, classes.paper)} style={modalStyle}>
          <CardContent>
            <Typography
              variant='subtitle1'
              gutterBottom
              style={{ fontStyle: 'italic' }}
            >
              {_.startCase(_.toLower(props.rznSoc))}
            </Typography>
            <h2 style={{ marginTop: '0px' }}>
              {_.startCase(_.toLower(props.item.name))}
            </h2>

            <table style={{ width: '200px' }}>
              <tr>
                <td style={{ fontStyle: 'italic' }}>Cantidad: </td>
                <td>{props.item.quantity.toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ fontStyle: 'italic' }}>Precio unitario:</td>
                <td>{props.item.unit_value.toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ fontStyle: 'italic' }}>Total: </td>
                <td>{props.item.total.toLocaleString()}</td>
              </tr>

              <tr>
                <td style={{ fontStyle: 'italic' }}> Monto Disponible CC: </td>
                <td>
                  {props.edit
                    ? (
                        props.availableAmount + props.assignedValue
                      ).toLocaleString()
                    : props.availableAmount.toLocaleString()}
                </td>
              </tr>

              {props.edit ? (
                <tr>
                  <td style={{ fontStyle: 'italic' }}>Monto asignado: </td>
                  <td>{props.assignedValue}</td>
                </tr>
              ) : (
                <> </>
              )}
            </table>

            <div style={{ padding: '10px 0px 0px 0px' }}></div>

            <form onSubmit={form.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Autocomplete
                    id='id_categorie'
                    options={categories}
                    renderOption={(option) => option.sub_category}
                    onChange={(event, value) => {
                      form.setFieldValue('category_data', value)
                    }}
                    groupBy={(option) =>
                      `${option.parent ? `${option.parent} - ` : ''}${
                        option.category
                      }`
                    }
                    getOptionLabel={(option) => {
                      if (option.category) {
                        return renderCategoryLabel(option)
                      } else {
                        const category = findCategoryOption(
                          categories,
                          option.category_id,
                          option.sub_category_id,
                        )
                        return category ? renderCategoryLabel(category) : ''
                      }
                    }}
                    value={{
                      ...form.values.category_data,
                    }}
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Centro de Costo'
                        variant='filled'
                        size='small'
                        className={classes.textField}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id='item_fraction'
                    name='item_fraction'
                    label='Valor asignado'
                    variant='filled'
                    type='number'
                    size='small'
                    InputProps={
                      props.edit
                        ? {
                            inputProps: {
                              min: 0,
                              max: props.availableAmount + props.assignedValue,
                            },
                          }
                        : {
                            inputProps: { min: 0, max: props.availableAmount },
                          }
                    }
                    className={classes.textField}
                    onChange={form.handleChange}
                    value={form.values.item_fraction}
                  />
                </Grid>

                <Grid item xs={6}></Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Button variant='contained' color='secondary' type='submit'>
                    Confirmar
                  </Button>

                  {props.edit ? (
                    <IconButton
                      color='primary'
                      aria-label='delete cost Center'
                      component='span'
                      style={{ color: 'white' }}
                      onClick={handleDelete}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <> </>
                  )}
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Modal>
    </React.Fragment>
  )
}
export default CostCenterAssignmentModal
