import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'

import clsx from 'clsx'
import _ from 'lodash'

import CostCenterAssignmentModal from 'components/CostCenterAssignmentModal'

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '28px',
    width: '28px',
    fontSize: '.8em',
  },
  yellowTheme: {
    color: '#fff',
    backgroundColor: '#bb9832',
  },
  blackTheme: {
    color: '#fff',
    backgroundColor: '#000',
  },
}))

function CostCenterItemTableCell({ categories, ...props }) {
  const [costCenter, setCostCenter] = React.useState([])
  const [availableAmount, setAvailableAmount] = React.useState([0])

  const classes = useStyles()

  const getFirstLetter = (text) => {
    var matches = text.match(/\b(\w)/g)
    var acronym = matches.join('').toUpperCase().substring(0, 3)
    return acronym
  }

  const searchNameCategorySubcategory = (id_category, id_subcategory) => {
    let value = _.find(categories, {
      category_id: id_category,
      sub_category_id: id_subcategory,
    })
    if (value) {
      return { name: value.category, subname: value.sub_category }
    }

    return { name: 'cargando', subname: 'cargando' }
  }

  const getAvailableAmount = (costCenters, totalAmount) => {
    const sumAssigned = costCenters.reduce(
      (sum, { item_fraction }) => sum + item_fraction,
      0,
    )
    return totalAmount - sumAssigned
  }

  const processData = () => {
    let data = props.data
    let arrayCategories = []

    data.details.map((category_element) => {
      const names = searchNameCategorySubcategory(
        category_element.category,
        category_element.sub_category,
      )

      arrayCategories.push({
        id_category: category_element.category,
        name_category: names.name,
        id_subcategory: category_element.sub_category,
        name_subcategory: names.subname,
        item_fraction: category_element.item_fraction,
        id_element: category_element.id,
      })
    })

    setCostCenter(arrayCategories)

    setAvailableAmount(getAvailableAmount(arrayCategories, props.data.total))
  }

  React.useEffect(() => {
    processData()
  }, [categories, props.data.details])

  return (
    <React.Fragment>
      {costCenter.length != 0 ? (
        costCenter.map((element) => (
          <>
            <CostCenterAssignmentModal
              item={props.data}
              costCenter={element}
              rznSoc={props.rznSoc}
              documentId={props.documentId}
              assignedValue={element.item_fraction}
              edit={true}
              label={getFirstLetter(element.name_subcategory)}
              onDelete={props.onDelete}
              categories={categories}
              availableAmount={availableAmount}
              {...props}
            />
          </>
        ))
      ) : (
        <Avatar className={clsx(classes.avatar, classes.blackTheme)}>?</Avatar>
      )}

      {availableAmount != 0 ? (
        <CostCenterAssignmentModal
          item={props.data}
          /* costCenter={element} */
          rznSoc={props.rznSoc}
          documentId={props.documentId}
          /* assignedValue={element.item_fraction} */
          edit={false}
          label={'+'}
          onSuccess={props.onSuccess}
          categories={categories}
          availableAmount={availableAmount}
          {...props}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  )
}

export default CostCenterItemTableCell
