import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import clsx from 'clsx'
import _ from 'lodash'
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#BB9832',
    color: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: 'unset',
    textAlign: 'center',
  },
}))(Tooltip)
const useStyles = makeStyles(() => ({
  avatar: {
    height: '28px',
    width: '28px',
    fontSize: '.8em',
  },
  yellowTheme: {
    color: '#fff',
    backgroundColor: '#BB9832',
  },
  blackTheme: {
    color: '#fff',
    backgroundColor: '#000',
  },
}))
function CostCenterTableCell({ categories, ...props }) {
  const [costCenter, setCostCenter] = React.useState([])
  const classes = useStyles()
  const getFirstLetter = (text) => {
    var matches = text.match(/\b(\w)/g)
    var acronym = matches.join('').toUpperCase().substring(0, 3)
    return acronym
  }
  const searchNameCategorySubcategory = (id_category, id_subcategory) => {
    let value = _.find(categories, {
      category_id: id_category,
      sub_category_id: id_subcategory,
    })
    if (value) {
      return { name: value.category, subname: value.sub_category }
    }
    return { name: 'cargando', subname: 'cargando' }
  }
  const processData = () => {
    let data = props.data
    let arrayCategories = []
    data.map((item) => {
      item.details.length != 0
        ? item.details.map((category_element) => {
            const names = searchNameCategorySubcategory(
              category_element.category,
              category_element.sub_category,
            )
            arrayCategories.push({
              id_category: category_element.category,
              name_category: names.name,
              id_subcategory: category_element.sub_category,
              name_subcategory: names.subname,
            })
          })
        : arrayCategories.push({
          id_category: 666,
          name_category: 'Sin asignar',
          name_subcategory: 'Sin asignar',
        })
    })
    arrayCategories = _.uniqWith(arrayCategories, _.isEqual)
    setCostCenter(arrayCategories)
  }
  React.useEffect(() => {
    processData()
  }, [categories, props.data])
  return (
    <React.Fragment>
      {costCenter.map((element) =>
        element.id_category != 666 ? (
          <HtmlTooltip
            title={
              <React.Fragment>
                <em>{element.name_category} </em>
                <Typography color='inherit'>
                  {element.name_subcategory}
                </Typography>
              </React.Fragment>
            }
          >
            <Avatar className={clsx(classes.avatar, classes.yellowTheme)}>
              {getFirstLetter(element.name_subcategory)}
            </Avatar>
          </HtmlTooltip>
        ) : (
          <HtmlTooltip
            title={
              <React.Fragment>
                <em>No se ha asignado</em>
              </React.Fragment>
            }
          >
            <Avatar className={clsx(classes.avatar, classes.blackTheme)}>
              ?
            </Avatar>
          </HtmlTooltip>
        ),
      )}
    </React.Fragment>
  )
}
export default CostCenterTableCell
