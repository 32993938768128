import React from 'react'
import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
import ResourcesAsTable from 'components/ResourcesAsTable'
import { Box, Typography } from '@material-ui/core'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { getCheckDigit } from 'rut.js'
import { formatISO } from 'date-fns'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/:businessId/documents/`

function ItemsTable({ field, setFieldValue }) {
  const [items, setItems] = React.useState([])
  const model = {
    fields: [
      { name: 'Nombre', field: 'name' },
      { name: 'Cantidad', field: 'quantity', type: 'number' },
      { name: 'Precio unitario', field: 'unit_price', type: 'currency' },
      {
        name: 'Monto neto ($)',
        field: 'total',
        type: 'currency',
        suggestedValue: (_, { unit_price, quantity }) =>
          unit_price && quantity && unit_price * quantity,
      },
    ],
    all: items,
    create: (item) => {
      const _item = { id: uuid(), ...item }
      setItems((items) => [...items, _item])
      return _item
    },
    edit: (id, item) => {
      const _item = { ..._.find(items, { id }), ...item }
      setItems((items) => [..._.reject(items, { id }), _item])
      return _item
    },
    remove: (id) => setItems((items) => _.reject(items, { id })),
  }

  React.useEffect(() => {
    setFieldValue(field.field, items)
  }, [items, setFieldValue, field.field])

  return (
    <Box py={1}>
      <Typography variant='h2'>Detalle</Typography>
      <Box py={1}>
        <ResourcesAsTable
          resources={items}
          parentSelections={[]}
          setSelected={() => null}
          refreshData={() => null}
          model={model}
          noContainer
          title='Item'
        />
      </Box>
    </Box>
  )
}
export const fields = [
  { name: 'RUT Empresa (ej. 77000111-k)', field: 'business_rut' },
  { name: 'Razón social', field: 'business_name' },
  { name: 'Fecha', field: 'date', type: 'date' },
  { name: 'IVA (ej. 19)', field: 'iva_rate', suggestedValue: () => 19 },
  {
    name: 'Detalle',
    field: 'items',
    Component: ItemsTable,
  },
]

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const create = (doc) => {
  const completeRut = `${doc.business.business_rut}-${getCheckDigit(
    String(doc.business.business_rut),
  )}`
  const emitter = doc.isEmitter
    ? { RUTEmisor: completeRut, RznSoc: doc.business.name }
    : { RUTEmisor: doc.business_rut, RznSoc: doc.business_name }
  const receiver = doc.isEmitter
    ? { RUTRecep: doc.business_rut, RznSocRecep: doc.business_name }
    : { RUTRecep: completeRut, RznSocRecep: doc.business.name }
  const content = {
    SetDTE: {
      DTE: {
        Documento: {
          ID: uuid(),
          Encabezado: {
            IdDoc: {
              TipoDTE: 'manual',
              FchEmis: formatISO(doc.date, { representation: 'date' }),
              Folio: _(uuid()).split('-').value()[0],
            },
            Emisor: emitter,
            Receptor: receiver,
            Totales: {
              MntTotal: String(
                Math.round(
                  _.sumBy(doc.items, 'total') * (1 + doc.iva_rate / 100),
                ),
              ),
              TasaIVA: String(doc.iva_rate),
            },
          },
          Detalle: _(doc.items).map((i) => ({
            QtyItem: String(i.quantity),
            PrcItem: String(i.unit_price),
            NmbItem: i.name,
            MontoItem: String(i.total),
          })),
        },
      },
    },
  }
  if (!doc.items.length) {
    throw new Error('at least 1 item required')
  }
  return axios
    .post(`${RESOURCE_LOCATION}manual_dte`, {
      document: { content: JSON.stringify(content) },
    })
    .then(dataGetter)
}

export const edit = (document_id, document) =>
  axios.put(`${RESOURCE_LOCATION}${document_id}`, { document }).then(dataGetter)

export const remove = (document_id) =>
  axios.delete(`${RESOURCE_LOCATION}${document_id}`).then(dataGetter)
