import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {
  Page,
  Text,
  Font,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from '@react-pdf/renderer'
import _, { filter, functionsIn, map } from 'lodash'
import { eachDayOfInterval, format } from 'date-fns'

import { Box, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import Logo from '../images/logo.png'
import { endOfWeek, startOfWeek, sub, add } from 'date-fns'
import StaticMap from './StaticMap'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  margin: {
    position: 'fixed',
    bottom: '70px',
    right: '10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    display: 'flex',
    width: '60vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 3),
  },
}))

// Register font
Font.register({
  family: 'Roboto',
  src:
    'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
})

const styles = StyleSheet.create({
  PDFViewer: {
    width: '100%',
    height: '100%',
    display: 'block',
  },

  viewMaps: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
  },
  dayContainer: {
    padding: 10,
    flexGrow: 1,
  },
  section: {
    marginTop: '5px',
    padding: 5,
    border: '3px solid #000',
    borderRadius: '4px',
    display: 'flex',
  },
  maquina: {
    marginTop: '5px',
    padding: '5px',
    paddingLeft: '10px',
    border: '2px solid #000',
    borderRadius: '4px',
  },
  insumos: {
    paddingTop: '5px',
    paddingLeft: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  line: {
    flexGrow: 1,
    borderBottom: '1px solid #000',
  },
  box: {
    border: '3px solid #000',
    height: '20px',
    width: '20px',
  },
  h1: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  pageNumber: {
    textAlign: 'right',
    marginBottom: '10px',
    marginRight: '10px',
  },
  logo: {
    display: 'flex',
    height: '18px',
  },
  map: {
    width: '100%',
    //border: '1px solid green',
    //height: '200px',
  },
  head: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  confirmation: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  confirmationSections: {
    height: '120px',
    flexGrow: 1,
    padding: '15px',
  },
})

export default function PdfExporter({
  date,
  state,
  resources,
  resourcesColor,
  activePdf,
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [initialDate, setInitialDate] = React.useState(date)
  const [finalDate, setFinalDate] = React.useState(date)

  const farms = useSelector((state) => state.farms)
  const farmId = useSelector((state) => state.routeParams.farmId)
  const farm = _.find(farms, { id: farmId })

  const handleInicialDateChange = (date) => {
    setInitialDate(date > finalDate ? initialDate : date)
  }
  const handleFinalDateChange = (date) => {
    setFinalDate(date)
  }

  //let mapImage = null

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }


  

  const intervalDate = eachDayOfInterval({ start: initialDate, end: finalDate })

  useEffect(()=>{ setInitialDate(date); setFinalDate(date)},[date])

  const intervalTasks = filter(
    map(intervalDate, (d) => {
      const parsed = format(d, 'dd/MM/yyyy')
      return state.tasksByDay[parsed]
    }),
  )

  //console.log('initial', initialDate,'final:', finalDate)
  //console.log('interbal:', intervalTasks)

  let googleArray = []

  let staticManagments = _.map(state.managements, 'meadow_id')
  //console.log(staticManagments)

  let resourcesArray = _.chunk(resources, 15)

  //console.log(activePdf)

  return (
    <div>
      {resourcesArray.map((x, i) => {
        //setGoogleImages(staticMapImage)
        //console.log(googleImages)

        return (
          <StaticMap
            googleImages={googleArray}
            staticManagments={staticManagments}
            index={i}
            resources={x}
            resourcesColor={resourcesColor}
          />
        )
      })}

      <Fab
        disabled={activePdf}
        color='primary'
        aria-label='add'
        className={classes.margin}
        onClick={handleOpen}
      >
        <PictureAsPdfSharpIcon />
      </Fab>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box width='50%'>
              <Typography variant='h3'>Exportador de PDF</Typography>
              <Box
                width='95%'
                height='2px'
                mt='8px'
                mb='10px'
                className={classes.divider}
              />

              <Typography variant='body'>
                Selecione el rango de fecha que desee Exportador
              </Typography>
              <Box
                display='flex'
                width='95%'
                mt='10px'
                justifyContent='space-between'
              >
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='dd/MM/yyyy'
                  margin='normal'
                  id='date-picker-inline'
                  label='Fecha de inicio'
                  value={initialDate}
                  onChange={handleInicialDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />

                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='dd/MM/yyyy'
                  margin='normal'
                  id='date-picker-inline'
                  label='Fecha de termino'
                  value={finalDate}
                  onChange={handleFinalDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Box>
              <Box pt='15px'>
                <Button variant='contained' color='primary'>
                  <PDFDownloadLink
                    document={
                      <TaskPdf
                        resources={resources}
                        images={googleArray}
                        state={state}
                        farm={farm}
                        intervalTasks={intervalTasks}
                      />
                    }
                    style={{ color: 'white', textDecoration: 'none' }}
                    fileName={farm?.name + '.pdf'}
                  >
                    {({ loading }) =>
                      loading ? 'Cargando Documento' : 'Descargar PDF'
                    }
                  </PDFDownloadLink>
                </Button>
              </Box>
            </Box>
            <Box width='50%'>
              <PDFViewer style={styles.PDFViewer}>
                <TaskPdf
                  resources={resources}
                  images={googleArray}
                  state={state}
                  farm={farm}
                  intervalTasks={intervalTasks}
                ></TaskPdf>
              </PDFViewer>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

function TaskPdf(props) {
  /*console.log('medows:',props.state.meadows) 
  console.log('task:', props.state.task_groups)
  console.log('management:', props.state.managements)*/

  return (
    <Document>
      <Page size='LETTER' style={styles.page}>
        {map(props.intervalTasks, (t, i) => (
          <TasksByDay
            resources={props.resources}
            images={props.images}
            farm={props.farm}
            newPage={i > 0}
            tasks={t}
            meadows={props.state.meadows}
            taskGroups={props.state.task_groups}
            managements={props.state.managements}
          />
        ))}
        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
          style={styles.pageNumber}
        />
      </Page>
    </Document>
  )
}

function pdfFinderId(meadow_id, resources) {
  const abc = 'ABCDEFGHIJKLMNO'
  let number = _.findIndex(resources, { id: Number(meadow_id) })
  let mapa = _.floor(number / 15)
  //console.log(meadow_id)
  //console.log(number)
  return ` - mapa: ${mapa + 1} - marcador: ${abc.charAt(number % 15)}`
}

function TasksByDay({
  resources,
  tasks,
  meadows,
  taskGroups,
  managements,
  newPage,
  farm,
  images,
}) {
  return (
    <React.Fragment>
      {images.map((x, i) => (
        <View break={i > 0} style={styles.viewMaps}>
          <Text
            style={{
              display: 'flex',
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              fontSize: '25px',
              textAlign: 'center',
              margin: '30px',
            }}
          >
            Mapa: {i + 1}
          </Text>
          <Image src={x} />
        </View>
      ))}
      <View style={styles.dayContainer} break={true}>
        <View style={styles.head}>
          <Text
            style={{
              display: 'flex',
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              fontSize: '24px',
            }}
          >
            Fecha: {format(new Date(tasks[0].suggested_date), 'dd/MM/yyyy')}{' '}
            {farm?.name}
          </Text>
          <Image style={styles.logo} src={Logo} />
        </View>

        {_(tasks)
          .groupBy(
            (t) =>
              managements[taskGroups[t.task_group_id].management_id].meadow_id,
          )
          .mapValues((ts, meadow_id) => (
            <View style={styles.section} wrap={false}>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  fontSize: '21px',
                }}
              >
                {meadows[meadow_id].name} - {meadows[meadow_id].area_in_ha} ha.
                {pdfFinderId(meadow_id, resources)},
              </Text>
              {map(ts, (t) => (
                <View style={styles.maquina} wrap={false}>
                  <View style={styles.row}>
                    <Text>
                      {t.machinery.name}, frec: {t.machinery_frequency}, Obs:{' '}
                      {t.observation}
                    </Text>
                    <View style={styles.line} />
                    <View style={styles.box} />
                  </View>
                  <View style={styles.insumos}>
                    {map(t.task_soil_resources, (sr) => (
                      <View style={styles.row}>
                        <Text>
                          {sr.soil_resource.name}, Dosis: {sr.dose}, Obs:{' '}
                          {sr.observation}
                        </Text>
                        <View style={styles.line} />
                        <View style={styles.box} />
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          ))
          .values()
          .value()}
        <View style={styles.confirmation} wrap={false}>
          <View style={styles.confirmationSections}>
            <View style={styles.line} />
            <Text
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '18px',
                margin: 'auto',
                paddingTop: '10px',
              }}
            >
              Nombre
            </Text>
          </View>
          <View style={styles.confirmationSections}>
            <View style={styles.line} />
            <Text
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '18px',
                margin: 'auto',
                paddingTop: '10px',
              }}
            >
              Firma
            </Text>
          </View>
        </View>
      </View>
    </React.Fragment>
  )
}
