import _ from 'lodash'
import polylabel from 'polylabel'

export const getRedirectPath = (pathDescription, location, key, value) => {
  const pathArr = _(pathDescription).split('/').value()
  const locationArr = _(location).split('/').value()

  if (pathArr.length !== locationArr.length) {
    console.warn(
      'path description and current location are different lengths',
      pathArr,
      locationArr,
    )
  }
  //console.log('value: ', value)
  const index = _(pathArr).indexOf(key)
  locationArr[index] = value
  return locationArr.join('/')
}

export const getParamFromUrl = (pathDescription, location, key) => {
  const pathArr = _(pathDescription).split('/').value()
  const locationArr = _(location).split('/').value()

  if (pathArr.length !== locationArr.length) {
    console.warn(
      'path description and current location are different lengths',
      pathArr,
      locationArr,
    )
  }

  const index = _(pathArr).indexOf(key)
  return locationArr[index]
}

export function getCenterCoordinate(polygon) {
  const coordinates = _(polygon)
    .map(({ lat, lng }) => [lat, lng])
    .value()
  const p = polylabel([coordinates], 0.1)
  return {
    lat: p[0],
    lng: p[1],
  }
}

export function getPolygonData(maps, polygon) {
  const coordinates = polygon
    .getPath()
    .getArray()
    .map((c) => c.toJSON())
  const area = Number(
    (maps.geometry.spherical.computeArea(polygon.getPath()) * 1e-4).toFixed(2),
  )
  return { coordinates: { polygon: coordinates }, area_in_ha: area }
}

export const redirectWithParams = (
  history,
  pathDescription,
  whitelist,
  params,
  side = 'left',
  isAdmin = true,
) => {
  const _whitelist = isAdmin ? whitelist : _.drop(whitelist, 2)
  const cleanPath = _(pathDescription)
    .split('/')
    .filter((k) =>
      _.startsWith(k, ':') ? !_.includes(_whitelist, k.substring(1)) : true,
    )
    .value()
    .join('/')
  const requiredPath = _(_whitelist)
    .map((p) => '/:' + p)
    .value()
    .join('')
  const newPathDescription =
    side === 'left' ? requiredPath + cleanPath : cleanPath + requiredPath

  const replacePathname = newPathDescription === pathDescription
  let pathname = history.location.pathname
  if (replacePathname) {
    const pathnameArr = _(pathname).split('/').value()
    pathname =
      side === 'left'
        ? '/' +
          _(pathnameArr)
            .drop(_whitelist.length + 1)
            .value()
            .join('/')
        : '/' +
          _(pathnameArr)
            .dropRight(_whitelist.length + 1)
            .value()
            .join('/')
  }

  const newPath =
    (_whitelist.length
      ? '/' +
        _(_whitelist)
          .map((p) => (params[p] ? params[p] : 0))
          .value()
          .join('/')
      : '') + pathname

  const redirectPath = getRedirectPath(newPathDescription, newPath)
  if (redirectPath !== history.location.pathname) {
    history.replace(redirectPath)
  }
}
