import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

export const getDocuments = (
  document_type,
  date_start,
  date_end,
  page_number,
  page_size,
  category_id,
  sub_category_id,
  folio,
  rs,
) =>
  axios
    .get(
      `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/:businessId/documents/`,
      {
        params: {
          date_start,
          date_end,
          document_type,
          page: page_number,
          page_size,
          item_detail_category_id: category_id,
          item_detail_subcategory_id: sub_category_id,
          folio: folio,
          rs: rs,
        },
      },
    )
    .then(dataGetter)

export const update = (document) =>  
  axios
    .put(
      `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/:businessId/documents/${document.id}`,
      {
        document,
      },
    )
    .then(dataGetter)
    .catch((e) => {
      throw e.response.data
    })
  

export const create = ({ user_id, farm_owner_id, business_id, ...document }) =>
  axios
    .post(
      `${BASE_URL}/api/users/${user_id}/farm_owners/${farm_owner_id}/accounting/businesses/:businessId/documents/`,
      {
        document,
      },
    )
    .then(dataGetter)
    .catch((e) => {
      throw e.response.data
    })

export const remove = (id) =>
  axios
    .delete(
      `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/:businessId/documents/${id}`,
    )
    .then(dataGetter)
