import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import _ from 'lodash'
import clsx from 'clsx'

const formatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0,
  //maximumFractionDigits: 0,
})

const color = ['#5FAA00', '#9CC26A', '#F9A825', '#FF7A00']

const GreenIcon = (Icon) =>
  withStyles((theme) => ({
    colorPrimary: {
      color: theme.palette.primary.dark,
    },
  }))(Icon)

const useStyles = makeStyles({
  
  table: {
    minWidth: 700,
  },
  category: {
    borderRight: '1px solid black',
  },
  superCategory: {
    fontWeight: '500 !important',
  },
  childRow: {
    paddingLeft: '26px',
  },
  childRowDouble: {
    paddingLeft: '36px',
  },
  marginResult: {
    background: '#E7FFC8',
    //color: '#F9A825',
  },
})
const SegTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    borderBottom: '2px solid black',
  },
  body: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}))(TableCell)

const CatTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    fontWeight: 400,
  },
}))(TableCell)

const SubCatTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    fontWeight: 300,
  },
}))(TableCell)

export default function CategoriesTable(props) {
  const { segment, isSuperCategory, children, dataParams } = props
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  console.log('categoriesTables',segment, children, dataParams)

  return (
    <React.Fragment>
      <TableRow hover className={classes.root}>
        <TableCell className={clsx(isSuperCategory && classes.childRow)}>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon color='primary' />
            ) : (
              <KeyboardArrowRightIcon color='primary' />
            )}
          </IconButton>
        </TableCell>
        <SegTableCell
          className={clsx(
            classes.category,
            isSuperCategory && classes.superCategory,
            isSuperCategory && classes.childRow,
          )}
          component='th'
          scope='row'
        >
          {segment.name}
        </SegTableCell>
        <SegTableCell
          align='right'
          className={clsx(isSuperCategory && classes.superCategory)}
        >
          {formatter.format(segment.total)}
        </SegTableCell>
        <SegTableCell
          align='right'
          className={clsx(isSuperCategory && classes.superCategory)}
        >
          {formatter.format(segment.total_by_cow)}
        </SegTableCell>
        <SegTableCell
          align='right'
          className={clsx(isSuperCategory && classes.superCategory)}
        >
          {formatter.format(segment.total_by_ha)}
        </SegTableCell>
        <SegTableCell
          align='right'
          className={clsx(isSuperCategory && classes.superCategory)}
        >
          {formatter.format(segment.total_by_milk)}
        </SegTableCell>
        <SegTableCell
          align='right'
          className={clsx(isSuperCategory && classes.superCategory)}
        >
          {segment.i_l_percentage}
        </SegTableCell>
      </TableRow>
      <React.Fragment />
      {open && (
        <React.Fragment>
          {children}
          {segment.categories.map((category) => (
            <SubcategoryTable
              classes={classes}
              category={category}
              isSuperCategory={isSuperCategory}
            />
          ))}
        </React.Fragment>
      )}
      {segment.result_name && (
        <TableRow hover className={classes.marginResult}>
          <TableCell />
          <SegTableCell className={classes.category} component='th' scope='row'>
            {_.upperFirst(segment.result_name)}
          </SegTableCell>
          <SegTableCell align='right'>
            {formatter.format(segment.margin)}
          </SegTableCell>
          <SegTableCell align='right'>{formatter.format(segment.margin / dataParams.cows )}</SegTableCell>
          <SegTableCell align='right'>{formatter.format(segment.margin / dataParams.ha )}</SegTableCell>
          <SegTableCell align='right'>{formatter.format(segment.margin / dataParams.liters )}</SegTableCell>
          <SegTableCell align='right'></SegTableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

const SubcategoryTable = (props) => {
  const { isSuperCategory } = props
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  return (
    <React.Fragment>
      <TableRow hover>
        <TableCell
          className={clsx(
            isSuperCategory && classes.childRowDouble,
            classes.childRow,
          )}
        >
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon color='primary' />
            ) : (
              <KeyboardArrowRightIcon color='primary' />
            )}
          </IconButton>
        </TableCell>
        <CatTableCell
          className={clsx(
            props.classes.category,
            isSuperCategory && classes.childRowDouble,
            classes.childRow,
          )}
          component='th'
          scope='row'
        >
          {props.category.name}
        </CatTableCell>
        <CatTableCell align='right'>
          {formatter.format(props.category.total)}
        </CatTableCell>
        <CatTableCell align='right'>
          {formatter.format(props.category.total_by_cow)}
        </CatTableCell>
        <CatTableCell align='right'>
          {formatter.format(props.category.total_by_ha)}
        </CatTableCell>
        <CatTableCell align='right'>
          {formatter.format(props.category.total_by_milk)}
        </CatTableCell>
        <CatTableCell align='right'>
          {props.category.i_l_percentage}
        </CatTableCell>
      </TableRow>
      {open &&
        props.category.sub_categories.map((subcategory) => (
          <TableRow hover>
            <TableCell />
            <SubCatTableCell
              className={clsx(
                props.classes.category,
                props.classes.childRowDouble,
              )}
              component='th'
              scope='row'
            >
              {subcategory.name}
            </SubCatTableCell>
            <SubCatTableCell align='right'>
              {formatter.format(subcategory.total)}
            </SubCatTableCell>
            <SubCatTableCell align='right'>
              {formatter.format(subcategory.total_by_cow)}
            </SubCatTableCell>
            <SubCatTableCell align='right'>
              {formatter.format(subcategory.total_by_ha)}
            </SubCatTableCell>
            <SubCatTableCell align='right'>
              {formatter.format(subcategory.total_by_milk)}
            </SubCatTableCell>
            <SubCatTableCell align='right'>
              {subcategory.i_l_percentage}
            </SubCatTableCell>
          </TableRow>
        ))}
    </React.Fragment>
  )
}
