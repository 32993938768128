import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/`

export const resourceKey = 'farm'
export const collectionKey = 'farms'

export const fields = [{ name: 'Nombre', field: 'name' }]

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const byId = (id) => axios.get(`${BASE_URL}/api/farm_owners/${id}/land/farms/`).then(dataGetter)

export const create = (farm) =>
  axios
    .post(RESOURCE_LOCATION, { farm: { ...farm, lat: 0, lon: 0 } })
    .then(dataGetter)

export const edit = (farm_id, farm) =>
  axios
    .put(`${RESOURCE_LOCATION}/${farm_id}`, {
      farm,
    })
    .then(dataGetter)

export const remove = (farm_id) =>
  axios.delete(`${RESOURCE_LOCATION}/${farm_id}`).then(dataGetter)
