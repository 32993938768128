import { StaticGoogleMap, Marker, Path } from 'react-static-google-map'
import _ from 'lodash'
import centroid from 'polygon-centroid'
import { decode, encode } from '@googlemaps/polyline-codec'

export default function StaticMap({
  setStaticMapImage,
  googleImages,
  resources,
  resourcesColor = undefined,
  index,
  staticManagments,
}) {
  //console.log(resources, resourcesColor)
  //console.log(googleImages)

  return (
    <StaticGoogleMap
      maptype='satellite'
      onGenerate={(value) => (googleImages[index] = value)}
      as='div'
      size='700x850'
      apiKey='AIzaSyAMJ6G8DC3G8oulclyOW01GKGd8o_RBDhI'
    >
      <Markers resources={resources} staticManagments={staticManagments} />

      {resources.map((resourceitem, i) => {
        let polygons = [
          ...resourceitem.coordinates.polygon,
          resourceitem.coordinates.polygon[0],
        ]

        function pointArray(x) {
          return [x.lat, x.lng]
        }

        let points = _.map(polygons, pointArray)

        //console.log(points, encode(points, 5))
        return (
          <Path
            color='white'
            weight='4'
            fillcolor={
              staticManagments.indexOf(resourceitem.id) >= 0
                ? '0xFFFFFF50'
                : null
            }
            points={`enc:${encode(points, 5)}`}
          />
        )
      })}
    </StaticGoogleMap>
  )
}

function Markers({ resources, staticManagments }) {
  const abc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789'

  function square(n) {
    return { x: n.lat, y: n.lng }
  }

  function formater(number) {
    return String(number).padStart(2, '0')
  }

  return resources.map((items, i) => {
    //console.log('items:', items.coordinates)
    let points = _.map(items.coordinates.polygon, square)
    let center = centroid(points)
    //console.log(center)

    if (staticManagments.indexOf(items.id) >= 0) {
      return (
        <Marker
          color='green'
          label={abc.charAt(i)}
          location={{ lat: center.x, lng: center.y }}
        />
      )
    }
  })
}
