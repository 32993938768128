import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import categories from 'reducers/categories'
import routeParams from 'reducers/routeParams'
import path from 'reducers/path'
import users from 'reducers/users'
import farmOwners from 'reducers/farmOwners'
import user from 'reducers/user'
import businesses from 'reducers/businesses'
import farms from 'reducers/farms'
import app from 'reducers/app'
import url from 'reducers/url'
import network from 'reducers/network'

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    categories,
    routeParams,
    path,
    users,
    farmOwners,
    user,
    businesses,
    farms,
    app,
    url,
    network,
  })

export default createRootReducer
