import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/meadows/:templateMeadowId/soil_management/managements`

export const collectionKey = 'task_groups'
export const parentKey = 'management_id'

export const fields = [{ name: 'Nombre', field: 'name' }]

export const create = (_management_category_id, management_id, task_group) =>
  axios
    .post(`${RESOURCE_LOCATION}/${management_id}/task_groups/`, {
      task_group: {
        ...task_group,
        soil_management_total: 0,
      },
    })
    .then(dataGetter)

export const remove = (
  _soil_management_category_id,
  management_id,
  task_group_id,
) =>
  axios
    .delete(
      `${RESOURCE_LOCATION}/${management_id}/task_groups/${task_group_id}`,
    )
    .then(dataGetter)

export const edit = (
  task_group_id,
  task_group,
  _soil_management_category_id,
  management_id,
) =>
  axios
    .put(`${RESOURCE_LOCATION}/${management_id}/task_groups/${task_group_id}`, {
      task_group,
    })
    .then(dataGetter)
