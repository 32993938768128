import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Box } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },

  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '6px',
  },
  inputBox: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid #cccccc',
    borderRadius: '6px',
    width: '50px',
  },
  tableCell: {
    padding: 0,
  },
}))

function createData(
  name,
  ene,
  feb,
  mar,
  abr,
  may,
  jun,
  jul,
  ago,
  sep,
  oct,
  nov,
  dic,
) {
  return { name, ene, feb, mar, abr, may, jun, jul, ago, sep, oct, nov, dic }
}

const rows = [
  createData(
    'precio de la leche',
    '200',
    '200',
    '200',
    '200',
    '200',
    '200',
    '200',
    '200',
    '200',
    '200',
    '200',
    '200',
    '200',
  ),
  createData(
    'N° de partos',
    '10',
    '10',
    '10',
    '10',
    '10',
    '10',
    '10',
    '10',
    '10',
    '10',
    '10',
    '10',
    '10',
  ),
]
const months = [
  'ene',
  'feb',
  'mar',
  'abr',
  'may',
  'jun',
  'jul',
  'ago',
  'sep',
  'oct',
  'nov',
  'dic',
]

export default function DenseTable(props) {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>{props.name}</TableCell>
            {months.map((m, key) => (
              <TableCell key={`${key}-head`} align='center'>
                {m}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, keyR) => (
            <TableRow key={row.name} key={`${keyR}-row`}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              {months.map((m, keyM) => (
                <TableCell
                  align='center'
                  key={`${keyM}-cell${keyR}`}
                  className={classes.tableCell}
                >
                  <Box className={classes.inputBox}>
                    <InputBase
                      className={classes.margin}
                      defaultValue={row[m]}
                      inputProps={{ 'aria-label': 'naked' }}
                    />
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
