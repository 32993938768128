import React from 'react'
import _ from 'lodash'
import { Box } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const ColorLegend = ({ resourcesColor }) => {
  const labels = _(resourcesColor)
    .values()
    .uniqBy('id')
    .keyBy('name')
    .mapValues('color')
    .value()
  return (
    <React.Fragment>
      {_.map(labels, (color, name) => (
        <Box display='flex' flexDirection='row' alignItems='center'>
          <FiberManualRecordIcon style={{ color }} />
          &nbsp;{name}
        </Box>
      ))}
    </React.Fragment>
  )
}

export default ColorLegend
