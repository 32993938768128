import React from 'react'
import { Redirect } from 'react-router-dom'
import { Route } from 'react-router'
import { useSelector, ReactReduxContext } from 'react-redux'
import { Box, CircularProgress } from '@material-ui/core'

export default function CustomPrivateRoute({
  component: Component,
  computedMatch,
  ...rest
}) {
  const { isWaitingLogin, isLoggedIn } = useSelector((state) => state.app)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isWaitingLogin) {
          return null
        }
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/iniciar-sesion',
                state: { from: props.location },
              }}
            />
          )
        }
        if (isLoggedIn) {
          return (
            <ReactReduxContext.Consumer>
              {({ store }) => {
                store.dispatch({
                  type: '@@router/ROUTE_CHANGED',
                  params: (computedMatch && computedMatch.params) || {},
                  path: (computedMatch && computedMatch.path) || {},
                  url: (computedMatch && computedMatch.url) || '',
                })
                return <Component {...props} />
              }}
            </ReactReduxContext.Consumer>
          )
        }
      }}
    />
  )
}
