import * as types from 'actions/types'

const INITIAL_STATE = {
  isWaitingLogin: true,
  isLoggedIn: false,
}

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER: {
      return { ...state, isWaitingLogin: false, isLoggedIn: true }
    }
    case types.UNSET_USER: {
      return { ...state, isWaitingLogin: false, isLoggedIn: false }
    }

    case types.LOGIN_FAILED: {
      return { ...state, isWaitingLogin: false, isLoggedIn: false }
    }

    default:
      return state
  }
}

export default app
