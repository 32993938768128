import React from 'react'
import { Task, TaskSoilResource } from 'utils/api/models'
import ResourcesAsTable from 'components/ResourcesAsTable'
import { Box, Breadcrumbs, Typography, withStyles } from '@material-ui/core'
import _ from 'lodash'

const TaskWithoutPrice = {
  ...Task,
  fields: _(Task.fields)
    .reject({ field: 'machinery_price' })
    .reject({ name: 'Costo/Ha' })
    .value(),
}

const SoilResourceWithoutPrice = {
  ...TaskSoilResource,
  fields: _(TaskSoilResource.fields)
    .reject({ field: 'unit_price' })
    .reject({ field: 'task_resource_total' })
    .value(),
}

const TypographyManagement = withStyles({
  root: {
    fontStyle: 'italic',
  },
})(Typography)

function TaskGroupDetail({
  task_group,
  tasks,
  task_id,
  management,
  meadows,
  refreshData,
}) {
  const _tasks = task_group.tasks.map((id) => tasks[id])

  return (
    <React.Fragment>
      <Box p={1}>
        <Breadcrumbs>
          <TypographyManagement>
            {meadows[management.meadow_id]?.name}
          </TypographyManagement>
          <TypographyManagement>
            {management.soil_management_category.name}
          </TypographyManagement>
          <TypographyManagement>{management.name}</TypographyManagement>
          <TypographyManagement>{task_group.name}</TypographyManagement>
        </Breadcrumbs>
      </Box>
      <ResourcesAsTable
        resources={_tasks}
        model={TaskWithoutPrice}
        hideAddButton
        parentSelections={[management.meadow_id, management.id, task_group.id]}
        setSelected={(id) => console.log('id_tabla', id)} //Marcar seleccionado aquí
        selected={task_id}
        refreshData={async () => await refreshData()}
        afterRow={(rowData) => (
          <ResourcesAsTable
            resources={rowData.task_soil_resources}
            parentSelections={[
              management.meadow_id,
              management.id,
              task_group.id,
              rowData.id,
            ]}
            setSelected={() => null}
            refreshData={async () => await refreshData()}
            model={SoilResourceWithoutPrice}
            hideAddButton
          />
        )}
      />
    </React.Fragment>
  )
}

export default TaskGroupDetail
