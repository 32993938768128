import React from 'react'
import AssignmentIcon from '@material-ui/icons/Assignment'

import {
  CardHeader as MuiCardHeader,
  makeStyles,
  ButtonBase,
  Badge as MuiBadge,
  Chip as MuiChip,
  Box,
  withStyles,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

import { isSameDay } from 'date-fns'

import clsx from 'clsx'

const CardHeader = withStyles({ action: { margin: 'unset' } })(MuiCardHeader)
const Badge = withStyles({
  badge: {
    marginLeft: -10,
    marginTop: 5,
  },
})(MuiBadge)
const Chip = withStyles({ labelSmall: { fontSize: 10 }, root: { height: 14 } })(
  MuiChip,
)
const useStyles = makeStyles((theme) => ({
  today: {
    backgroundColor: 'black !important',
  },
  headerRoot: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  addButton: {
    color: 'white',
    paddingTop: '5px',
  },
  buttonAsHeader: {
    display: 'block',
  },
}))

const CardHeaderDay = (props) => {
  const classes = useStyles()

  return (
    <ButtonBase className={classes.buttonAsHeader} component='div'>
      <CardHeader
        disableTypography
        className={clsx(
          isSameDay(props.d, props.date) && classes.today,
          classes.headerRoot,
        )}
        onClick={props.onClick}
        title={
          <Badge
            badgeContent={
              props.showDayWarning ? <ErrorIcon fontSize='small' /> : null
            }
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            {props.title}
          </Badge>
        }
        Primary
        action={
          props.machineriesCount > 0 && (
            <Chip label={props.machineriesCount} size='small' />
          )
        }
      />
    </ButtonBase>
  )
}

export default CardHeaderDay
