import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
import { ServiceProvider } from 'utils/api/models'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId`

export const fields = [
  { name: 'Fecha de ejecución', field: 'date', type: 'date' },
  {
    name: 'Proveedor de servicio',
    field: 'service_provider_id',
    model: ServiceProvider,
  },
]

export const create = (task_assignment_batch) =>
  axios
    .post(`${RESOURCE_LOCATION}/task_batch`, {
      task_assignment_batch,
    })
    .then(dataGetter)
