/* import logo from './logo.svg' */
import React from 'react'
import './App.css'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import logger from 'redux-logger'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import { createBrowserHistory } from 'history'

import Routes from 'components/Routes'
import LoadingIndicator from 'components/LoadingIndicator'

import reducers from 'reducers'
import sagas from 'sagas'

import { SnackbarProvider } from 'notistack'

import { esES } from '@material-ui/core/locale'
import { SnackbarUtilsConfigurator } from 'utils/Snackbar'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

const themeLightAlba = createMuiTheme(
  {
    typography: {
      h2: {
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontSize: '1.5rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
      },

      h3: {
        fontWeight: 'bold',
        fontSize: '1.4rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
      },
    },

    palette: {
      primary: {
        light: '#a7f84d',
        main: '#5faa00',
        dark: '#3b9400',
        contrastText: '#fff',
      },

      secondary: {
        light: '#ffffff',
        main: '#ffffff',
        dark: '#cccccc',
        contrastText: '#000000',
      },

      background: {
        paper: '#ffffff',
        default: '#e8e8e8',
      },

      text: {
        primary: '#000',
      },
    },
    overrides: {
      MuiTab: {
        textColorSecondary: {
          color: '#aaa',
        },
        wrapper: { fontWeight: 'bold', fontSize: '1rem' },
      },
    },
  },
  esES,
)

const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const middlewares = [routerMiddleware(history), sagaMiddleware]
if (process.env.REACT_APP_REDUX_LOGGER === 'true') {
  middlewares.push(logger)
}
export const store = createStore(
  reducers(history),
  applyMiddleware(...middlewares),
)

sagaMiddleware.run(sagas)

const defaultOwner = { user_id: 3, farm_owner_id: 3 }

function App(props) {
  // const { user_id, farm_owner_id } = props

  // React.useEffect(() => {
  //   store.dispatch(
  //     actions.setOwner(
  //       user_id ? user_id : defaultOwner.user_id,
  //       farm_owner_id ? farm_owner_id : defaultOwner.farm_owner_id,
  //     ),
  //   )
  // }, [farm_owner_id, user_id])

  return (
    <Provider store={store}>
      <ThemeProvider theme={themeLightAlba}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <SnackbarUtilsConfigurator />

          <LoadingIndicator />

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <ConnectedRouter history={history}>
              <Routes />
            </ConnectedRouter>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
