import { connect } from 'react-redux'
import { compose } from 'lodash/fp'

import Documents from 'components/Documents'
import withProps from 'hoc/withProps'
import { Income } from 'utils/api/models'
import withYearParam from 'hoc/withYearParam'
import withOwnerBusinessParam from 'hoc/withOwnerBusinessParam'

const getData = Income?.all

const mapState = ({ categories: { incomes } }) => ({ categories: incomes })

const Incomes = compose(
  connect(mapState),
  withProps({
    getData,
    title: 'Ventas',
    businessNameKeys: ['Receptor', 'RznSocRecep'],
    isEmitter: true,
  }),
)(Documents)

export default Incomes
