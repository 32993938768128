import React from 'react'
import Chart from 'react-google-charts'
import { fromUnixTime, isSameYear } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useTheme, makeStyles } from '@material-ui/core'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  chart: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
}))

const CalendarNavigator = (props) => {
  const theme = useTheme()
  const classes = useStyles()

  console.log('taskfreq: ', props.taskFrequencyByDay)

  const tasksThisYear = props.taskFrequencyByDay
    .filter(([date]) => isSameYear(date, props.date))

  console.log('props.date', tasksThisYear)



  return (
    <div className={classes.chart}>
      <Chart
        width={866}
        height={170}
        chartType='Calendar'
        chartLanguage='es'
        loader={<div>Loading Chart</div>}
        data={[
          [
            {
              type: 'date',
              id: 'Fecha',
            },
            {
              type: 'number',
              id: 'Cantidad de tareas',
            },
          ],
          ...(tasksThisYear.length ? tasksThisYear : [[props.date, NaN]]),
        ]}
        options={{
          title: props.visit ? 'Visitas realizadas' : 'Tareas por día',
          calendar: {
            cellSize: 15,
            dayOfWeekLabel: {
              fontName: 'Roboto',
              fontSize: 12,
              color: '#1a8763',
              bold: true,
            },
            dayOfWeekRightSpace: 10,
            daysOfWeek: 'DLMMJVS',
          },
          colorAxis: {
            minValue: 1,
            colors: [theme.palette.primary.light, theme.palette.primary.dark],
          },
        }}
        rootProps={{
          'data-testid': '1',
        }}
        chartEvents={[
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart()
              const [{ date: ts }] = chart.getSelection()
              props.setDate(utcToZonedTime(fromUnixTime(ts / 1000)))
            },
          },
        ]}
      />
    </div>
  )
}

export default CalendarNavigator
