import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  TextField,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles, makeStyles } from '@material-ui/core/styles'
import es from 'date-fns/locale/es';
import { update } from 'utils/api/models/Document/index'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    padding: '30px',
    border: 'none',
    outline: 'none',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  textfield: {
    display:'flex',
    width: '80%',
    flexDirection: 'column',
  },
}))

const MyModal = ({ open, setOpen, row }) => {
  
  console.log(row)

  const [selectedDate, handleDateChange] = useState(row.payments_first_date ? row.payments_first_date: row.date);
  const [paymentsCount, setPaymentsCount] = useState(row.payments_count ? row.payments_count : '1');
  const [paymentsFrequencyDays, setPaymentsFrequencyDays] = useState(row.payments_frequency_days ? row.payments_frequency_days: '1');
  const [errors, setErrors] = useState({});

  useEffect(()=>{
      handleDateChange(row.payments_first_date ? row.payments_first_date: row.date)
      setPaymentsCount(row.payments_count ? row.payments_count : '1');
      setPaymentsFrequencyDays(row.payments_frequency_days ? row.payments_frequency_days: '1');
      setErrors({})
  }, [open])

  const handleClose = () => {
    setOpen(false);
    
  };

  const handleSave = () => {
    let validate = [false, false]
    setErrors({})

    if(paymentsFrequencyDays === '' ) {
      setErrors({...errors, paymentsFrequencyDays: 'El campo no puede ir vacio' })
    } else {
      validate[0] = true
    } 

    if(paymentsCount === '' ) {
      setErrors({...errors, paymentsCount: 'El campo no puede ir vacio' })
    } else {
      validate[1] = true
    }

    console.log(validate)

    if(validate[0] === true && validate[1] === true){
      row.payments_count = paymentsCount
      row.payments_first_date = selectedDate
      row.payments_frequency_days = paymentsFrequencyDays
      //console.log(row)
      update(row).then(data => handleClose())
    }
  };

  const classes = useStyles()

  return (
    <Modal open={open} onClose={()=> setOpen(false)} className={classes.root}>
        <Paper className={classes.modalContainer}>
          <Typography variant='h5' gutterbottom>Ingreso de fechas de Pago</Typography>
          <Typography variant='body1' gutterbottom><b>{`N° de factura: ${row.dte_id}`}</b></Typography>
          <div className={classes.inputs}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <DatePicker
                  autoOk
                  label="Seleccionar fecha"
                  format="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.textfield}>
                  <TextField
                    error={errors.paymentsCount && true}
                    label="N° de cuotas"
                    variant="outlined"
                    value={paymentsCount}
                    onChange={(e) => setPaymentsCount(e.target.value.replace(/[^0-9]/g, ''))}
                    helperText={errors.paymentsCount && errors.paymentsCount}
                  />
                  <Typography variant='caption'>Ingrese solo números</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.textfield}>
                  <TextField
                    error={errors.paymentsFrequencyDays && true}
                    label="Frecuencia de pago en días"
                    variant="outlined"
                    value={paymentsFrequencyDays}
                    onChange={(e) => setPaymentsFrequencyDays(e.target.value.replace(/[^0-9]/g, ''))}
                    helperText={errors.paymentsFrequencyDays && errors.paymentsFrequencyDays}
                  />
                  <Typography variant='caption'>Ingrese solo números</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Guardar
          </Button>
        </Paper>
    </Modal>
  );
};

export default MyModal;