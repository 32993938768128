import React,{useEffect, useState, useLayoutEffect, useRef} from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, createStaticRanges} from 'react-date-range';
import { addDays, format, endOfDay, startOfDay } from 'date-fns';
import es from 'date-fns/locale/es';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
    filterOptions,
    findCategoryOption,
    renderCategoryLabel,
  } from 'components/CostCenterAssignmentModal/CostCenterAssignmentModal';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    rootHeader:{
        display: 'flex',
        //justifyContent: 'space-between',
        alignItems: 'center',
        //border: '1px solid green',
        width: '100%',
        gap: '50px',

    },
    filters:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        //alignItems: 'center',
        //border: '1px solid green',
        width: '400px',
        //gap: '20px',
        marginLeft: '50px',
    },
    heading: {
        //marginLeft: '25px',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
    },
  }));

export default function DocumentFilter({
    state, 
    setState,
    categoryFilter,
    setCategoryFilter,
    handleSubmit,
    dteIdFilter,
    setDteIdFilter,
    contentRsFilter, 
    setContentRsFilter,
    ...props
    }) {

    const stateHandleChange = (value) => {
        setState([value.selection])
        //setSelectedManagementCategories([])
        //setSelectedResourceCategories([]) 
    }

    const classes = useStyles()
    return(
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.rootHeader}>    
                    <Typography variant='h3' 
                        //color='primary'
                    >
                        Filtro de Busqueda
                    </Typography>
                    <DatePicker
                        variant='inline'
                        //inputVariant='outlined'
                        disableFuture
                        format='dd/MM/yyyy'
                        label='Fecha inicial'
                        value={state[0].startDate}
                        size='small'
                        disabled={true}
                    />
                    <DatePicker
                        variant='inline'
                        //inputVariant='outlined'
                        format='dd/MM/yyyy'
                        label='Fecha Final'
                        value={state[0].endDate}
                        size='small'
                        disabled={true}
                    />
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <DateRangePicker
                onChange={item => stateHandleChange(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                rangeColors={['#5FAA00']}
                fixedHeight={true}
                direction='horizontal'
                locale={es}
                //renderStaticRangeLabel={renderStaticRangeLabel}
                inputRanges={[]}
                staticRanges={createStaticRanges([
                    {
                        label: '1° semestre Pasado',
                        range: () => ({
                            startDate: new Date(`${new Date().getFullYear() -1 }, 01, 01`),
                            endDate: new Date(`${new Date().getFullYear() -1 }, 06, 30`),
                        }),
                    },
                    {
                        label: '1° semestre Actual',
                        range: () => ({
                            startDate: new Date(`${new Date().getFullYear() }, 01, 01`),
                            endDate: new Date(`${new Date().getFullYear() }, 06, 30`),
                        }),
                    },
                    {
                        label: 'Próximo 1° semestre',
                        range: () => ({
                            startDate: new Date(`${new Date().getFullYear() + 1 }, 01, 01`),
                            endDate: new Date(`${new Date().getFullYear() + 1 }, 06, 30`),
                        }),
                    },
                    {
                        label: '2° semestre Pasado',
                        range: () => ({
                            startDate: new Date(`${new Date().getFullYear() -1 }, 07, 01`),
                            endDate: new Date(`${new Date().getFullYear() -1 }, 12, 31`),
                        }),
                    },
                    {
                        label: '2° semestre Actual',
                        range: () => ({
                            startDate: new Date(`${new Date().getFullYear() }, 07, 01`),
                            endDate: new Date(`${new Date().getFullYear() }, 12, 31`),
                        }),
                    },
                    {
                        label: 'Próximo 2° semestre',
                        range: () => ({
                            startDate: new Date(`${new Date().getFullYear() + 1 }, 07, 01`),
                            endDate: new Date(`${new Date().getFullYear() + 1 }, 12, 31`),
                        }),
                    },
                    {
                      label: 'Año Pasado',
                      range: () => ({
                        startDate: new Date(`${new Date().getFullYear() -1 }, 01, 01`),
                        endDate: new Date(`${new Date().getFullYear() -1 }, 12, 31`),
                      }),
                    },
                    {
                        label: 'Año Actual',
                        range: () => ({
                            startDate: new Date(`${new Date().getFullYear() }, 01, 01`),
                            endDate: new Date(`${new Date().getFullYear() }, 12, 31`),
                        }),
                    },
                    {
                        label: 'Próximo Año',
                        range: () => ({
                            startDate: new Date(`${new Date().getFullYear() + 1 }, 01, 01`),
                            endDate: new Date(`${new Date().getFullYear() + 1 }, 12, 31`),
                        }),
                    },                    
                  ])}
                />
                <div className={classes.filters}>
                <Typography>Agregue filtros:</Typography>
                <TextField
                id="numero-de-factura"
                label="N° de factura"
                className={classes.textField}
                value={dteIdFilter}
                onChange={(e)=> setDteIdFilter(e.target.value)}
                margin="normal"
                />
                <TextField
                id="razón-social"
                label="Razón Social"
                className={classes.textField}
                value={contentRsFilter}
                onChange={(e)=>setContentRsFilter(e.target.value)}
                margin="normal"
                />
                <Autocomplete
                    id='category'
                    options={props.categories}
                    renderOption={(option) => option.sub_category}
                    onChange={(_event, value) =>
                        setCategoryFilter({
                        category_id: value?.category_id,
                        sub_category_id: value?.sub_category_id,
                        })
                    }
                    groupBy={(option) =>
                        `${option.parent ? `${option.parent} - ` : ''}${option.category}`
                    }
                    getOptionLabel={(option) => {
                        if (option.category) {
                        return renderCategoryLabel(option)
                        } else {
                        const category = findCategoryOption(
                            props.categories,
                            option.category_id,
                            option.sub_category_id,
                        )
                        return category ? renderCategoryLabel(category) : ''
                        }
                    }}
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label='Centro de Costo'
                        size='small'
                        style={{ width: '100%' }}
                        />
                    )}
                />
                <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.button}>
                    Aplicar Filtro
                </Button>
            </div>
            </ExpansionPanelDetails>
        </ExpansionPanel> 
    )
}