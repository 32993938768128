import React, { useEffect, useState } from 'react'
import { makeStyles, Typography, Box } from '@material-ui/core/'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import es from 'date-fns/locale/es'
import { format } from 'date-fns'
import { MyResponsiveLine } from './LineGraph'
import { MyResponsiveBoxPlot } from './BoxPlot'
import { MyResponsiveBar } from './BarGraph'
import PreTableMilkByDay from './PreTableMilkByDay'
import PreTableFixed from 'components/AnalyticsMilkFixedMonth/examples/PreTableFixed'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px',
    //border: '1px solid rgba(180, 180, 180, 0.2)',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
  },
  paper: {
    position: 'absolute',
    top: '10%',
    left: '2.5%',
    width: '95%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    overflowY: 'auto',
  },
}))

export default function GraphCardsMilkByDay({
  data,
  table_data,
  data_box,
  title,
  valueType,
  month = false,
  units = 'int',
  keys = [],
  data_type,
  fixed = false,
  intervalDate,
}) {
  const [graphType, setGraphType] = useState(data_type[0])
  const [modalOpen, isModalOpen] = useState(false)
  const classes = useStyles()
  const locale = es
  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <div className={classes.header}>
          <div>
            <Typography variant='h3' color='primary'>
              {title}
            </Typography>
            <Typography>{`${format(intervalDate?.from, 'd MMM YYY', {
              locale,
            })} - ${format(intervalDate?.to, 'd MMM YYY', {
              locale,
            })}`}</Typography>
            <Typography variant='body2'>{valueType}</Typography>
          </div>
          <GraphSelector
            graphType={graphType}
            setGraphType={setGraphType}
            isModalOpen={isModalOpen}
            modalOpen={modalOpen}
            data_type={data_type}
          />
        </div>
        <div style={{ height: 600 }}>
          {graphType === 'curve' && (
            <MyResponsiveLine data={data} month={month} units={units} />
          )}
          {graphType === 'boxplot' && (
            <MyResponsiveBoxPlot data={data_box} month={month} units={units} />
          )}
          {graphType === 'bar' && (
            <MyResponsiveBar
              data={data}
              month={month}
              units={units}
              keys={keys}
            />
          )}
          {graphType === 'table' &&
            (fixed === true ? (
              <PreTableFixed
                data={table_data}
                month={month}
                units={units}
                keys={keys}
              />
            ) : (
              <PreTableMilkByDay
                data={data}
                month={month}
                units={units}
                keys={keys}
              />
            ))}
        </div>
      </Paper>
    </>
  )
}

function GraphSelector({
  graphType,
  setGraphType,
  isModalOpen,
  modalOpen,
  data_type,
}) {
  const classes = useStyles()

  const filtered_data = _.filter(graphsData, (x) => data_type.includes(x.id))

  return (
    <Paper elevation={1} className={classes.icons}>
      {filtered_data.map((data, i) => (
        <div key={`graphic-style-selector-${i}`}>
          <IconButton
            onClick={() => setGraphType(data.id)}
            color={graphType === data.id ? 'primary' : 'default'}
            className={classes.iconButton}
            aria-label={data.label}
            disabled={data.disabled}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox={data.viewBox}
            >
              <path
                fill={data.fill}
                stroke={data.stroke}
                strokeWidth={data.strokeWidth}
                d={data.path}
              />
            </svg>
          </IconButton>
        </div>
      ))}
    </Paper>
  )
}

const graphsData = [
  {
    path: 'M22 21H2V3h2v16h2v-9h4v9h2V6h4v13h2v-5h4v7Z',
    label: 'grouped-view',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: '0',
    id: 'bar',
    disabled: false,
  },
  {
    path: 'M4 2v18h18v2H2V2zm17.914 6L15.5 14.414l-4-4l-5 5L5.086 14L11.5 7.586l4 4l5-5z',
    label: 'curve-view',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: '0',
    id: 'curve',
    disabled: false,
    viewBox: '0 0 24 24',
  },
  {
    path: 'M22 6V4H12v2h4v2h-4v12h4v2h-4v2h10v-2h-4v-2h4V8h-4V6Zm-8 12v-3h6v3Zm6-5h-6v-3h6Z',
    label: 'boxplot-view',
    fill: 'currentColor',
    stroke: 'none',
    strokeWidth: '1',
    id: 'boxplot',
    disabled: false,
    viewBox: '6 1 20 24',
  },
  {
    path: 'M8 5v18m8-18v18M1 11h22M1 5h22M1 17h22M1 1h22v22H1V1Z',
    label: 'table-view',
    fill: 'none',
    stroke: 'currentColor',
    strokeWidth: '2',
    id: 'table',
    disabled: false,
    viewBox: '0 0 24 24',
  },
]
