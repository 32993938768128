import React from 'react'
import TopBar from 'components/TopBar'
import { useLocation } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'

import Measure from 'react-measure'
import { makeStyles } from '@material-ui/core/styles'

import TabMenuOption from '../TabMenuOption'
import _ from 'lodash'
import useParams from 'hooks/useParams'

const buildMenuOptions = ({ farmOwnerId, farmId, businessId, year }) => [
  {
    id: 0,
    label: 'Mapas',
    route: '/mapas',
    submenus: [
      {
        label: 'Datos Geográficos',
        route: `/${farmOwnerId}/${farmId}/mapas/datos-geograficos`,
      },
      { label: 'Datos Climáticos', route: '/mapas/datos-climaticos' },
    ],
  },
  {
    id: 1,
    label: 'Planificación',
    route: '/planificacion',
    submenus: [
      {
        label: 'Base de datos',
        route: '/planificacion/base-de-datos',
      },
      {
        label: 'Plantillas manejos',
        route: `/${farmOwnerId}/${farmId}/planificacion/plantillas-manejos`,
      },
      {
        label: 'Calendario manejos',
        route: `/${farmOwnerId}/${farmId}/planificacion/calendario-manejos/${year}`,
      },
      { label: 'Analisis de suelo', route: '/planificacion/analisis-de-suelo' },
      {
        label: 'Ingreso de datos',
        route: `/${farmOwnerId}/${farmId}/planificacion/visitas-prediales/${year}`,
      },
    ],
  },
  {
    id: 2,
    label: 'Balance Forrajero',
    route: `/${farmOwnerId}/${farmId}/balance-forrajero`,
  },
  {
    id: 3,
    label: 'Finanzas',
    route: '/finanzas',
    submenus: [
      {
        label: 'Ventas',
        route: `/${farmOwnerId}/${businessId}/finanzas/ventas/${year}`,
      },
      {
        label: 'Compras',
        route: `/${farmOwnerId}/${businessId}/finanzas/compras/${year}`,
      },
      {
        label: 'Estado Resultado',
        route: `/${farmOwnerId}/${businessId}/finanzas/estado-resultado/${year}`,
      },
      {
        label: 'Estado Resultado Mensual',
        route: `/${farmOwnerId}/${businessId}/finanzas/estado-resultado-mensual/${year}`,
      },
      { label: 'Depreciación', route: '/finanzas/depreciacion' },
    ],
  },
  {
    id: 4,
    label: 'Analítica',
    route: '/analitica',
    submenus: [
      {
        label: 'Insumos y maquinaria',
        route: `/${farmOwnerId}/${businessId}/analitica`,
      },
      {
        label: 'Leche por día',
        route: `/${farmOwnerId}/${businessId}/analitica/leche/dia`,
      },
      {
        label: 'Leche por mes',
        route: `/${farmOwnerId}/${businessId}/analitica/leche/mes`,
      },
      {
        label: 'Leche mes fijo',
        route: `/${farmOwnerId}/${businessId}/analitica/leche/mes_fijo`,
      },
    ],
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    top: 'auto',
    bottom: 0,

    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.25)',
  },
  containerContent: {
    paddingTop: '1em',
    paddingBottom: '70px',
    maxWidth: '1800px',
    paddingLeft: '1em',
    paddingRight: '1em',
    margin: 'auto',
  },
}))

export default function Template({ children }) {
  const [tabBarWidth, setTabBarWidth] = React.useState(1179)

  const routeParams = useParams()

  const menuOptions = buildMenuOptions(routeParams)
  const classes = useStyles()

  let location = useLocation()
  const getIdFromPath = (options, path) => {
    const option = _(options).find((m) => {
      // eslint-disable-next-line no-useless-escape, prettier/prettier
      const pathRegEx = new RegExp(_(m.route).split('/').value().join('/'), 'g')

      return pathRegEx.test(path)
    })
    return option ? option.id : null
  }
  const tabId = getIdFromPath(menuOptions, location.pathname) || 0

  return (
    <div className={classes.root}>
      <TopBar />
      <Measure
        bounds
        onResize={(contentRect) => {
          setTabBarWidth(contentRect.bounds.width)
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <div className={classes.containerContent}>{children}</div>

            <AppBar position='fixed' className={classes.appBar}>
              <Tabs
                value={tabId}
                aria-label='site navigator'
                scrollButtons='auto'
                centered={tabBarWidth > 1179}
                variant={tabBarWidth > 1179 ? 'standard' : 'scrollable'}
              >
                {menuOptions.map(({ label, route, submenus }, index) => (
                  <TabMenuOption
                    key={index}
                    label={label}
                    route={route}
                    submenus={submenus}
                  />
                ))}
              </Tabs>
            </AppBar>
          </div>
        )}
      </Measure>
    </div>
  )
}
