import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import _ from 'lodash'
import { format } from 'date-fns'

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, fields } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            color='primary'
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>

        {_(fields)
          .filter((f) => f.only === 'list' || !f.only)
          .map((f) => (
            <TableCell key={`header-${f.name}`} padding={'default'}>
              {f.name}
            </TableCell>
          ))
          .value()}
      </TableRow>
    </TableHead>
  )
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}))

function ResourcesAsDataTable({
  enoughSelections = true,
  resources,
  selected,
  setSelected,
  allowCreation = true,
  ...props
}) {
  const classes = useStyles()
  const { fields } = props.model

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = resources.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected ? selected.indexOf(id) : -1
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    //setSelected(newSelected)
    setSelected(_.reject(newSelected, _.isUndefined))
  }

  const formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  })

  function renderField(row, field) {
    const value = field.value ? field.value(row) : _(row).get(field.field)

    switch (field.type) {
      case 'currency':
        return formatter.format(value)
      case 'date':
        return format(new Date(value), 'dd/MM/yyyy')
      default:
        return value
    }
  }

  const isSelected = (id) => (selected ? selected.indexOf(id) !== -1 : false)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={'small'}
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected ? selected.length : 0}
              onSelectAllClick={handleSelectAllClick}
              rowCount={resources.length}
              fields={fields}
            />
            <TableBody>
              {resources.map((r, index) => {
                const isItemSelected = isSelected(r.id)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <React.Fragment key={`row-${r.id}`}>
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, r.id)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={r.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={isItemSelected}
                          color='primary'
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>

                      {_(fields)
                        .filter((f) => f.only === 'list' || !f.only)
                        .map((f) => (
                          <TableCell
                            key={`row-${r.id}-${f.name}`}
                            component='th'
                            scope='row'
                            className={
                              selected === r.id
                                ? classes.selectedRow
                                : classes.notSelectedRow
                            }
                          >
                            {renderField(r, f)}
                          </TableCell>
                        ))
                        .value()}
                    </TableRow>
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

ResourcesAsDataTable.propTypes = {
  model: PropTypes.shape({
    fields: PropTypes.array.isRequired,
  }),
}

export default ResourcesAsDataTable
