import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
import { MachineryCategory, Machinery } from 'utils/api/models'
import * as ServiceProvider from 'utils/api/models/ServiceProvider'

// const RESOURCE_LOCATION = `${BASE_URL}/api/users/1/farm_owners/1/land/farms/1/meadows/1/soil_management/managements`
const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/meadows`

export const fields = [
  { name: 'Máquina', field: 'machinery.name', only: 'list' },
  {
    name: 'Categoría de máquina',
    field: 'machinery_category_id',
    only: 'create',
    model: MachineryCategory,
  },
  {
    name: 'Máquina',
    field: 'machinery_id',
    depends: 'machinery_category_id',
    only: 'create',
    editValue: 'machinery',
    model: Machinery,
  },
  { name: 'Fecha', field: 'suggested_date', type: 'date' },
  { name: 'Frecuencia', field: 'machinery_frequency' },
  {
    name: 'Costo/u',
    field: 'machinery_price',
    type: 'currency',
    suggestedValue: ({ machinery }) => (machinery ? machinery.unit_price : 0),
  },
  {
    name: 'Costo/Ha',
    value: (row) => row.machinery_frequency * row.machinery_price,
    suggestedValue: (state, form_values) =>
      form_values.machinery_frequency &&
      form_values.machinery_price &&
      form_values.machinery_price * form_values.machinery_frequency,
    type: 'currency',
  },
  { name: 'Observaciones', field: 'observation' },
  {
    name: 'Realizada',
    field: 'service_provider',
    only: 'list',
    type: 'boolean',
    value: (row) => !!row.service_provider?.id,
  },
  {
    name: 'Fecha de ejecución',
    field: 'real_date',
    only: 'create',
    type: 'date',
  },
  {
    name: 'Proveedor de servicio',
    field: 'service_provider_id',
    editValue: 'service_provider',
    only: 'create',
    model: ServiceProvider,
  },
]

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const remove = (meadow_id, management_id, task_group_id, task_id) =>
  axios
    .delete(
      `${RESOURCE_LOCATION}/${meadow_id}/soil_management/managements/${management_id}/task_groups/${task_group_id}/tasks/${task_id}`,
    )
    .then(dataGetter)

export const edit = (task_id, task, meadow_id, management_id, task_group_id) =>
  axios
    .put(
      `${RESOURCE_LOCATION}/${meadow_id}/soil_management/managements/${management_id}/task_groups/${task_group_id}/tasks/${task_id}`,
      {
        task,
      },
    )
    .then(dataGetter)

export const batchDelete = (ids) =>
  axios
    .post(
      `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/task_batch`,
      {
        task_delete_batch: ids,
      },
    )
    .then(dataGetter)
