import axios from 'axios'
import _ from 'lodash'
import { store } from 'App'
import * as actions from 'actions'

axios.interceptors.request.use(
  (req) => {
    store.dispatch(actions.startLoading())
    const parts = req.url.split('/')
    const { routeParams } = store.getState()
    req.url = _(parts)
      .map((p) =>
        _.startsWith(p, ':') ? routeParams[_.trimStart(p, ':')] || p : p,
      )
      .value()
      .join('/')
    return req
  },
  function (error) {
    store.dispatch(actions.stopLoading())
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (res) => {
    store.dispatch(actions.stopLoading())
    return res
  },
  function (error) {
    store.dispatch(actions.stopLoading())
    return Promise.reject(error)
  },
)

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL
export const dataGetter = ({ data: { data } }) => data

export const findIn = (collection, value, field) =>
  _(collection).find((e) => e[field] === value)

export const getCategories = () =>
  axios.get(`${BASE_URL}/api/accounting/categories`)

export const getToken = (params) =>
  axios.post(`${BASE_URL}/api/login`, params).then(({ data }) => data)
