import React from 'react'
import {
  Card,
  makeStyles,
  Box,
  Typography,
  Grid,
  ButtonBase,
  Chip,
  CardHeader,
  Badge,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import _ from 'lodash'
import clsx from 'clsx'

import { getShortName } from 'utils/api/models/Meadow'
import { isPast } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    marginBottom: '4px',
  },
  cardSelected: {
    backgroundColor: 'black',
  },
  meadows: {
    display: 'flex',
    justifyContent: 'center',
  },
  meadow: {
    padding: theme.spacing(0.2),
  },
  currentTask: {
    backgroundColor: theme.palette.primary.main,
  },
  header: { padding: 0, fontSize: 14, paddingBottom: 5 },
}))

const MachineryEntry = ({
  tasks,
  machineryName,
  selectTask,
  toggleTasks,
  task_id,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Card
      className={clsx(classes.root, props.selected && classes.cardSelected)}
    >
      <ButtonBase
        component='div'
        style={{ width: '100%' }}
        onClick={() => toggleTasks(_.map(tasks, 'id'))}
      >
        <CardHeader
          title={<Typography variant='subtitle2'>{machineryName}</Typography>}
          className={classes.header}
        />
      </ButtonBase>

      <Box display='flex' flexDirection='column'>
        <Grid container className={classes.meadows}>
          {tasks.map((t) => {
            const meadow =
              props.meadows[
                props.managements[
                  props.task_groups[t.task_group_id].management_id
                ].meadow_id
              ]

            const showWarning =
              isPast(new Date(t.suggested_date)) && !t.service_provider?.id

            return (
              <Grid item className={classes.meadow}>
                <Badge
                  badgeContent={showWarning && <ErrorIcon fontSize='small' />}
                >
                  <Chip
                    color={task_id === t.id ? 'primary' : 'default'}
                    variant={
                      props.selected_tasks[t.id] ? 'default' : 'outlined'
                    }
                    onClick={() => {
                      selectTask(t)
                      toggleTasks([t.id])
                    }}
                    label={getShortName(meadow)}
                    size='small'
                  ></Chip>
                </Badge>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Card>
  )
}

export default MachineryEntry
