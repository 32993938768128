import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/soil_management/soil_resources_category`

export const resourceKey = 'resource'
export const collectionKey = 'resources'

export const fields = [
  { name: 'Nombre', field: 'name' },
  { name: 'Precio unitario', field: 'unit_price', type: 'currency' },
]

export const all = (category_id) =>
  axios
    .get(`${RESOURCE_LOCATION}/${category_id}/soil_resources`)
    .then(dataGetter)

export const create = (category_id, soil_resource) =>
  axios
    .post(`${RESOURCE_LOCATION}/${category_id}/soil_resources`, {
      soil_resource,
    })
    .then(dataGetter)

export const remove = (category_id, soil_resource_id) =>
  axios
    .delete(
      `${RESOURCE_LOCATION}/${category_id}/soil_resources/${soil_resource_id}`,
    )
    .then(dataGetter)

export const edit = (soil_resource_id, soil_resource, category_id) =>
  axios
    .put(
      `${RESOURCE_LOCATION}/${category_id}/soil_resources/${soil_resource_id}`,
      {
        soil_resource,
      },
    )
    .then(dataGetter)
