import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { fitBounds } from 'google-map-react'
import React, { useEffect } from 'react'
import Map from 'components/Map'
import _ from 'lodash'
import ResourcesAsList from 'components/ResourcesAsList'
import clsx from 'clsx'
import { getCenterCoordinate, getPolygonData } from 'utils/common'
import ColorLegend from './ColorLegend'

const DEFAULT_NE = {
  lat: -40.440474947344896,
  lng: -72.78388893719185,
}
const DEFAULT_SW = {
  lat: -40.81568771972929,
  lng: -73.46504124081213,
}

const useStyles = makeStyles({
  fixedHeight: {
    height: '80vh',
  },
  fullHeight: {
    height: '400px',
  },
  overflow: {
    maxHeight: '80vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
})

const updateElevation = _.throttle((mapApi, mapInstance, setElevationData) => {
  if (mapApi.current) {
    const elevation = new mapApi.current.ElevationService()
    elevation.getElevationForLocations(
      { locations: [mapInstance.current.getCenter()] },
      (results, status) => {
        if (status === 'OK' && results) {
          // Retrieve the first result
          if (results[0]) {
            setElevationData({
              center: [mapInstance.current.getCenter()],
              elevation: results[0].elevation,
            })
          } else {
            console.log('No results found')
          }
        } else {
          console.log('Elevation service failed due to: ' + status)
        }
      },
    )
  }
}, 3000)

const updateMapZoom = (map, setCurrentZoom) => {
  if (map.current) {
    const zoom = map.current.getZoom()
    setCurrentZoom(zoom)
  }
}

function ResourcesAsPolygons({
  resources,
  Resource,
  onPolygonClick = () => null,
  polygonGetter = (r) => r.coordinates.polygon,
  allowCreation = true,
  multiSelect = false,
  HeaderComponent = null,
  resourcesColor = {},
  refMap,
  ...props
}) {
  const mapInstance = React.useRef()
  const mapApi = React.useRef()
  const polygonRef = React.useRef(null)
  const mapContainerRef = React.useRef(null)
  const drawingManagerRef = React.createRef(null)
  const [mapSize, setMapSize] = React.useState({ width: 0, height: 0 })
  const [, setLoaded] = React.useState(false)
  const [selectedPolygonId, setSelectedPolygonId] = React.useState(0)
  const [mapLocation, setMapLocation] = React.useState({
    center: { lat: -40.573814088612934, lng: -73.13920045457898 },
    zoom: 9,
  })
  const [open, setOpen] = React.useState(undefined)
  const [isEditing, setIsEditing] = React.useState(false)
  const [modalMode, setModalMode] = React.useState('')
  const [editingResource, setEditingResource] = React.useState({})
  const [geoParams, setGeoParams] = React.useState({
    coordinates: [],
    area: 0,
  })
  const [elevationData, setElevationData] = React.useState({
    center: { lat: -40.573814088612934, lng: -73.13920045457898 },
    elevation: 0,
  })
  const [currentZoom, setCurrentZoom] = React.useState(mapLocation.zoom)
  const centerTimeoutRef = React.useRef(null)

  const classes = useStyles()

  const allPolygonsSelected =
    _(props.active_meadows).keys().value().length === resources.length

  const handleClickMultiSelect = (e, id) => {
    if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
      props.togglePolygon(id)
    } else {
      props.setActivePolygon(id)
    }
  }

  const toggleAllPolygonsSelected = () => {
    const resourcesIds = _(resources).map('id').value()
    allPolygonsSelected
      ? props.setActivePolygons([])
      : props.setActivePolygons(resourcesIds)
  }

  React.useLayoutEffect(() => {
    if (mapContainerRef.current) {
      setMapSize({
        height: mapContainerRef.current.offsetHeight,
        width: mapContainerRef.current.offsetWidth,
      })
    }
  }, [setMapSize, mapContainerRef])

  const updateMapLocation = (_resources = resources) => {
    if (_resources?.length) {
      const coords = _(_resources).flatMap(polygonGetter).value()

      const neLng = _(coords).maxBy((c) => c.lng)?.lng
      const neLat = _(coords).maxBy((c) => c.lat)?.lat
      const swLng = _(coords).minBy((c) => c.lng)?.lng
      const swLat = _(coords).minBy((c) => c.lat)?.lat

      if (centerTimeoutRef.current) {
        clearTimeout(centerTimeoutRef.current)
      }
      centerTimeoutRef.current = setTimeout(
        () =>
          setMapLocation(
            fitBounds(
              {
                ne: neLng ? { lat: neLat, lng: neLng } : DEFAULT_NE,
                sw: swLng ? { lat: swLat, lng: swLng } : DEFAULT_SW,
              },
              mapSize,
            ),
          ),
        500,
      )
    }
  }

  React.useEffect(updateMapLocation, [resources, mapSize])

  React.useEffect(
    () => updateElevation(mapApi, mapInstance, setElevationData),
    [mapLocation.center, mapApi],
  )

  const apiHasLoaded = ({ map, maps }) => {
    mapInstance.current = map
    mapApi.current = maps
    //console.log(maps)
    //updateElevation(mapApi, mapInstance)

    updateMapLocation()

    map.addListener('dragend', () =>
      updateElevation(mapApi, mapInstance, setElevationData),
    )
    map.addListener('zoom_changed', () => {
      updateElevation(mapApi, mapInstance, setElevationData)
      updateMapZoom(mapInstance, setCurrentZoom)
    })

    if (allowCreation) {
      const drawingManager = new maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            maps.drawing.OverlayType.POLYGON,
            maps.drawing.OverlayType.MARKER,
          ],
        },
        polygonOptions: {
          strokeColor: 'white',
          strokeWeight: 2,
          fillColor: 'transparent',
        },
        map,
      })

      drawingManager.addListener('polygoncomplete', (polygon) => {
        if (polygonRef.current) {
          polygonRef.current.setMap(null)
          polygonRef.current = polygon
        } else {
          polygonRef.current = polygon
        }

        handleNewPolygon(getPolygonData(maps, polygon))
      })

      drawingManagerRef.current = drawingManager
    }

    // force update on ref assignment
    setLoaded(true)
  }

  const handleNewPolygon = (polygonData) => {
    setGeoParams(polygonData)
    setModalMode('create')
    setOpen(true)
  }

  const handlePolygonUpdate = (id, polygonData) => {
    setGeoParams(polygonData)
    if (!editingResource?.id || editingResource?.id !== id) {
      setEditingResource(_(resources).find({ id }))
    }
  }

  const handleCommitPolygon = () => {
    setModalMode('edit')
    setOpen(true)
  }
  const handleCancel = () => {
    setModalMode('')
    setIsEditing(false)
    resetState()
  }

  const resetState = () => {
    if (!open) {
      setIsEditing(false)
      setEditingResource({})
      setGeoParams({})
      setModalMode('')
      setOpen(undefined)
      setTimeout(() => {
        polygonRef.current?.setMap(null)
        polygonRef.current = null
      }, 500)
    }
  }
  React.useEffect(resetState, [open])

  const handlePolygonClick = (id) => (e) => {
    if (multiSelect) {
      handleClickMultiSelect(e, id)
      updateMapLocation(
        _(resources)
          .filter((r) => props.active_meadows[r.id])
          .value(),
      )
    } else {
      setIsEditing(true)
      setSelectedPolygonId(id)
      drawingManagerRef.current?.setOptions({
        drawingMode: null,
      })
      updateMapLocation([_(resources).find({ id })])
    }

    onPolygonClick(e, drawingManagerRef.current, id)
  }

  const centerPolygons = () => {
    const selected = multiSelect
      ? _(resources)
          .filter((r) => props.active_meadows[r.id])
          .value()
      : _.filter([_(resources).find({ id: selectedPolygonId })])
    updateMapLocation(selected)
  }

  const activeMeadows = props.active_meadows || { [selectedPolygonId]: true }
  const totalHa = _(resources)
    .filter((r) => activeMeadows[r.id])
    .map('area_in_ha')
    .map(Number)
    .sum()

  React.useEffect(centerPolygons, [
    props.active_meadows,
    selectedPolygonId,
    resources,
    multiSelect,
  ])

  const mapType = allowCreation ? 'hybrid' : 'satellite'

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={multiSelect ? 12 : 8}
        ref={mapContainerRef}
        className={clsx(classes.fixedHeight, multiSelect && classes.fullHeight)}
      >
        <div ref={refMap}>
          <Box {...mapSize} position='relative'>
            {allowCreation && isEditing && (
              <Paper
                elevation={3}
                style={{
                  position: 'absolute',
                  zIndex: 5,
                  margin: '10px',
                }}
              >
                <ButtonGroup variant='contained' color='primary' size='small'>
                  <Button onClick={handleCommitPolygon}>Confirmar</Button>
                  <Button color='secondary' onClick={handleCancel}>
                    Cancelar
                  </Button>
                </ButtonGroup>
              </Paper>
            )}
            {multiSelect && (
              <Paper
                elevation={3}
                style={{
                  position: 'absolute',
                  zIndex: 5,
                  margin: '10px',
                  paddingLeft: '10px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={allPolygonsSelected}
                      onChange={toggleAllPolygonsSelected}
                      name='checked all'
                    />
                  }
                  label='Seleccionar todo'
                />
              </Paper>
            )}

            <Paper
              elevation={3}
              style={{
                position: 'absolute',
                zIndex: 1,
                padding: '0 5px',
                background: 'rgba(255, 255, 255, 0.6)',
                bottom: 23,
                right: 60,
              }}
            >
              <Typography variant='caption'>
                {_.round(elevationData.center[0]?.lat(), 6)},{' '}
                {_.round(elevationData.center[0]?.lng(), 6)},{' '}
                {_.round(elevationData.elevation, 1)} m
              </Typography>
              {/* <Typography>
              Centro: {elevationData.center.lat}, {elevationData.center.lng}
            </Typography> */}
            </Paper>
            <Paper
              elevation={3}
              style={{
                position: 'absolute',
                zIndex: 1,
                padding: '0 5px',
                background: 'rgba(255, 255, 255, 0.6)',
                bottom: 23,
                right: 300,
              }}
            >
              <Typography variant='caption'>
                Total ha: {Number(totalHa).toFixed(2)}
              </Typography>
            </Paper>
            <Paper
              elevation={3}
              style={{
                position: 'absolute',
                zIndex: 1,
                padding: '0 5px',
                background: 'rgba(255, 255, 255, 0.8)',
                bottom: 30,
                left: 5,
              }}
            >
              <ColorLegend resourcesColor={resourcesColor} />
            </Paper>
            <Map
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={apiHasLoaded}
              libraries={allowCreation ? ['drawing', 'geometry'] : []}
              {...mapLocation}
              debounced={false}
              mapType={mapType}
            >
              {resources.map((r) => {
                const center = getCenterCoordinate(polygonGetter(r))

                return (
                  <Resource
                    key={`resource-polygon-${r.id}`}
                    {...center}
                    mapApi={mapApi.current}
                    mapInstance={mapInstance.current}
                    mapType={mapType}
                    resource={r}
                    color={resourcesColor[r.id]?.color}
                    isSelected={
                      multiSelect
                        ? props.active_meadows[r.id]
                        : selectedPolygonId === r.id
                    }
                    onClick={handlePolygonClick(r.id)}
                    isEditing={isEditing && selectedPolygonId === r.id}
                    onPolygonUpdate={handlePolygonUpdate}
                    currentZoom={currentZoom}
                  />
                )
              })}
            </Map>
          </Box>
        </div>
      </Grid>
      {!multiSelect && (
        <Grid item xs={12} md={4}>
          <Paper>
            <Box className={(clsx(classes.fixedHeight), classes.overflow)}>
              {HeaderComponent ? (
                <HeaderComponent refreshData={props.refreshData} />
              ) : null}
              <ResourcesAsList
                title={props.model?.label}
                model={props.model}
                resources={resources}
                enoughSelections
                setSelected={setSelectedPolygonId}
                selected={selectedPolygonId}
                additionalValues={geoParams}
                openModal={modalMode === '' ? undefined : open}
                setOpenModal={modalMode === '' ? undefined : setOpen}
                modalMode={modalMode}
                refreshData={props.refreshData}
                editingResource={editingResource}
                hideAddButton
              />
            </Box>
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}

export default ResourcesAsPolygons
