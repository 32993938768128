import * as types from 'actions/types'

export const setCategories = (categories) => ({
  type: types.SET_CATEGORIES,
  categories,
})

export const setOwner = (user_id, farm_owner_id) => ({
  type: types.SET_OWNER,
  user_id,
  farm_owner_id,
})

export const setUsers = (users) => ({
  type: types.SET_USERS,
  users,
})

export const unsetUser = () => ({
  type: types.UNSET_USER,
})

export const setFarmOwners = (farmOwners) => ({
  type: types.SET_FARM_OWNERS,
  farmOwners,
})

export const setBusinesses = (businesses) => ({
  type: types.SET_BUSINESSES,
  businesses,
})

export const setFarms = (farms) => ({
  type: types.SET_FARMS,
  farms,
})
export const setToken = (token) => ({
  type: types.SET_TOKEN,
  token,
})

export const startLoading = () => ({
  type: types.START_LOADING,
})

export const stopLoading = () => ({
  type: types.STOP_LOADING,
})

export const breadcrumbResourcesUpdated = (params = {}) => ({
  type: types.BREADCRUMB_RESOURCES_UPDATED,
  params,
})
