import React from 'react'
import ResourcesModalForm from 'components/ResourcesModalForm'
import { TaskAssignment } from 'utils/api/models'
import _ from 'lodash'

function AssignTasks({
  open,
  onClose,
  onSuccess,
  selected_tasks,
  refreshData,
}) {
  const ids = _.keys(selected_tasks)

  return (
    <ResourcesModalForm
      model={TaskAssignment}
      setSelected={() => null}
      refreshData={refreshData}
      mode='create'
      title='Asignación de tareas'
      hideAddButton
      openModal={open}
      setOpenModal={onClose}
      additionalValues={{ ids }}
      onRecord={() => {
        onSuccess()
        onClose()
      }}
    />
  )
}

export default AssignTasks
