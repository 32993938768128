import React, { useState, useEffect } from 'react'
import {
  Grid,
  Paper,
  Button,
  Box,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core'
import { format, setMonth, isSameYear, isSameMonth, getMonth } from 'date-fns'
import { es } from 'date-fns/locale'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  papper: {
    padding: theme.spacing(2, 4, 3),
  },
  Textfield: {
    paddingBottom: '10px',
  },
  buttongroup: {},
  modal: {
    color: 'black',
    alignContent: 'center',
    width: 'auto',
    backgroundColor: '#eeeeee',
    padding: theme.spacing(2, 4, 3),
  },
  selectorlabel: {
    fontWeight: 'bold',
  },
  light: {
    fontWeight: '300',
    color: '#305600',
  },
  root: {
    padding: '20px',
  },
  calendarYear: {
    //margin: theme.spacing.unit,
    margin: '10px',
    minWidth: 180,
    maxWidth: 180,
  },
}))

const months = [
  { id: 1, name: 'ene' },
  { id: 2, name: 'feb' },
  { id: 3, name: 'mar' },
  { id: 4, name: 'abr' },
  { id: 5, name: 'may' },
  { id: 6, name: 'jun' },
  { id: 7, name: 'jul' },
  { id: 8, name: 'ago' },
  { id: 9, name: 'sep' },
  { id: 10, name: 'oct' },
  { id: 11, name: 'nov' },
  { id: 12, name: 'dic' },
]

export default function MonthsSelector({ date, setDate, summary = [] }) {
  {
    console.log('monthly', summary)
  }
  const classes = useStyles()

  const monthsVisited = summary
    .filter((item) => new Date(item.date).getFullYear() === date.getFullYear())
    .map((item) => getMonth(new Date(item.date)))

  {
    console.log('monthly', monthsVisited)
  }

  return (
    <Box display='flex' paddingBottom='5px'>
      {months.map((month, i) => {
        return (
          <MonthSelector
            name={month.name}
            date={date}
            setDate={setDate}
            i={i}
            summary={summary}
            monthsVisited={monthsVisited.includes(i)}
          />
        )
      })}
    </Box>
  )
}

function MonthSelector({
  name,
  date,
  setDate,
  i,
  summary,
  monthsVisited = false,
}) {
  const [active, setActive] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    if (format(date, 'MMM', { locale: es }) === name) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [date])

  //console.log(summary)

  return (
    <Box key={`month-selector-${name}`} padding='5px'>
      <Button
        variant={active ? 'contained' : 'outlined'}
        color={monthsVisited ? 'primary' : 'none'}
        onClick={() => {
          setDate(setMonth(date, i))
          console.log(summary)
        }}
      >
        {name}
      </Button>
    </Box>
  )
}
